import React, { Component, ReactNode } from 'react';
import styles from '@/page/withdraw/ui/modal-error/modal-error.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import i18next from 'i18next';
import * as Sentry from '@sentry/browser';

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  state: State = {
    hasError: false,
    error: null,
    errorInfo: null,
  };

  static getDerivedStateFromError(error: Error): State {
    Sentry.captureException(error);
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ errorInfo });
  }

  reload = (value: boolean) => {
    // Reset the error state to hide the modal and potentially recover from the error
    this.setState({ hasError: !value, error: null, errorInfo: null });
    window.location.pathname = '/';
  };
  goBack = () => {
    window.history.back();
  };
  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.root}>
          <div className={styles.block}>
            <Icon iconName="error" width={80} height={80} className={styles.icon} />
            <h6 className={styles.title}>{i18next.t('Something Went Wrong')}</h6>
            <p className={styles.text}>{i18next.t('errors.unknown.description')}</p>
          </div>
          <div className={styles.footer}>
            <Button color="blue" fullWidth onClick={() => this.reload}>
              <Icon iconName="refresh" width="20" height="20" />
              {i18next.t('RETRY')}
            </Button>
            {/*<Button color="white-blue" variant="filled" wide onClick={() => this.goBack}>*/}
            {/*  <Icon iconName="arrowLeft" width="20" height="20" />*/}
            {/*  {i18next.t('GO BACK')}*/}
            {/*</Button>*/}
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
