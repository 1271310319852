import {
  ctBrowserLightSrc,
  ctBrowserLiteIconSrc,
  ctBrowserMaxIconSrc,
  ctBrowserMaxSrc,
  ctBrowserProIconSrc,
  ctBrowserProSrc,
  ctFarmIconSrc,
  ctFarmProIconSrc,
  ctFarmProSrc,
  ctFarmSrc,
  ctTagsIconSrc,
  ctTagsSrc,
  ctVpnIconSrc,
  ctVpnSrc,
  ncWalletSrc,
} from '../assets';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentTheme } from '@/features/theme';
import { AppTheme } from '@/features/theme/model/slice';
import ncwalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg';
import ncwalletWhiteIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet-white.svg';

export interface CTProduct {
  name: string;
  description: string;
  promo: string | null;
  image: string;
  icon: string;
  link: string;
}

export const useProductsList = () => {
  const theme = useSelector(selectCurrentTheme);

  const products: CTProduct[] = useMemo(
    () => [
      {
        name: 'Products.CTB_Lite.Title',
        description: 'Products.CTB_Lite.Description',
        promo: null,
        image: ctBrowserLightSrc,
        icon: ctBrowserLiteIconSrc,
        link: 'https://play.google.com/store/apps/details?id=lite.cryptotab.android',
      },
      {
        name: 'Products.CTB_Pro.Title',
        description: 'Products.CTB_Pro.Description',
        promo: null,
        image: ctBrowserProSrc,
        icon: ctBrowserProIconSrc,
        link: 'https://play.google.com/store/apps/details?id=pro.cryptotab.android',
      },
      {
        name: 'Products.CTB_Max.Title',
        description: 'Products.CTB_Max.Description',
        promo: null,
        image: ctBrowserMaxSrc,
        icon: ctBrowserMaxIconSrc,
        link: 'https://play.google.com/store/apps/details?id=max.cryptotab.android',
      },
      {
        name: 'Products.NCW.Title',
        description: 'Products.NCW.Description',
        promo: null,
        image: ncWalletSrc,
        icon: theme === AppTheme.LIGHT ? ncwalletIcon : ncwalletWhiteIcon,
        link: 'https://play.google.com/store/apps/details?id=com.ncwallet',
      },
      {
        name: 'Products.CTFarm.Title',
        description: 'Products.CTFarm.Description',
        promo: null,
        image: ctFarmSrc,
        icon: ctFarmIconSrc,
        link: 'https://play.google.com/store/apps/details?id=com.cryptofarm',
      },
      {
        name: 'Products.CTFarmPro.Title',
        description: 'Products.CTFarmPro.Description',
        promo: null,
        image: ctFarmProSrc,
        icon: ctFarmProIconSrc,
        link: 'https://play.google.com/store/apps/details?id=com.ctfarm',
      },
      {
        name: 'Products.CTVPN.Title',
        description: 'Products.CTVPN.Description',
        promo: null,
        image: ctVpnSrc,
        icon: ctVpnIconSrc,
        link: 'https://play.google.com/store/apps/details?id=com.ctvpn.android',
      },
      {
        name: 'Products.CTags.Title',
        description: 'Products.CTags.Description',
        promo: null,
        image: ctTagsSrc,
        icon: ctTagsIconSrc,
        link: 'https://play.google.com/store/apps/details?id=com.cryptotabtags',
      },
    ],
    [theme]
  );

  return {
    products,
  };
};
