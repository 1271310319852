import ModalLayout from '@/shared/ui/modal/modal';
import styles from './rate-us-company.module.scss';
import { useCallback, useState } from 'react';
import { useRating } from '@/shared/lib/hooks/useRating';
import topImg from '@/features/rate-us/ui/market-block/img/particles_top.svg';
import bottomImg from '@/features/rate-us/ui/market-block/img/particles_bottom.svg';
import { Icon } from '@/shared/ui/icons';
import companyIcon from '@/page/home/ui/modal-rate-company/image/icons_products.svg';
import { Rating } from '@/shared/ui/rating/rating';
import cn from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { ReviewAction } from '@/shared/lib/backend/JsonRpcApi';
import { CRYPTO_COMPANY_GOOGLE_RATE } from '@/shared/lib/utils/links';
import { useDispatch, useSelector } from 'react-redux';
import { rootUser, userAction } from '@/shared/store/user/user.slice';
import { AppDispatch } from '@/shared/store/types';

export const RateUsCompany = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const [loading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { sendReviewDetails } = useRating();
  const { t } = useTranslation();
  const { reviewRatingId, ratingMeta } = useSelector((store: { user: rootUser }) => store.user);
  console.log('reviewRatingId', reviewRatingId);
  console.log('ratingMeta', ratingMeta);
  const handleToGoogleClick = useCallback(async () => {
    setLoading(true);
    try {
      await sendReviewDetails({
        action: ReviewAction.Click,
        meta: ratingMeta,
        review_id: reviewRatingId,
      });
      dispatch(userAction.setForceDisableRating(true));
      setLoading(false);
      onClose(false);
    } catch (error) {
      setLoading(false);
      onClose(false);
      console.error('Failed to send review details:', error);
    } finally {
      setLoading(false);
      window.open(CRYPTO_COMPANY_GOOGLE_RATE);
      onClose(false);
    }
  }, [dispatch, onClose, ratingMeta, reviewRatingId, sendReviewDetails]);

  const onCloseModal = async () => {
    setLoading(true);
    try {
      await sendReviewDetails({
        action: ReviewAction.Close,
        meta: ratingMeta,
        review_id: reviewRatingId,
      });
      dispatch(userAction.setForceDisableRating(true));
      setLoading(false);
      onClose(false);
    } catch (error) {
      setLoading(false);
      console.error('Failed to send review details:', error);
      onClose(false);
    } finally {
      setLoading(false);
      onClose(false);
    }
  };

  return (
    <ModalLayout iconClose onClose={onCloseModal} isOpen={isOpen} id="rate-us">
      <div className={styles.root} onClick={onCloseModal}>
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className={styles.block}>
            <img src={topImg} className={styles.block__particlesTop} alt="top" />
            <span className={styles.close} onClick={onCloseModal}>
              <Icon iconName="close" width="20" height="20" />
            </span>
            <img src={companyIcon} alt="company Icon" width={60} height={60} className={styles.marketIcon} />
            <div className={styles.marketRating}>
              <Rating noNumbers onChange={() => {}} value={5} size={24} />
            </div>
            <h6 className={cn(styles.title, styles.center, 'mt-20')}>{<Trans i18nKey={t('RateCompany.Title')} />}</h6>
            <p className={cn(styles.text, styles.center)}>{<Trans i18nKey={t('RateCompany.Description')} />}</p>
            <img src={bottomImg} className={styles.block__particlesBottom} alt="bottom" />
          </div>
          <div className={styles.footer}>
            <Button color="blue" fullWidth onClick={handleToGoogleClick} loading={loading}>
              <Icon iconName="write-review" width={20} height={20} />
              {t('RateCompany.Btn')}
            </Button>
            <Button variant="filled" color="white" fullWidth onClick={onCloseModal} loading={loading}>
              {t('Select_Reviews.Btn2')}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
