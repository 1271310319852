import { LAST_SHOWN_DATE } from '@/shared/lib/constants/storage-key';

const TWO_DAYS = 2 * 24 * 60 * 60 * 1000;

export function shouldShowContent(): boolean {
  const currentDate: Date = new Date();

  const lastShownDateString: string | null = localStorage.getItem(LAST_SHOWN_DATE);

  if (lastShownDateString) {
    const lastShownDate: Date = new Date(lastShownDateString);

    return currentDate.getTime() - lastShownDate.getTime() >= TWO_DAYS;
  } else {
    return true;
  }
}
