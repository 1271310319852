import React, { ReactNode, useMemo } from 'react';
import styles from './balance-additional-info.module.scss';
import { Currencies } from '@/shared/ui/currency-icon';
import { WalletsCurrenciesResponse } from '@/shared/lib/backend/JsonRpcApi';
import { formatBalance } from '@/shared/lib/utils/units';
import cn from 'classnames';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

type BalanceAdditionalInfoProps = {
  title: string;
  btc2usd: number;
  balance: string | number;
  className?: string;
  data?: string;
  icon?: ReactNode;
  onClick?: () => void;
  slot?: ReactNode;
  currency?: Currencies;
  mining_currencies?: [] | WalletsCurrenciesResponse;
};

export const BalanceAdditionalInfo = (props: BalanceAdditionalInfoProps) => {
  const { title, btc2usd, balance, className, data, icon, onClick, slot, currency, mining_currencies } = props;
  const currentCurrencyObj = mining_currencies?.find((cur) => cur?.symbol === currency);
  const formattedUSDBalance = +balance ? formatBalance(+balance * btc2usd, '0.01') : '0.00';

  const formattedBalance = useMemo(() => {
    if (!currentCurrencyObj) return '0.00';
    return +balance >= +currentCurrencyObj.unit
      ? formatBalance(balance, currentCurrencyObj.unit)
      : currentCurrencyObj.unit.toString().replace(/1$/, '0');
  }, [currentCurrencyObj]);

  return (
    <div className={styles.container}>
      <div className={cn(styles.info__item, className)}>
        <div onClick={onClick} data-hook={data} className={styles.col}>
          <div>
            <div className={styles.info__label}>
              {title} {icon ? <div>{icon}</div> : null}
            </div>
            <div className={styles.info__mob_row}>
              <div className={styles.info__btc}>
                {getLocalAmount(formattedBalance)} {currency}
              </div>
              <div className={styles.info__usd}>{getLocalAmount(formattedUSDBalance, 'usd')}</div>
            </div>
          </div>
        </div>
        {slot}
      </div>
    </div>
  );
};
