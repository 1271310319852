import ModalLayout from '@/shared/ui/modal/modal';
import styles from './modal-withdraw-suspended.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { SUPPORT_SUSPENDED } from '@/shared/lib/utils/links';

type ModalWithdrawSuspended = {
  onClose: (e: boolean) => void;
  isOpen: boolean;
};

export const ModalWithdrawSuspended = ({ onClose, isOpen }: ModalWithdrawSuspended) => {
  const { Header, Title, ContentMobile } = ModalLayout;
  const { t } = useTranslation();

  const onContactSupportClick = () => {
    window.open(SUPPORT_SUSPENDED);
  };

  return (
    <ModalLayout background onClose={() => onClose(false)} isOpen={isOpen} isNavigation={true} id="error">
      <Header>
        <Title size="xs">{t('Sus_Page.Subtitle')}</Title>
      </Header>
      <ContentMobile className={styles.contentModal}>
        <div className={styles.container}>
          <div className={styles.innerContainer}>
            <div className={styles.contentContainer}>
              <div className={styles.iconContainer}>
                <Icon iconName="errorTime" width={80} height={80} />
              </div>
              <h1 className={styles.title}>{t('Sus_Page.Title')}</h1>
              <p className={styles.description}>{t('Sus_Page.Descr')}</p>
            </div>
            <div className={styles.footer}>
              <Button color="blue" fullWidth onClick={onContactSupportClick}>
                <Icon iconName="question" width="20" height="20" />
                {t('Sus_Page.Btn')}
              </Button>
            </div>
          </div>
        </div>
      </ContentMobile>
    </ModalLayout>
  );
};
