import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import { Button } from '@/shared/ui/button';
import { useCallback } from 'react';
import { useCurrentPlanPriceInfoTelegram } from '@/page/cart/hooks/use-current-plan-price-info-telegram';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { makeOrderThunk } from '@/page/cart/store';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { HOME_PATH } from '@/shared/lib/utils/links';
import { useTelegram } from '@/shared/lib/hooks/useTelegram';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

export const MakeOrderTelegramButton = () => {
  const status = useSelector((store: RootState) => store.cart.status);
  const { onInvoice } = useTelegram();
  const dispatch: AppDispatch = useDispatch();
  const { formattedPrice } = useCurrentPlanPriceInfoTelegram();
  const { t } = useTranslation();
  const { openModal } = useModalSet();
  const navigate = useNavigate();
  const isLoading = status === 'loading';

  const handleClick = useCallback(async () => {
    try {
      const res = await dispatch(makeOrderThunk({ currency: 'XTR' })).unwrap();

      Telegram.WebApp.openInvoice(res);

      onInvoice(res, (status) => {
        if (status === 'paid') {
          toaster.success(t('Succ_paid'));
          navigate(HOME_PATH);
        }
      });
    } catch (error) {
      console.error(error);
      openModal('MODAL_ERROR', { isServices: true });
    }
  }, []);

  return (
    <Button fullWidth color="green" onClick={handleClick} disabled={isLoading} loading={isLoading}>
      {t('BUY')}
      {' / '}
      {getLocalAmount(formattedPrice)} {'XTR'}
    </Button>
  );
};
