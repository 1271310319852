import { useCallback, useEffect, useState } from 'react';

let countdownStore: number | null = null;
let countdownTimer: number | null = null;
let valueStore: string = '';

export const useModalVerify = () => {
  const [countdown, setCountdown] = useState<number | null>(countdownStore);
  const [toastId, setToastId] = useState<string | number>('');
  const [value, setValue] = useState(valueStore);

  useEffect(() => {
    setValue(valueStore);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCountdown(countdownStore);
    }, 1001);

    return () => clearTimeout(timer);
  }, [countdown]);

  const newCountdown = useCallback((n: number) => {
    countdownStore = n;
    setCountdown(n);

    countdownTimer && clearInterval(countdownTimer);
    countdownTimer = setInterval(() => {
      const prev = countdownStore;
      countdownStore = prev && prev > 0 ? (prev === 1 ? null : prev - 1) : prev;
      if (!countdownStore) {
        countdownTimer && clearInterval(countdownTimer);
        countdownTimer = null;
      }
    }, 1000);
  }, []);

  const newToastId = useCallback((id: string | number) => {
    setToastId(id);
  }, []);

  const changeValue = useCallback((s: string) => {
    valueStore = s;
    setValue(s);
  }, []);

  return { countdown, newCountdown, toastId, newToastId, value, changeValue };
};
