import styles from './header.module.scss';
import {
  CART_PATH,
  CRYPTO_TAB_REVIEW,
  ERROR_CONNECTION,
  HOME_PATH,
  LOGIN_PATH,
  MENU_PATH,
  POOL_MINING,
  WITHDRAW_PATH,
  CRYPTO_TAB_PRODUCTS_PATH,
  BONUS_PROGRAM,
} from '@/shared/lib/utils/links';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import cn from 'classnames';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentTheme } from '@/features/theme';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { AppTheme, selectIsMobile } from '@/features/theme/model/slice';
import { rootUser, userAction } from '@/shared/store/user/user.slice';
import { AppDispatch } from '@/shared/store/types';
import { ReviewAction } from '@/shared/lib/backend/JsonRpcApi';
import { useRating } from '@/shared/lib/hooks/useRating';

export function Header({ headerTitle }: { headerTitle?: string }) {
  const { pathname } = useLocation();
  const { isTelegramMiniApp } = useDevice();
  const isMobileState = useSelector(selectIsMobile);

  const path = pathname.startsWith('/withdraw') ? '/withdraw' : pathname.replace(new RegExp('^(.+)/$'), '$1');

  const title = () => {
    switch (path) {
      case CART_PATH:
        return <HeaderCart />;
      case CRYPTO_TAB_PRODUCTS_PATH:
        return <HeaderProducts />;
      case WITHDRAW_PATH:
        return <HeaderWithdraw />;
      case HOME_PATH:
        return <HeaderDashboard />;
      case LOGIN_PATH:
        return <HeaderLogo />;
      case ERROR_CONNECTION:
        return <HeaderLogo />;
      case MENU_PATH:
        return <HeaderLogo />;
      case CRYPTO_TAB_REVIEW:
        return <HeaderWithTitleReview headerTitle={headerTitle} />;
      case BONUS_PROGRAM:
        return <HeaderWithTitleReview headerTitle={headerTitle} />;
      default:
        return <HeaderWithTitle headerTitle={headerTitle} />;
    }
  };
  if (isTelegramMiniApp === undefined && isMobileState === undefined) {
    return null;
  }
  if (isMobileState || isTelegramMiniApp) {
    return null;
  }
  return (
    <div className={styles.container}>
      <div className={styles.bar}>{title()}</div>
    </div>
  );
}

const HeaderWithTitle = ({ headerTitle }: { headerTitle?: string }) => {
  const navigate = useNavigate();
  return (
    <>
      <Icon
        onClick={() => navigate(-1)}
        iconName="arrow"
        width={20}
        height={20}
        className={cn(styles.logo, styles.logo_arrow)}
      />
      <p className={styles.title}>{headerTitle}</p>
    </>
  );
};

const HeaderWithTitleReview = ({ headerTitle }: { headerTitle?: string }) => {
  const { reviewRatingId, ratingMeta } = useSelector((store: { user: rootUser }) => store.user);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { sendReviewDetails } = useRating();
  const onBackClick = async () => {
    await sendReviewDetails({
      action: ReviewAction.Close,
      meta: ratingMeta,
      review_id: reviewRatingId,
    });
    dispatch(userAction.setForceDisableRating(true));
    navigate(-1);
  };

  return (
    <>
      <Icon
        onClick={onBackClick}
        iconName="arrow"
        width={20}
        height={20}
        className={cn(styles.logo, styles.logo_arrow)}
      />
      <p className={styles.title}>{headerTitle}</p>
    </>
  );
};

const HeaderLogo = () => {
  const theme = useSelector(selectCurrentTheme);
  return (
    <Link to={{ pathname: HOME_PATH }}>
      <Icon
        iconName={theme === AppTheme.DARK ? 'logoFooter' : 'logoHeader'}
        className={cn(styles.logo, styles.logo_center)}
        height={32}
        width={174}
      />
    </Link>
  );
};

const HeaderDashboard = () => {
  const { t } = useTranslation();
  return (
    <>
      <Icon iconName="logoSmall" width={32} height={32} className={styles.logo} />
      <div className={styles.title}>{t('Dashboard')}</div>
    </>
  );
};

const HeaderWithdraw = () => {
  const { t } = useTranslation();

  return (
    <>
      <Icon iconName="logoSmall" width={32} height={32} className={styles.logo} />
      <div className={styles.title}>
        <Trans i18nKey={t('My Wallet')} />
      </div>
    </>
  );
};

const HeaderProducts = () => {
  const { t } = useTranslation();

  return (
    <>
      <Icon iconName="logoSmall" width={32} height={32} className={styles.logo} />
      <div className={styles.title}>
        <Trans i18nKey={t('Products.General_title')} />
      </div>
    </>
  );
};

const HeaderCart = () => {
  const { t } = useTranslation();
  const { openModal } = useModalSet();
  const { platformCt } = useDevice();
  const navigation = useNavigate();
  const onButtonClick = () => {
    if (platformCt === 'ios') {
      navigation(POOL_MINING);
    } else {
      openModal('MODAL_INFO_POOL', { isServices: true });
    }
  };
  return (
    <>
      <Icon iconName="logoSmall" width={32} height={32} className={styles.logo} />
      <div className={styles.title}>{t('ADD POOL MINER')}</div>
      <Button className={styles.btn} variant="text" color="blue" onClick={onButtonClick}>
        <Icon iconName="info" width="20" height="20" />
      </Button>
    </>
  );
};
