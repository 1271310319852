import { getLanguage } from '@/shared/store/language';

export const formatHashrate = (value: number, isPoolList: boolean = false): string => {
  const lang = getLanguage();

  let hpsCount;
  let hpsCurrency;

  if (isPoolList) {
    if (value >= 1000000) {
      hpsCount = value / 1000000;
      hpsCurrency = 'M';
    } else {
      hpsCount = value / 1000;
      hpsCurrency = 'K';
    }
  } else {
    if (value >= 1000000) {
      hpsCount = value / 1000000;
      hpsCurrency = 'MH/s';
    } else {
      hpsCount = value / 1000;
      hpsCurrency = 'kH/s';
    }
  }

  return `${hpsCount.toLocaleString(lang)} ${hpsCurrency}`;
};
