import React from 'react';
import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';
import styles from './balance.module.scss';
import theme from './reel-theme.module.scss';
import theme2 from './reel-theme2.module.scss';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { Link } from 'react-router-dom';
import { Tutorial } from '@/shared/ui/tutorial/tutorial';
import { MINING } from '@/page/withdraw/ui/switch-withdrawal-tabs/switch-withdrawal-tabs';
import { splitCurrencyValue } from '@/shared/lib/utils/balance';
import { useMiningBlock } from '../../mining-block';
import { ShuffleMachine } from '@/features/shuffleMachine/shuffleMachine';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

export const Balance = () => {
  const { currentCurrencyObj, formattedBalance, usdBalance, userCurrency, exact_total } = useBalanceInfo();

  const balanceToViewMap: Record<string, number> = {
    BTC: 0.00000001,
    ETH: 0.000001,
    USDT: 0.000001,
    CTC: 0.001,
    DOGE: 0.001,
    POL: 0.0001,
    SOL: 0.00001,
    BNB: 0.00000001,
    TRX: 0.0001,
    TON: 0.0001,
    HSH: 0.01,
  };

  const splitedAmount = splitCurrencyValue(exact_total, userCurrency);

  const balanceToView =
    Number(formattedBalance) <= balanceToViewMap[userCurrency] ? (
      <>
        <ShuffleMachine text={getLocalAmount(`${splitedAmount[0]}.${splitedAmount[1]}`)} extendedClasses={theme} />
        <ShuffleMachine text={splitedAmount[2]} extendedClasses={theme2} />
      </>
    ) : (
      <ShuffleMachine text={getLocalAmount(formattedBalance)} extendedClasses={theme} />
    );
  const miningBlockRef = useMiningBlock();

  return (
    <div className={styles.container}>
      <Tutorial step="balance" arrow="left" position="top" viewBlock={miningBlockRef.current}>
        <div className={styles.balanceInfoContainer}>
          <img src={currentCurrencyObj?.img} alt="ico" width={40} height={40} className={styles.balanceIcon} />
          <div className={styles.balanceCrypto}>
            {balanceToView}
            <span>{userCurrency}</span>
          </div>
          <div className={styles.balanceUsd}>
            <span>{getLocalAmount(usdBalance, 'usd')}</span>
          </div>
        </div>
      </Tutorial>
      <Tutorial step="withdraw" arrow="right" position="top" viewBlock={miningBlockRef.current}>
        <Button
          color="blue"
          variant="outline"
          className={styles.walletButton}
          to={`${WITHDRAW_PATH}/${MINING}`}
          as={Link}
        >
          <Icon iconName="wallet" width={20} height={20} />
        </Button>
      </Tutorial>
    </div>
  );
};
