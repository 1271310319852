import ModalLayout from '@/shared/ui/modal/modal';
import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { RootState } from '@/shared/store/types';
import { useSelector } from 'react-redux';
import { checkE } from '@/shared/lib/utils/checE';
import styles from './error-funds-withdrawal-modal.module.scss';

export const ErrorFundsWithdrawalModal = ({
  onClose,
  isOpen,
  amount,
  isMining,
}: {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  amount: number;
  isMining: boolean;
}) => {
  const { t } = useTranslation();
  const { currency: userCurrency } = useSelector((store: RootState) => store.user);

  return (
    <ModalLayout iconClose isOpen={isOpen} onClose={() => onClose(false)} className={styles.root} id="address">
      <div className={styles.root} onClick={() => onClose(false)}>
        <div className={styles.block}>
          <span className={styles.close} onClick={() => onClose(false)}>
            <Icon iconName="close" width="20" height="20" />
          </span>
          <div className={styles.header}>
            <Icon iconName="attention" width={80} height={80} />
            <h6 className={styles.title}>
              {
                <Trans
                  i18nKey={t('You do not have enough BTC')}
                  values={{ currency: isMining ? userCurrency : 'HSH' }}
                />
              }
            </h6>
            <p className={styles.text}>
              {
                <Trans
                  i18nKey={t('Not_enough.Description')}
                  components={{ b: <strong /> }}
                  values={{ amount: checkE(amount), currency: isMining ? userCurrency : 'HSH' }}
                />
              }
            </p>
          </div>
          <div className={styles.footer}>
            <Button color="blue" className={styles.mailbox} fullWidth onClick={() => onClose(false)}>
              <Icon iconName="checked" width="17" height="16" />
              {t('OK, GOT IT')}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
