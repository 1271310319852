import { useTranslation } from 'react-i18next';
import { Icon } from '@/shared/ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { Button } from '@/shared/ui/button';
import { confirmEmail, selectWithdrawal } from '@/shared/store/withdrawal/withdrawal.slice';
import ModalLayout from '@/shared/ui/modal/modal';
import { isValidEmail } from '@/shared/lib/utils/units';
import styles from './modal-email-verify.module.scss';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { selectCurrentTheme } from '@/features/theme';
import { Slide, ToastContainer } from 'react-toastify';
import { useModalVerify } from '@/shared/lib/hooks/useModalVerify';

type ModalEmailVerifyType = {
  onClose: (e: boolean) => void;
  isOpen: boolean;
};

export const ModalEmailVerify = ({ onClose, isOpen }: ModalEmailVerifyType) => {
  const email = useSelector((state: RootState) => state.user.email);
  const theme = useSelector(selectCurrentTheme);
  const withdrawal = useSelector(selectWithdrawal);
  const [error, setError] = useState<string | null>(null);
  const [buttonTimeout, setButtonTimeout] = useState(false);
  const isLoading = withdrawal.sendEmailStatus === 'loading';
  const { Header, Title, ContentMobile } = ModalLayout;
  const dispatch: AppDispatch = useDispatch();
  const { openModal } = useModalSet();
  const { t } = useTranslation();
  const { countdown, newCountdown, toastId, newToastId, value, changeValue } = useModalVerify();

  const handleClick = () => {
    if (isValidEmail(value)) {
      dispatch(confirmEmail({ email: value, t, toastId, newToastId, countdown, newCountdown, openModal }));
      setButtonTimeout(true);
      setTimeout(() => {
        setButtonTimeout(false);
      }, 3000);
    } else {
      setError(t('InvalidEmail'));
    }
  };

  useEffect(() => {
    changeValue(email);
  }, []);

  return (
    <ModalLayout background onClose={() => onClose(false)} isOpen={isOpen} isNavigation={true} id="mail-verify">
      <Header>
        <Title size="xs">{t('Mail_conf.Main_title')}</Title>
      </Header>
      <ContentMobile className={styles.contentModal}>
        <div className={styles.root}>
          <div className={styles.block}>
            <Icon
              iconName={theme === 'light' ? 'email-verify-connect' : 'email-verify-connect-dark'}
              width={80}
              height={80}
            />
            <h6 className={styles.title}>{t('Mail_conf.Enter.Title')}</h6>
            <p className={styles.text}>{t('Mail_conf.Enter.Descr')}</p>
            <div className={styles.container}>
              <label htmlFor={'email'} className={cn(styles.container__label, styles.label)}>
                <Icon iconName="email" width={20} height={20} className={styles.label__icon} />
                <span className={styles.label__text}>{t('Mail_conf.Enter.Subt')}</span>
              </label>
              <input
                id="email"
                type="email"
                className={cn(styles.container__input, error && styles.error)}
                value={value}
                placeholder={t('Pos_Vibes.Enter_mail')}
                onChange={(e) => changeValue(e.target.value)}
              />
              {error && <span className={styles.container__error}>{error}</span>}
            </div>
          </div>
          <div className={styles.footer}>
            <Button
              color="blue"
              fullWidth
              onClick={handleClick}
              disabled={isLoading || buttonTimeout || !isValidEmail(value)}
              loading={isLoading}
            >
              {t('Confirm')}
              <Icon iconName="arrow" width="24" height="24" />
            </Button>
          </div>
          <ToastContainer
            enableMultiContainer={true}
            closeButton={false}
            hideProgressBar
            closeOnClick={true}
            pauseOnHover={false}
            position="top-right"
            transition={Slide}
            containerId="modalsContainer"
          />
        </div>
      </ContentMobile>
    </ModalLayout>
  );
};
