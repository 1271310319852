import { Trans } from 'react-i18next';
import styles from './pool-mining-info.module.scss';
import Lottie from 'lottie-react';
import { cloud_boost } from '@/shared/ui/assets/animations/lottie';
import placeholder from '@/page/cart/ui/v1/modal-mining-info/images/placeholder.svg';

export const PoolMiningInfo = () => {
  return (
    <div className={styles.container}>
      <Lottie animationData={cloud_boost} placeholder={placeholder} loop={true} className={styles.img} />
      <p className={styles.description}>
        <Trans
          i18nKey={'PM_page.Description'}
          components={{
            b: <strong />,
          }}
        />
      </p>
    </div>
  );
};
