import ModalLayout from '@/shared/ui/modal/modal';
import styles from './modal-error.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';

export const ModalError = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const { t } = useTranslation();
  return (
    <ModalLayout background iconClose onClose={() => onClose(false)} isOpen={isOpen} id="error">
      <div className={styles.root}>
        <div
          className={styles.block}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Icon iconName="error" width={80} height={80} className={styles.icon} />
          <h6 className={styles.title}>{t('Something Went Wrong')}</h6>
          <p className={styles.text}>{t('errors.unknown.description')}</p>
        </div>
        <div className={styles.footer}>
          <Button color="blue" className={styles.mailbox} fullWidth onClick={() => onClose(false)}>
            <Icon iconName="checked" width="17" height="16" />
            {t('OK, GOT IT')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
