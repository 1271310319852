import styles from './withdrawal-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import ModalLayout from '@/shared/ui/modal/modal';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

export const CancelWithdrawalModal = ({
  onClose,
  isOpen,
  amount,
  onCancel,
  currency,
}: {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  amount: string;
  currency: string;
  onCancel: () => Promise<void>;
}) => {
  const { t } = useTranslation();
  const [loading, setLoding] = useState(false);
  const [isClosing, setIsClosing] = useState<boolean>(false);

  const handleClick = useCallback(async () => {
    setLoding(true);
    await onCancel();
  }, [onCancel]);

  useEffect(() => {
    if (isClosing) {
      const turn = setTimeout(() => {
        onClose(false);
        setIsClosing(false);
      }, 200);

      return () => clearTimeout(turn);
    }
  }, [isClosing]);

  return (
    <ModalLayout iconClose onClose={() => setIsClosing(true)} isOpen={isOpen} id="cancel-withdrawal">
      <div className={styles.root} onClick={() => setIsClosing(true)}>
        <div
          className={cn(styles.block, isClosing && styles.slideOut)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span className={styles.close} onClick={() => setIsClosing(true)}>
            <Icon iconName="close" width="20" height="20" />
          </span>
          <Icon iconName="reject" width={80} height={80} className={styles.icon} />
          <h6 className={styles.title}>{t('Cancel Withdrawal')}</h6>
          <p className={styles.text}>
            <Trans
              i18nKey={t('canel.desc')}
              values={{ amount: getLocalAmount(amount), currency }}
              components={{ b: <strong /> }}
            />
          </p>
          <div className={styles.buttonBlock}>
            <Button
              loading={loading}
              borderRadius={false}
              disabled={loading}
              onClick={handleClick}
              color="blue"
              fullWidth
            >
              <Icon iconName="checked" width={17} height={16} />
              {t('confirm')}
            </Button>
            <Button
              disabled={loading}
              borderRadius={false}
              color="blue"
              variant="outline"
              fullWidth
              onClick={() => setIsClosing(true)}
            >
              <Icon iconName="cancel" width={20} height={20} />
              {t('CANCEL')}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
