import styles from './btc-rate.module.scss';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Line, LineChart, Tooltip, YAxis } from 'recharts';
import { Currencies } from '@/shared/ui/currency-icon';
import { Currency } from '@/shared/lib/backend/JsonRpcApi';
import { RateState } from '@/shared/store/rate/rate.slice';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

export function CurrentCurrencyRate({ userCurrency, currency }: { userCurrency: Currencies; currency?: Currency }) {
  const rate = useSelector((store: { rate: RateState }) => store.rate);
  const handleClick = useCallback(() => window.open(currency?.url), [currency?.url]);

  // Find the max and min values in your data
  const maxValue = Math.max(...rate.btc_history.map((item) => parseFloat(item.avg)));
  const minValue = Math.min(...rate.btc_history.map((item) => parseFloat(item.avg)));

  return (
    <div className={styles.btc_chart__container}>
      <div className={styles.btc_chart__line_chart}>
        <LineChart
          width={100}
          margin={{ top: 8, right: 8, bottom: 8, left: 8 }}
          height={24}
          data={rate.btc_history}
          onClick={handleClick}
        >
          <YAxis type="number" hide={true} domain={[minValue, maxValue]} />
          <Tooltip content={<CustomTooltip unit={currency?.symbol} />} cursor={false} />

          <Line type="monotone" dataKey="avg" dot={false} stroke="#F7931A" strokeWidth={1} />
        </LineChart>
      </div>
      <div className={styles.btc_chart__rates}>
        <b>{userCurrency} / USD</b>
        <span>{getLocalAmount(rate.crypto2usdRate)}</span>
      </div>
    </div>
  );
}

// eslint-disable-next-line
const CustomTooltip = (props: any) => {
  if (!props.active) {
    return null;
  }
  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        backgroundColor: 'rgba(0,0,0,0.9)',
        color: '#fff',
        fontSize: '11px',
        padding: '4px',
        borderRadius: '2px',
      }}
    >
      <b>{props.payload?.[0]?.payload?.date}</b>, {props.unit} / USD – <b>{props?.payload?.[0]?.value}</b>
    </div>
  );
};
