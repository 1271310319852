import ModalLayout from '@/shared/ui/modal/modal';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import styles from './confirm-withdrawal-modal.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { useNavigate } from 'react-router-dom';
import { HOME_PATH } from '@/shared/lib/utils/links';
import React, { useCallback } from 'react';
import { getEmailServiceUrl } from '@/shared/lib/utils/emailServices';
import { selectCurrentTheme } from '@/features/theme';

export const ConfirmWithdrawalModal = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const theme = useSelector(selectCurrentTheme);
  const email = useSelector((state: RootState) => state.user.email);
  const { Title, Header, ContentMobile } = ModalLayout;
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleOpenInbox = useCallback(() => {
    onClose(false);
    navigate(HOME_PATH);
    const serviceUrl = getEmailServiceUrl(email);
    if (serviceUrl) {
      window.open(serviceUrl, '_blank');
    }
    // window.open('https://mail.google.com/', '_blank');
  }, [email, navigate, onClose]);

  return (
    <ModalLayout background onClose={() => onClose(false)} isOpen={isOpen} id="confirm-withdrawal">
      <Header>
        <Title size="xs">{t('Confirm Withdrawal')}</Title>
      </Header>
      <ContentMobile>
        <div className={styles.root}>
          <div className={styles.block}>
            <div className={styles.header}>
              <Icon
                iconName={theme === 'light' ? 'email-verify-connect' : 'email-verify-connect-dark'}
                width={80}
                height={80}
              />
              <h6 className={styles.title}>{t('Confirm Withdrawal')}</h6>
              <p className={styles.text}>
                <Trans
                  i18nKey={t('ConfirmWithdrawal.Description1')}
                  components={{ b: <strong /> }}
                  values={{ email: email }}
                />
              </p>
            </div>
            <div className={styles.footer}>
              <Button color="white-blue" variant="filled" className={styles.back} onClick={() => onClose(false)}>
                <Icon iconName="arrow" width="20" height="20" />
              </Button>
              <Button color="blue" className={styles.mailbox} fullWidth onClick={handleOpenInbox}>
                <Icon iconName="email" width="24" height="24" />
                {t('MAILBOX')}
              </Button>
            </div>
          </div>
        </div>
      </ContentMobile>
    </ModalLayout>
  );
};
