import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import s from './subscription-mining-duration.module.scss';
import { Typography } from '@/shared/ui/typography';
import { Button } from '@/shared/ui/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { MiningPoolPlanGoogle } from '@/shared/lib/models';
import { Icon } from '@/shared/ui/icons';
import {
  checkPurchase,
  setGoogleInfo,
  setGoogleMiningDuration,
  useGoogleAvailablePurchase,
  useGoogleCurrentPlan,
  useGoogleMiningDurations,
} from '@/page/cart/store/cart-google-store';
import { formatHashrate } from '../hashrate';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import { useCallback } from 'react';
import { HshAlert } from '@/page/cart/ui/v1/bonus-alerts/hsh-alert';
import { useBonusBanner } from '@/page/cart/hooks/use-bonus-banner';
import { BonusNames, usePlansBonusName } from '@/shared/hooks/use-plans-bonus-name';
import { HpsAlert } from '@/page/cart/ui/v1/bonus-alerts/hps-alert';

export const SubscriptionMiningDuration = () => {
  const activeMonth = useSelector((state: RootState) => state.googleCart.miningDuration);
  const plans = useSelector((state: RootState) => state.googleCart.googleData?.plans);
  const availablePurchase = useSelector(useGoogleAvailablePurchase);
  const miningDurationList = useSelector(useGoogleMiningDurations);
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const plansBonusName = usePlansBonusName();
  const { monthsBonus, isShowBanner } = useBonusBanner({
    plans,
    sliceTo: miningDurationList?.length,
    currentPlanMonth: currentPlan?.month,
  });

  const handleButtonClick = useCallback(
    (month: number, productId: string, offerToken: string, basePlanId: string, trial: boolean) => () => {
      dispatch(checkPurchase(availablePurchase?.some((i) => i?.productId === productId)));
      dispatch(setGoogleMiningDuration({ miningDuration: month }));
      dispatch(setGoogleInfo({ productId: productId, offerToken: offerToken, basePlanId: basePlanId, trial: trial }));
    },
    [dispatch]
  );

  return (
    <div className={s.root}>
      <div className={s.top}>
        <Typography variant="h3" className={s.title}>
          <Icon iconName="clock" width={20} height={20} />
          {t('choose mining duration')}
        </Typography>
      </div>

      <div className={s.buttonsContainer}>
        {miningDurationList &&
          miningDurationList.map((month, i) => {
            const discount = plans && (plans?.find((i) => i.month === month) as MiningPoolPlanGoogle)?.discount;
            const plan = plans && plans?.find((i) => i.hps === currentPlan?.hps && i.month === month);
            const isNotAvailable =
              availablePurchase?.some((p) => {
                return p.productId === plan?.productId;
              }) ?? true;
            const pickedPlan = plan ? activeMonth === month : undefined;
            const isDoubleHpsIcon = !plan?.hsh_reward && plan?.tags?.includes(BONUS_TAG_20) && plan?.hps_bonus;

            if (!plan) {
              return <></>;
            }
            return (
              <Button
                borderRadius={false}
                fullWidth
                key={month}
                variant="outline"
                onClick={handleButtonClick(
                  month,
                  plan?.productId || '',
                  plan?.offerToken || '',
                  plan?.basePlanId || '',
                  plan?.trial
                )}
                color="black"
                className={cn(s.button, activeMonth !== month && s.inactive, isNotAvailable && s.actived)}
                style={{ marginBottom: !pickedPlan && i === miningDurationList.length - 1 ? '10px' : '0' }}
              >
                <div className={s.button__container}>
                  <span className={s.button__text}>
                    {month} {t('mo.')}
                  </span>
                  {!isNotAvailable ? (
                    <>
                      <div
                        className={cn(
                          s.button__container_discount,
                          isDoubleHpsIcon && s.button__container_discount_background
                        )}
                      >
                        {discount && discount > 1 ? <span className={s.button__discount}>-{discount}%</span> : null}
                        {plan?.tags?.includes(BONUS_TAG_20) && plan?.hsh_reward && (
                          <Icon iconName="gift-yellow" width={24} height={24} className={s.button__icon} />
                        )}
                        {isDoubleHpsIcon && (
                          <Icon iconName="white-gift-x2" width={15} height={15} className={s.button__icon} />
                        )}
                      </div>
                      <span className={s.button__plan}>
                        {plan && getLocalAmount(plan?.month_price, plan?.currentCurrency)} / {t('mo.')}
                      </span>
                    </>
                  ) : (
                    <span className={s.button__actived}>
                      {t('workerDetails.activated')}
                      <Icon iconName="check-green" width={20} height={20} />
                    </span>
                  )}
                  {!pickedPlan && i === miningDurationList.length - 1 && !isNotAvailable && (
                    <span className={s.button__tag}>{t('MTU.Cloud_boost_buy.Tag_profitable')}</span>
                  )}
                </div>
                {!isNotAvailable && pickedPlan && (plan?.hsh_reward || plan?.hps_bonus) && (
                  <div className={cn(s.button__bonus, pickedPlan && s.button__bonus__active)}>
                    <Icon iconName="gift" width={20} height={20} />
                    {plan?.hsh_reward ? (
                      <>
                        {t('BB_Hsh.Bonus')}:
                        <span className={s.button__right}>
                          +{getLocalAmount(plan?.hsh_reward)} HSH {plan?.tags?.includes(BONUS_TAG_20) && '(x2)'}
                        </span>
                      </>
                    ) : (
                      <>
                        {t('hps_increment')}:
                        <span className={s.button__right}>
                          +{formatHashrate(plan?.hps_bonus)} {plan?.tags?.includes(BONUS_TAG_20) && '(x2)'}
                        </span>
                      </>
                    )}
                  </div>
                )}
              </Button>
            );
          })}
      </div>
      {plansBonusName === BonusNames.HSH && isShowBanner ? <HshAlert months={monthsBonus} /> : null}
      {plansBonusName === BonusNames.HPS && isShowBanner ? <HpsAlert months={monthsBonus} /> : null}
    </div>
  );
};
