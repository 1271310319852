import React, { useCallback } from 'react';
import styles from './review.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentTheme } from '@/features/theme';
import reviewPick from '@/page/review/image/review.svg';
import reviewPickDark from '@/page/review/image/review_dark.svg';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { useRating } from '@/shared/lib/hooks/useRating';
import { ReviewAction } from '@/shared/lib/backend/JsonRpcApi';
import { useNavigate } from 'react-router-dom';
import { HOME_PATH, RATING_GOOGLE_STORE } from '@/shared/lib/utils/links';
import { rootUser, userAction } from '@/shared/store/user/user.slice';
import { AppTheme } from '@/features/theme/model/slice';

export const Review = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentTheme = useSelector(selectCurrentTheme);
  const isDarkTheme = currentTheme === AppTheme.DARK;
  const image = isDarkTheme ? reviewPickDark : reviewPick;
  const titleClass = isDarkTheme ? styles.titleDark : styles.titleLight;
  const { sendReviewDetails } = useRating();
  const { reviewRatingId, ratingMeta } = useSelector((store: { user: rootUser }) => store.user);

  const handleToMarketClick = useCallback(async () => {
    try {
      await sendReviewDetails({
        action: ReviewAction.Click,
        meta: ratingMeta,
        review_id: reviewRatingId,
      }).then(() => userAction.setForceDisableRating(true));
    } catch (error) {
      console.error('Failed to send review details:', error);
      navigate(HOME_PATH);
    } finally {
      window.open(RATING_GOOGLE_STORE);
      navigate(HOME_PATH);
      userAction.setForceDisableRating(true);
    }
  }, [navigate, ratingMeta, reviewRatingId, sendReviewDetails]);

  return (
    <div className={styles.container}>
      <div className={styles.root}>
        <div className={styles.title}>
          <Trans i18nKey="Select_Reviews.Title" components={{ b: <b className={titleClass} /> }} />
        </div>
        <p className={styles.text}>{t('Select_Reviews.Description')}</p>
        <img src={image} alt="ReviewPick" className={styles.image} />
      </div>
      <div className={styles.footer}>
        <Button color="blue" fullWidth onClick={handleToMarketClick}>
          <Icon iconName="write-review" width={20} height={20} />
          {t('Select_Reviews.Btn')}
        </Button>
      </div>
    </div>
  );
};
