import styles from './menu-family.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { useProductsList } from '@/features/crypto-tab-products';
import { ProductTile } from '@/features/crypto-tab-products';
import { Link } from 'react-router-dom';
import { CRYPTO_TAB_PRODUCTS_PATH } from '@/shared/lib/utils/links';

export const MenuFamily = () => {
  const { t } = useTranslation();
  const { products } = useProductsList();

  return (
    <div className={styles.root}>
      <Link to={CRYPTO_TAB_PRODUCTS_PATH} className={styles.titleContainer}>
        <Icon className={styles.sectionIcon} iconName="ct-products" width={20} height={20} />
        <strong>{t('Products.General_title')}</strong>
        <Icon className={styles.arrowIcon} iconName="arrow" width={20} height={20} />
      </Link>

      <div className={styles.productsContainer}>
        {products.map((product) => (
          <ProductTile key={product.name} product={product} />
        ))}
      </div>
    </div>
  );
};
