import styles from './frame-news.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getLanguage } from '@/shared/store/language';
import { Icon } from '../icons';
import { Button } from '@/shared/ui/button';
import cn from 'classnames';
import useMobileDetect from '@/shared/lib/hooks/useMobileDetect';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import { setNews } from '@/shared/store/news/news.slice';
import { rpc } from '@/shared/lib/backend/Rpc';
import { BROWSER_NEWS } from '@/shared/lib/utils/links';
import { RootState } from '@/shared/store/types';
import { getDefaultOptions } from '@/shared/lib/utils/getFormatDate';

export function FrameNews() {
  const { t } = useTranslation();
  const news = useSelector((store: RootState) => store.news);
  const dispatch = useDispatch();

  const lang = getLanguage();

  useEffect(() => {
    const reloadNews = async () => {
      await rpc.transmit('content.pages', { type: 'news', language: lang, limit: 3 }).then((r) => {
        dispatch(setNews(r));
      });
    };
    reloadNews().catch(() => {});
  }, [dispatch, lang]);

  const { isMobile } = useMobileDetect();
  const BROWSER_BASE = `${window.app_config.baseSiteURL}/${lang}`;
  return (
    <div className={styles.root}>
      <div className="container">
        <div className={styles.block}>
          <div className={styles.row}>
            <a href={`${BROWSER_BASE}${BROWSER_NEWS}`} target="_blank" rel="noreferrer" className={styles.title}>
              {isMobile() ? t('News Center') : t('CryptoTab News Center')}{' '}
              <Icon iconName="arrow" width="20" height="20" />
            </a>
          </div>
          <div className={styles.news}>
            <ul className={styles.list}>
              {news.list.map((item, idx) => (
                <li className={styles.item} key={idx}>
                  <a href={item.url} target="_blank" className={styles.item__link} rel="noreferrer">
                    <span className={styles.item__image}>
                      <img src={item.image} alt={item.title} width={285} height={146} />
                    </span>
                    <span className={styles.item__info}>
                      <span className={styles.item__title}>{item.title}</span>
                      <p className={styles.item__text}>{item.description}</p>
                    </span>
                  </a>
                  <div className={styles.item__bottom}>
                    <ul className={styles.tags}>
                      {item.tags.map((tag, idx) => (
                        <li className={styles.tags__item} key={idx}>
                          <a
                            href={`https://cryptobrowser.site/${lang}/news/?tag=${tag}`}
                            target="_blank"
                            rel="noreferrer"
                            className={styles.tags__link}
                          >
                            #{tag}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <a href={item.url} target="_blank" className={styles.item__date} rel="noreferrer">
                      <strong>{t('Published')}</strong>{' '}
                      {new Date(item.published_at).toLocaleString(lang, getDefaultOptions())}
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className={cn(styles.footer)}>
            <Button
              fullWidth
              as="a"
              color="white-blue"
              variant="filled"
              href={`${BROWSER_BASE}${BROWSER_NEWS}`}
              target="_blank"
            >
              {t('View all')} <Icon iconName="arrow" width="20" height="20" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
