export const STORAGE_THEME = 'color-theme';
export const STORAGE_TUTORIAL = 'tutorial-';
export const STORAGE_TUTORIAL_CURRENCY = 'currency';
export const STORAGE_TUTORIAL_SLIDER = 'slider';
export const STORAGE_TUTORIAL_ONBOARDING = 'onboarding';
export const CONFIRMED_EMAIL = 'confirmed-email';
export const STORAGE_BACKGROUND = 'background';
export const STORAGE_RANDOM_BACKGROUND = 'random-background';
export const DISABLE_MEGA_SALE_MODAL = 'disable-mega-sale-modal';
export const MEGA_SALE_MODAL_CLOSED_TIMESTAMP = 'mega-sale-modal-closed-timestamp';
export const LAST_VISIT_RATING = 'lastVisitRating';
export const DID_LOADING = 'did_loading';
export const PURCHASE_INFO_BLOCK_SHOWED = 'purchaseInfoBlockShowed';
export const DID = 'did';
export const RATING = 'rating';
export const EXPLORE_PRODUCTS_NOTIFICATION = 'exploreProductsNotification';
export const TOKENS = 'tokens';
export const IS_VERIFY_EMAIL_NOTIFICATION_SHOWN = 'isVerifyEmailNotificationShown';
export const OFFER_ACCEPTED_BY_USER = 'OfferAcceptedByUser';
export const UTM_OBJ = 'utm_obj';
export const MINING_START_TIMESTAMP = 'miningStartTimestamp';
export const RATING_TIMER_ID = 'ratingTimerId';
export const LAST_SHOW_TOAST_NCW = 'lastShowToastNCW';
export const LAST_SHOWN_DATE = 'lastShownDate';
export const FCM_TOKEN = 'fcmToken';
export const I18_NEXT_LNG = 'i18nextLng';
