import { PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '@/shared/store/store';
import { ErrorModalProvider } from '@/features/error-modal/error-modal';
import { ModalContextProvider } from '@/shared/lib/context/modal';
import { ModalRender } from '@/shared/lib/context/modal/modal-render';
import { ThemeProvider } from '@/features/theme';

export const Providers = ({ children }: PropsWithChildren) => (
  <Provider store={store}>
    <ThemeProvider>
      <ModalContextProvider>
        <BrowserRouter basename={window.app_config.basename}>
          <ErrorModalProvider>{children}</ErrorModalProvider>
          <ModalRender />
        </BrowserRouter>
      </ModalContextProvider>
    </ThemeProvider>
  </Provider>
);
