import { useCallback } from 'react';

export function getScrollElement(): HTMLElement {
  return document.querySelector('#scroll') ?? document.documentElement;
}

export const useScrollTo = (): ((options: ScrollToOptions) => void) => {
  return useCallback((options: ScrollToOptions) => {
    const scroll = getScrollElement();
    const bound = scroll.getBoundingClientRect();
    if (options.top) options.top -= bound.top;
    if (options.left) options.left -= bound.left;
    scroll.scrollTo(options);
  }, []);
};

export function getScrollToCallback(offset: number, callback: () => void) {
  const scrollElement = getScrollElement();

  const onScroll = () => {
    const currentScrollY = window.scrollY;
    const maxScrollValue = document.body.scrollHeight - window.innerHeight;

    if (
      (offset - 1 <= currentScrollY && currentScrollY <= offset + 1) ||
      (currentScrollY <= 0 && offset <= 0) ||
      currentScrollY >= maxScrollValue
    ) {
      window.removeEventListener('scroll', onScroll);
      callback();
    }
  };

  window.addEventListener('scroll', onScroll);
  onScroll(); // call onScroll initially to check the scroll position immediately

  scrollElement.scrollTo({
    top: offset,
    behavior: 'smooth',
  });
}

export const getScrollOffset = (element: HTMLElement | null, offset = 10, stepName: string) => {
  if (element) {
    const bottomOffset = offset + 50;
    const offsetPosition =
      stepName === 'hashrate'
        ? element.getBoundingClientRect().top + window.scrollY - offset
        : element.getBoundingClientRect().bottom + window.scrollY - window.innerHeight + bottomOffset;

    return offsetPosition;
  }
  return 0;
};
