import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './create-withdraw.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import cn from 'classnames';
import { HISTORY_PATH, SWITCH_BALANCE_PATH } from '@/shared/lib/utils/links';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { createWithdrawal, selectWithdrawals } from '@/shared/store/withdrawal/withdrawal.slice';
import { NcwalletBanner } from '@/page/withdraw/ui/ncwallet-banner/ncwallet-banner';
import { Link, useNavigate } from 'react-router-dom';
import { LimitView } from '@/page/withdraw/ui/limit-view/limit-view';
import { BalanceInfo } from '@/page/home/ui/balance-info/balance-info';
import { isValidEmail, isValidWithdrawalAddress } from '@/shared/lib/utils/units';
import { Currency, CurrencyBonus, WithdrawalCreateType, WithdrawalStatus } from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { checkE } from '@/shared/lib/utils/checE';
import { UnconfirmedTransactionInfo } from './unconfirmed-transaction-info';
import { BalanceAdditionalInfo } from '@/page/withdraw/ui/create-withdraw/balance-additional-info';
import { getWithdrawalAddressInfo } from '@/shared/lib/utils/get-withdrawal-address-info';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { BonusBalance } from '@/page/bonus-program/ui/bonus-balance-card/ui/bonus-balance';
import { SwitchBalance } from '@/shared/ui/switch-balance/switch-balance';
import { CustomTippy } from '@/shared/ui/custom-tippy/custom-tippy';
import { ErrorText, IconVariant } from '@/shared/ui/error-text/error-text';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import { NcwIconContainer } from '@/shared/ui/ncw-icon/ncw-icon-container';
import { LAST_SHOW_TOAST_NCW } from '@/shared/lib/constants/storage-key';
import { Currencies } from '@/shared/ui/currency-icon';
import { EmailVerify } from '../email-check/email-verify/email-verify';
import { selectUser } from '@/shared/store/user/user.slice';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

const MIN_TEXTAREA_HEIGHT = 50;
const MIN_AMOUNT = 'MIN_AMOUNT';
const MAX_AMOUNT = 'MAX_AMOUNT';
const EMPTY_AMOUNT = 'EMPTY_AMOUNT';

type CreateWithdrawProps = {
  isMining: boolean;
  currentCurrencyObj?: Currency;
  hsh_currency?: CurrencyBonus;
  formattedBalance: string;
  userCurrency: Currencies;
};

export const CreateWithdraw = ({
  isMining,
  currentCurrencyObj,
  hsh_currency,
  formattedBalance,
  userCurrency,
}: CreateWithdrawProps) => {
  const rate = useSelector((store: RootState) => store.rate);
  const { mining_currencies, currencies, pool_balance, hsh_balance } = useSelector((store: RootState) => store.balance);
  const { min_withdrawal_amount, email, currency, email_verified, ncw_min_withdrawal_amount } = useSelector(selectUser);
  const withdrawals = useSelector(selectWithdrawals);
  const status = useSelector((store: RootState) => store.withdrawal.createWithdrawalStatus);
  const allAddresses = useSelector((store: RootState) => store.withdrawal.withdrawalsAddresses);
  const { isIos } = useDevice();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const showTooltip = () => setTooltipVisible(true);
  const hideTooltip = () => setTooltipVisible(false);
  const [amount, setAmount] = useState('');
  const [address, setAddress] = useState('');
  const [errorAmount, setErrorAmount] = useState('');
  const [isNcw, setIsNcw] = useState(true);
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const { openModal } = useModalSet();
  const navigate = useNavigate();
  const amountInputRef = useRef<HTMLInputElement>(null);

  const waitTransactions = useMemo(
    () =>
      withdrawals.filter(
        (item) =>
          (item.status === WithdrawalStatus.Created ||
            item.status === WithdrawalStatus.PendingFunds ||
            item.status === WithdrawalStatus.Pending) &&
          item.currency !== 'HSH'
      ),
    [withdrawals]
  );

  const filteredAddresses = useMemo(() => {
    return allAddresses.filter((item) => {
      return userCurrency === 'BTC' && isMining ? true : getWithdrawalAddressInfo(item).isNcw;
    });
  }, [allAddresses, userCurrency, isMining]);

  const hasSavedAddresses = filteredAddresses.length > 0;
  const hasWaitTransaction = waitTransactions.length > 0 && isMining;
  const limitTransaction = waitTransactions.length >= 10 && isMining;
  const isWrongWithdrawalPayload = limitTransaction || hasWaitTransaction || errorAmount != '' || +amount <= 0;

  useEffect(() => {
    const isConnectNcwAddress = filteredAddresses.some((address) => 'email' in address);

    const lastShowToast = localStorage.getItem(LAST_SHOW_TOAST_NCW);
    const lastShowToastDate = new Date(lastShowToast || 0);
    const now = new Date();
    const oneDay = 24 * 60 * 60 * 1000; // 1 day
    const diff = now.getTime() - lastShowToastDate.getTime() > oneDay;

    if (!isConnectNcwAddress && (diff || !lastShowToast) && email_verified) {
      toaster.info('Connect NC Wallet');
      localStorage.setItem(LAST_SHOW_TOAST_NCW, new Date().toString());
    }
  }, [filteredAddresses]);

  useEffect(() => {
    if (!hasSavedAddresses) {
      return setAddress('');
    }

    const lastAddress = filteredAddresses[0];
    if (!lastAddress) return;

    const addressInfo = getWithdrawalAddressInfo(lastAddress);

    if (addressInfo.isNcw) {
      setAddress(addressInfo.email || addressInfo.account_id);
      setIsNcw(true);
      return;
    }

    setAddress(addressInfo.address);
    setIsNcw(false);
  }, [filteredAddresses, hasSavedAddresses]);

  const currentBalance = useMemo(
    () => (isMining ? formattedBalance : hsh_balance.current),
    [isMining, formattedBalance, hsh_balance.current]
  );

  const currentMinimumAmount = useMemo(() => {
    if (!isMining) return checkE(hsh_currency?.min_withdrawal || +ncw_min_withdrawal_amount);
    return isNcw
      ? checkE(currentCurrencyObj?.min_withdrawal || +ncw_min_withdrawal_amount)
      : checkE(+min_withdrawal_amount);
  }, [min_withdrawal_amount, currentCurrencyObj?.min_withdrawal, hsh_currency?.min_withdrawal, isNcw, isMining]);

  const isLessMinBalance = useMemo(() => {
    return isMining ? +pool_balance.total < +currentMinimumAmount : +hsh_balance.total < +currentMinimumAmount;
  }, [isMining, currentMinimumAmount, pool_balance.total, hsh_balance.total]);

  useEffect(() => {
    if (isLessMinBalance) setErrorAmount(EMPTY_AMOUNT);
    else setErrorAmount('');
  }, [isLessMinBalance]);

  const disabledAmount = useMemo(
    () => !address.length || hasWaitTransaction || !isValidWithdrawalAddress(address) || isLessMinBalance,
    [address, hasWaitTransaction, isLessMinBalance]
  );

  const handleChange = useCallback(
    (value: string) => {
      let formattedValue = value;

      formattedValue = formattedValue.replace(',', '.');

      const firstZerosRow = /^0[0-9]/.test(formattedValue);
      const firstDotted = /^\./.test(formattedValue);

      // Match only numbers and decimal points
      if (formattedValue.match(/^([0-9]+)?(\.)?([0-9]+)?$/) && !firstZerosRow && !firstDotted) {
        setAmount(formattedValue);
        setErrorAmount('');
      }
    },
    [isIos, currentBalance, currentMinimumAmount]
  );

  const handleCreate = async () => {
    if (+amount > +currentBalance) {
      return setErrorAmount(MAX_AMOUNT);
    }
    if (+amount < +currentMinimumAmount) {
      return setErrorAmount(MIN_AMOUNT);
    }
    try {
      // Check if the address looks like an email
      if (isValidEmail(address)) {
        try {
          const r = await rpc.transmit('ncw.find_account', { value: address });
          if (r) {
            dispatch(
              createWithdrawal({
                address: r?.account_id,
                amount: +amount,
                type: isMining ? WithdrawalCreateType.Regular : WithdrawalCreateType.Hsh,
                openModal,
                navigate,
              })
            );
          } else {
            // Handle the case where ncw.find_account does not return an account
            openModal('MODAL_NC_WALLET_REJECT', { email: address, isServices: true });
          }
        } catch (error) {
          // If ncw.find_account RPC call fails, open the rejection modal
          openModal('MODAL_NC_WALLET_REJECT', { email: address, isServices: true });
        }
      } else {
        dispatch(
          createWithdrawal({
            address,
            amount: +amount,
            type: isMining ? WithdrawalCreateType.Regular : WithdrawalCreateType.Hsh,
            openModal,
            navigate,
          })
        );
      }
    } catch (e) {
      console.log('Operation error', e);
    }
  };

  const onAddressClick = () => {
    openModal('MODAL_ADDRESS_INFO', {
      addresses: filteredAddresses,
      currentAddress: address,
      onClick: handleSetAddress,
      setIsNcw: setIsNcw,
      userAccountEmail: email,
      isServices: true,
    });
  };

  const handleSetAddress = async (addr: string) => {
    setAddress(addr);
  };

  const onSetMinAmountClick = useCallback(() => {
    if (disabledAmount) return;

    if (+currentBalance >= +currentMinimumAmount) {
      setAmount(String(currentMinimumAmount));
      setErrorAmount('');
    } else {
      setErrorAmount(MIN_AMOUNT);
    }
  }, [currentMinimumAmount, currentBalance, disabledAmount]);

  const onSetMaxAmountClick = useCallback(() => {
    if (disabledAmount) return;

    if (+currentBalance >= +currentMinimumAmount) {
      setAmount(String(currentBalance));
      setErrorAmount('');
    } else {
      setErrorAmount(MIN_AMOUNT);
    }
  }, [currentMinimumAmount, currentBalance, disabledAmount]);

  const cardVariants = {
    title: t('Your balance'),
    minedTitle: t('Pool Mining Income:'),
    affTitle: t('Affiliate_income'),
    poolTitle: t('Pool Mining'),
    nftTitle: t('Nft Mining'),
    totalTitle: t('Total Earnings:'),
    mined: pool_balance.pool,
    referral: pool_balance.affiliate,
    total: pool_balance.total,
    balance: pool_balance.balance,
  };

  return (
    <>
      {limitTransaction ? (
        <div className={styles.root}>
          <LimitView />
          <div className={styles.footer}>
            <Button
              color="blue"
              fullWidth
              className={styles.btn}
              onClick={handleCreate}
              disabled={status === 'loading' || limitTransaction}
              loading={status === 'loading'}
            >
              <Icon iconName="transfer" width="24" height="20" />
              {t('Withdraw BTC')}
            </Button>
          </div>
        </div>
      ) : (
        <>
          {isMining ? (
            <>
              <div className={styles.balance}>
                <div className={styles.sectionHeader}>
                  <span>{t('Balance')}</span>
                </div>
                <div className={styles.balanceContent}>
                  <BalanceInfo />
                  <BalanceAdditionalInfo
                    title={cardVariants.totalTitle}
                    balance={cardVariants.mined}
                    btc2usd={rate.crypto2usdRate}
                    className={styles.last}
                    currency={currency}
                    mining_currencies={mining_currencies}
                  />
                  <SwitchBalance userCurrency={userCurrency} handleClick={() => navigate(SWITCH_BALANCE_PATH)} />
                </div>
              </div>
            </>
          ) : (
            <div className={styles.balance}>
              <BonusBalance
                balance={hsh_balance.total}
                income={hsh_balance.income}
                accrued={hsh_balance.accrued}
                nextPayment={hsh_balance.nextPayment}
              />
            </div>
          )}

          <div className={styles.root}>
            <div className={styles.sectionHeader}>
              <span>{t('Withdraw_pg.Main_Title')}</span>
              {email_verified && (
                <Button as={Link} to={HISTORY_PATH} className={styles.sectionHeaderButton} variant="text" color="blue">
                  <Icon iconName="history" width="21" height="20" />
                </Button>
              )}
            </div>
            {!email_verified ? (
              <EmailVerify />
            ) : (
              <>
                <div className={styles.block}>
                  <div className={styles.block__header}>
                    <span className={styles.label__wrapTitle}>
                      {isNcw || !isMining ? (
                        <NcwIconContainer width={20} height={20} className={styles.label__icon} />
                      ) : (
                        <Icon iconName={'wallet'} width={20} height={20} className={styles.label__icon} />
                      )}

                      {isNcw || !isMining ? t('Withdrawal.NCW_account.Title') : t('Bitcoin Address')}
                      <CustomTippy
                        content={
                          <div className={styles.tooltip__content}>
                            <Trans
                              i18nKey={t('Withdrawal.NCW_Account.Tip')}
                              values={{ currency: isMining ? userCurrency : 'HSH' }}
                            />
                            <Icon
                              iconName="close"
                              width={16}
                              height={16}
                              className={styles.tooltip__close}
                              onClick={hideTooltip}
                            />
                          </div>
                        }
                        visibleTooltip={tooltipVisible}
                        hideTooltip={hideTooltip}
                      >
                        <div className={styles.tooltip} onClick={tooltipVisible ? hideTooltip : showTooltip}>
                          <Icon iconName="qa" width={16} height={16} />
                        </div>
                      </CustomTippy>
                    </span>
                  </div>

                  <div
                    className={styles.labelWrap}
                    onClick={(e) => {
                      e.preventDefault();
                      onAddressClick();
                    }}
                  >
                    <label className={styles.label}>
                      <div className={styles.label__container}>
                        <input
                          className={cn(styles.label__input, styles.small, styles.not_focus)}
                          placeholder={t('Add New Address')}
                          readOnly={true}
                          value={address}
                          style={{
                            minHeight: MIN_TEXTAREA_HEIGHT,
                            resize: 'none',
                          }}
                        />
                      </div>
                    </label>
                    <span className={cn(styles.label__openAddress)}>
                      <Icon iconName="arrowDown" width="12" height="13" />
                    </span>
                  </div>
                </div>
                {(!isNcw || !address) && <NcwalletBanner email={email} />}
                {hasWaitTransaction && isMining && (
                  <div className={cn(styles.block, styles.unconfirmedWithdrawBlock)}>
                    <UnconfirmedTransactionInfo withdrawal={waitTransactions[0]} currencies={currencies} />
                  </div>
                )}
                <div className={cn(styles.block, styles.block__amount)}>
                  <label className={styles.label}>
                    <div className={styles.label__titleItems}>
                      <div className={styles.label__wrapTitle}>
                        <img
                          src={isMining ? currentCurrencyObj?.img : hsh_currency?.img}
                          alt="ico"
                          width={20}
                          height={20}
                          className={styles.balance__icon}
                        />
                        {t('Withdrawal.Amount.Title')}
                      </div>
                    </div>
                    <div className={styles.label__container}>
                      <input
                        className={cn(
                          styles.label__input,
                          styles[`label__input--with-currency`],
                          errorAmount != '' && styles.error
                        )}
                        placeholder={`${getLocalAmount(currentMinimumAmount)}`}
                        type="text"
                        inputMode="decimal"
                        value={amount}
                        disabled={disabledAmount}
                        onChange={(e) => handleChange(e.target.value)}
                        ref={amountInputRef}
                      />
                      <span className={styles.label__currency}>
                        {isMining ? currentCurrencyObj?.symbol : hsh_currency?.symbol}
                      </span>
                    </div>
                  </label>
                  <div>
                    <div className={styles.amountWrap}>
                      <div
                        className={cn(
                          styles.amount,
                          styles.amount__min,
                          (errorAmount === MIN_AMOUNT || errorAmount === EMPTY_AMOUNT) && styles.amount_error,
                          disabledAmount && styles.amount_disabled
                        )}
                        onClick={onSetMinAmountClick}
                      >
                        {t('min')}. {getLocalAmount(currentMinimumAmount)}{' '}
                        {isMining ? currentCurrencyObj?.symbol : hsh_currency?.symbol}
                      </div>
                      <div
                        className={cn(
                          styles.amount,
                          styles.amount__max,
                          errorAmount === MAX_AMOUNT && styles.amount_error,
                          disabledAmount && styles.amount_disabled
                        )}
                        onClick={onSetMaxAmountClick}
                      >
                        <strong>
                          {t('Max.')} {getLocalAmount(currentBalance)}{' '}
                          {isMining ? currentCurrencyObj?.symbol : hsh_currency?.symbol}
                        </strong>
                      </div>
                    </div>
                    {errorAmount === MIN_AMOUNT && (
                      <ErrorText iconVariant={IconVariant.circle} text={t('Withdr.Min_error')} />
                    )}
                    {errorAmount === MAX_AMOUNT && (
                      <ErrorText iconVariant={IconVariant.circle} text={t('Withdr.Max_error')} />
                    )}
                    {errorAmount === EMPTY_AMOUNT && (
                      <ErrorText iconVariant={IconVariant.circle} text={t('NoFunds.State')} />
                    )}
                  </div>
                </div>
                <div className={styles.footer}>
                  <Button
                    color="blue"
                    fullWidth
                    className={styles.btn}
                    onClick={handleCreate}
                    disabled={status === 'loading' || isWrongWithdrawalPayload}
                    loading={status === 'loading'}
                  >
                    <Icon iconName="transfer" width="24" height="20" />
                    {t('Confirm Withdrawal')}
                  </Button>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};
