import styles from './rating-block.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { LoadSpinner } from '@/shared/ui/loader/loader';
import cn from 'classnames';
import { RatingEmoji } from '@/shared/ui/ratingEmoji/ratingEmoji';
import { Rating } from '@/shared/ui/rating/rating';
import { RatingIcons } from '@/shared/lib/backend/JsonRpcApi';
import { useEffect, useState } from 'react';

type RatingBlockProps = {
  onClose: (e: boolean) => void;
  handleOnRate: (e: number) => void;
  rating: number;
  loading: boolean;
  ratingIcons?: RatingIcons;
};

export const RatingBlock = ({
  onClose,
  handleOnRate,
  rating,
  loading,
  ratingIcons = RatingIcons.Stars,
}: RatingBlockProps) => {
  const { t } = useTranslation();
  const getArrayIconsType = (value: RatingIcons) => {
    switch (value) {
      case 'emoji':
        return <RatingEmoji value={rating} onChange={handleOnRate} />;
      default:
        return <Rating value={rating} onChange={handleOnRate} />;
    }
  };
  const [isClosing, setIsClosing] = useState<boolean>(false);

  useEffect(() => {
    if (isClosing) {
      const turn = setTimeout(() => {
        onClose(false);
        setIsClosing(false);
      }, 250);

      return () => clearTimeout(turn);
    }
  }, [isClosing, onClose]);

  return (
    <>
      <div className={cn(styles.block, isClosing && styles.slideOut)}>
        <span
          className={styles.close}
          onClick={() => {
            setIsClosing(true);
            onClose(false);
          }}
        >
          <Icon iconName="close" width="20" height="20" />
        </span>
        <h6 className={styles.title}>{t('MTU.Modal.Feedback.Title')}</h6>
        <p className={styles.text}>{t('MTU.Modal.Feedback.Description')}</p>
        <div className={styles.rating}>
          {loading ? <LoadSpinner className={styles.loader} /> : getArrayIconsType(ratingIcons)}
        </div>
      </div>
    </>
  );
};
