import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { AppDispatch } from '@/shared/store/types';
import { rpc } from '@/shared/lib/backend/Rpc';
import { withdrawalAction } from '@/shared/store/withdrawal/withdrawal.slice';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { isValidWithdrawalAddress } from '@/shared/lib/utils/units';
import { CONFIRMED_EMAIL } from '@/shared/lib/constants/storage-key';
import ModalLayout from '@/shared/ui/modal/modal';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import { ErrorText } from '@/shared/ui/error-text/error-text';
import styles from './btc-connect-modal.module.scss';

export const BtcConnectModal = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const [address, setAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const handleConfirm = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await rpc.transmit('wallets.check_address', { address });

      if (res) {
        let addressToLocal;
        if ('email' in res) {
          addressToLocal = { account_id: res.account_id, email: res.email };
        } else {
          addressToLocal = { address };
        }

        localStorage.setItem(CONFIRMED_EMAIL, JSON.stringify(addressToLocal));
        dispatch(withdrawalAction.addNewAddress(addressToLocal));
        toaster.success(t('Successfully_Connected'));
        navigate(WITHDRAW_PATH);
      }
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [address]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setIsError(false);
    setAddress(e.target.value.trim());
  }, []);

  const isValid = useMemo(() => isValidWithdrawalAddress(address), [address]);

  return (
    <ModalLayout iconClose onClose={() => onClose(false)} isOpen={isOpen} id="connect-btc">
      <div
        className={styles.root}
        ref={overlayRef}
        onClick={(e) => {
          if (e.target === overlayRef.current) onClose(false);
        }}
      >
        <div className={styles.container}>
          <div
            className={styles.block}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <span className={styles.close} onClick={() => onClose(false)}>
              <Icon iconName="close" width="20" height="20" />
            </span>
            <Icon iconName="btc" width={80} height={80} className={styles.icon} />
            <h6 className={styles.title}>{t('Enter_BTC.Title')}</h6>
            <p className={styles.text}>{t('Enter_BTC.Descr')}</p>
            <label htmlFor="address" className={styles.label}>
              <input
                id={'address'}
                value={address}
                onChange={(e) => handleChange(e)}
                className={cn(styles.input, isLoading && styles[`input--loading`], isError && styles[`input--error`])}
                placeholder={t('Enter_BTC.Placehold')}
              />
              {isError && <ErrorText text={t('Enter_BTC.Error')} />}
            </label>
          </div>
          <div className={styles.footer}>
            <Button
              color="blue"
              fullWidth
              onClick={handleConfirm}
              disabled={isLoading || isError || !isValid}
              loading={isLoading}
            >
              <Icon iconName="checked" width="17" height="16" />
              {t('Balance_currency.Conversion.Confirm.Btn')}
            </Button>
            <Button color="blue" variant="outline" fullWidth onClick={() => onClose(false)}>
              <Icon iconName="closeCircleOutline" width="20" height="20" />
              {t('CANCEL')}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
