import ModalLayout from '@/shared/ui/modal/modal';
import styles from './wrong-address-withdrawal-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';

export const WrongAddressWithdrawalModal = ({
  onClose,
  isOpen,
}: {
  onClose: (e: boolean) => void;
  isOpen: boolean;
}) => {
  const { t } = useTranslation();
  return (
    <ModalLayout iconClose onClose={() => onClose(false)} isOpen={isOpen} id="error">
      <div className={styles.root} onClick={() => onClose(false)}>
        <div
          className={styles.block}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span className={styles.close} onClick={() => onClose(false)}>
            <Icon iconName="close" width="20" height="20" />
          </span>
          <Icon iconName="error" width={80} height={80} className={styles.icon} />
          <h6 className={styles.title}>{t('Error.Inv_address.Title')}</h6>
          <p className={styles.text}>{t('Error.Inv_address.Description')}</p>
        </div>
        <div className={styles.footer}>
          <Button color="blue" className={styles.mailbox} fullWidth onClick={() => onClose(false)}>
            <Icon iconName="checked" width="17" height="16" />
            {t('OK, GOT IT')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
