import styles from './withdrawal-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import ModalLayout from '@/shared/ui/modal/modal';
import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import cn from 'classnames';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

export const SuccessWithdrawalModal = ({
  onClose,
  isOpen,
  amount,
  currency,
}: {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  amount: string;
  currency: string;
}) => {
  const { t } = useTranslation();
  const [isClosing, setIsClosing] = useState<boolean>(false);

  useEffect(() => {
    if (isClosing) {
      const turn = setTimeout(() => {
        onClose(false);
        setIsClosing(false);
      }, 200);

      return () => clearTimeout(turn);
    }
  }, [isClosing]);

  return (
    <ModalLayout iconClose onClose={() => setIsClosing(true)} isOpen={isOpen} id="success-withdrawal">
      <div className={styles.root} onClick={() => setIsClosing(true)}>
        <div
          className={cn(styles.block, isClosing && styles.slideOut)}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span className={styles.close} onClick={() => setIsClosing(true)}>
            <Icon iconName="close" width="20" height="20" />
          </span>
          <Icon iconName="confirmed" width={80} height={80} className={styles.icon} style={{ color: '#37CC33' }} />
          <h6 className={styles.title}>{t('WithdrawalCancelSuccess.Title')}</h6>
          <p className={styles.text}>
            {
              <Trans
                i18nKey={t('WithdrawalCancelSuccess.Description')}
                values={{ amount: getLocalAmount(amount), currency }}
                components={{ b: <strong /> }}
              />
            }
          </p>
          <div className={styles.buttonBlock}>
            <Button color="blue" borderRadius={false} fullWidth onClick={() => setIsClosing(true)}>
              <Icon iconName="checked" width="17" height="16" />
              {t('OK, GOT IT')}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
