import React from 'react';
import styles from './bonus-balance-card.module.scss';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { Link, useNavigate } from 'react-router-dom';
import { BONUS_PROGRAM, WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { Icon } from '@/shared/ui/icons';
import browserShare from '@/shared/lib/utils/browserShare';
import { BonusBalance } from '@/page/bonus-program/ui/bonus-balance-card/ui/bonus-balance';
import { DailyTransactionsHistory } from '@/shared/lib/backend/JsonRpcApi';
import { BONUS } from '@/page/withdraw/ui/switch-withdrawal-tabs/switch-withdrawal-tabs';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useShareProduct } from '@/shared/lib/hooks/useShareProduct';

export const BonusBalanceCard = ({
  withdrawal = false,
  items,
  balance,
  increased,
  accrued,
  income,
}: {
  balance?: number;
  increased?: number;
  withdrawal?: boolean;
  items?: DailyTransactionsHistory[];
  accrued?: number;
  income?: number;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isTelegramMiniApp } = useDevice();

  const { poolRefLink, text } = useShareProduct();

  const onClickShare = async () => {
    try {
      await browserShare({
        url: poolRefLink,
        text: text,
        isTelegramMiniApp,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  const onClickWithdrawal = async () => {
    navigate(`${WITHDRAW_PATH}/${BONUS}`);
  };

  return (
    <div className={styles.container}>
      <BonusBalance items={items} balance={balance} increased={increased} accrued={accrued} income={income} />
      <div className={styles.footer}>
        {withdrawal ? (
          <Button fullWidth color="blue" onClick={onClickWithdrawal}>
            <Icon iconName="transfer" width="24" height="20" />
            {t('BB_Hsh.Withdraw')}
          </Button>
        ) : (
          <>
            <Button fullWidth color="green" onClick={onClickShare}>
              <Icon iconName="share" width="20" height="20" />
              {t('BB_Hsh.Share_btn')}
            </Button>
            <Button as={Link} to={BONUS_PROGRAM} color="white-blue" variant="filled">
              <Icon iconName="info" width="20" height="20" />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
