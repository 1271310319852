import React, { useEffect, useState } from 'react';
import s from '@/page/cart/ui/v2/mining-duration/mining-duration.module.scss';
import { MiningDurationButton } from '@/page/cart/ui/v2/mining-duration/mining-duration-button';
import { Button } from '@/shared/ui/button';
import { BottomSheetModal } from '@/page/cart/ui/v2/bottom-sheet-modal';
import { useSelector } from 'react-redux';
import { useCurrentPlan, useMiningDurations } from '@/page/cart/store';
import { formatPlanPrice } from '@/page/cart/ui/v2/mining-duration/mining-duration.helpers';
import { RootState } from '@/shared/store/types';
import { Trans, useTranslation } from 'react-i18next';
import { Icon } from '@/shared/ui/icons';
import cn from 'classnames';
import { BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';

type MiningDurationsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelected: (month: number) => void;
};

export const MiningDurationsModal = ({ isOpen, onClose, onSelected }: MiningDurationsModalProps) => {
  const { t } = useTranslation();
  const miningDurations = useSelector(useMiningDurations);
  const currentPlan = useSelector(useCurrentPlan);
  const plans = useSelector((state: RootState) => state.cart.data?.plans);
  const { cryptoBtc2userCurrencyRate } = useSelector((store: RootState) => store.rate);
  const { currency: userCurrency } = useSelector((store: RootState) => store.user);
  const { mining_currencies } = useSelector((store: RootState) => store.balance);
  const currentCurrencyObj = mining_currencies?.find((cur) => cur?.symbol === userCurrency);
  const activeMonth = useSelector((state: RootState) => state.cart.miningDuration)!;
  const hasTrial = useSelector((state: RootState) => state.googleCart.trial);

  const [selectedMonth, setSelectedMonth] = useState(activeMonth);
  const [hasDouble, setHasDouble] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (isOpen) return;

    setSelectedMonth(activeMonth);
  }, [activeMonth, isOpen]);

  useEffect(() => {
    setHasDouble(
      plans?.find((p) => p.hps === currentPlan.hps && p.month === selectedMonth)?.tags.includes(BONUS_TAG_20)
    );
  }, [selectedMonth]);

  const onMonthSelect = (month: number) => {
    setSelectedMonth(month);
  };

  const onConfirm = () => {
    onSelected(selectedMonth);
    onClose();
  };

  return (
    <BottomSheetModal isOpen={isOpen} onClose={onClose} title={t('N.SelectDuration.Title')}>
      <div className={s.buttonsContainer}>
        {miningDurations &&
          miningDurations.map((month, i) => {
            const plan = plans?.find((p) => p.hps === currentPlan.hps && p.month === month);
            const discount = plan?.discount;
            const formattedPrice = formatPlanPrice(plan, cryptoBtc2userCurrencyRate, currentCurrencyObj);
            const isBonus = plans
              ?.find((p) => p.hps === currentPlan.hps && p.month === month)
              ?.tags.includes(BONUS_TAG_20);
            return (
              <MiningDurationButton
                key={i}
                month={month}
                discount={discount}
                formattedPrice={formattedPrice}
                isBonus={isBonus}
                userCurrency={userCurrency}
                isActive={selectedMonth === month}
                onClick={() => onMonthSelect(month)}
                isMostProfitable={i === miningDurations.length - 1}
              />
            );
          })}
      </div>
      {hasDouble && (
        <div className={cn(s.note, s.note__purple)}>
          <div className={cn(s.note__icon__container, s.note__icon__container__purple)}>
            <Icon iconName="white-gift-x2" width={20} height={20} className={s.note__icon} />
          </div>
          <span className={cn(s.note__description, s.note__description__purple)}>{t('BonusHashx2.Description')}</span>
        </div>
      )}
      {hasTrial && (
        <div className={cn(s.note, s.note__green)}>
          <div className={cn(s.note__icon__container, s.note__icon__container__green)}>
            <Icon iconName="timer" width={20} height={20} className={s.note__icon} />
          </div>
          <span className={cn(s.note__description, s.note__description__green)}>
            <span>{t('Trial.Title')}</span>
            <span className={s.note__description__subtitle}>
              <Trans
                i18nKey={t('Trial.Description')}
                values={{
                  price: `${formatPlanPrice(
                    currentPlan,
                    cryptoBtc2userCurrencyRate,
                    currentCurrencyObj
                  )} ${userCurrency}`,
                  duration: currentPlan.month,
                }}
              />
            </span>
          </span>
        </div>
      )}
      <Button
        fullWidth
        color="white-blue"
        variant="filled"
        onClick={onConfirm}
        className={s.confirmButton}
        borderRadius={false}
      >
        <Icon iconName="checked" width={18} height={18} />
        <span>{t('confirm')}</span>
      </Button>
    </BottomSheetModal>
  );
};
