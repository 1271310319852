import { useTranslation } from 'react-i18next';
import s from './subscription-total-price.module.scss';
import { Typography } from '@/shared/ui/typography';
import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';
import { Discount } from '@/page/cart/ui/v1/total-price/discount';
import { useGoogleCurrentPlan, useGoogleHashrate } from '@/page/cart/store/cart-google-store';
import { formatSpeed } from '@/shared/lib/utils/getFormatSpeed';
import cn from 'classnames';
import { useCallback } from 'react';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

const PLAN_TAG = 'bonus';

export const SubscriptionTotalPrice = () => {
  const { t } = useTranslation();
  const miningDuration = useSelector((state: RootState) => state.googleCart.miningDuration);
  const hashrate = useSelector(useGoogleHashrate);
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const { openModal } = useModalSet();

  const openHshModal = useCallback(() => {
    openModal('MODAl_INFO_BONUS_HSH', { isServices: true });
  }, []);

  return (
    <div className={s.container}>
      <div className={s.totalPriceRows}>
        <div className={s.row}>
          <Typography variant="h6" weight="bold" className={s.row__typography}>
            <Icon iconName="cartSell" height={20} width={20} />
            {t('OrdDetails.Title')}
          </Typography>
        </div>
        <div className={s.row}>
          <div className={s.priceRow}>
            <Typography variant="h5" weight="bold">
              <span className={s.currentMiner}>
                Pool Miner {hashrate && formatSpeed(hashrate)} ({miningDuration} {t('mo.')})
              </span>
            </Typography>
            <div className={s.priceContainer}>
              <Typography variant="h3">
                {currentPlan && getLocalAmount(currentPlan?.total_price, currentPlan?.currentCurrency)}
              </Typography>
            </div>
          </div>
          <div className={s.priceContainer}>
            <Icon iconName="offer" height={20} width={20} />
            {t('Regular Price')}
            <div className={s.oldPrice}>
              {' '}
              <Typography variant="h3"></Typography>
              {currentPlan && getLocalAmount(currentPlan?.old_price, currentPlan?.currentCurrency)}
            </div>
          </div>
        </div>

        {currentPlan && currentPlan?.discount > 0 && (
          <div className={s.flex}>
            <Icon iconName="percent" width={20} height={20} className={s.green} />
            <span className={s.discounts}>{t('N.YourDiscount.Title')}</span>
            <Discount discount={currentPlan?.discount} promoDiscount={currentPlan?.promo_discount} />
          </div>
        )}

        {currentPlan && currentPlan?.discount > 0 && (
          <div className={s.flex}>
            <Icon iconName="check-blue" width={20} height={20} />
            <span className={s.discounts}>{t('Purchase_scr.Total_Discount.Title')}</span>
            <div className={s.savePrice}>
              {' '}
              <p>{`${currentPlan && getLocalAmount(currentPlan?.save_price, currentPlan?.currentCurrency)}`}</p>
            </div>
          </div>
        )}
      </div>
      {currentPlan?.tags?.includes(PLAN_TAG) && (currentPlan?.hsh_reward || currentPlan?.hps_bonus) && (
        <div className={s.bonuses}>
          <div className={s.bonus}>
            {currentPlan?.hsh_reward ? (
              <>
                <Icon iconName="hsh" width={20} height={20} />
                <div>
                  <span className={cn(s.bonus__title, s[`bonus__title--hsh`])} onClick={openHshModal}>
                    {t('HashCoins')}
                  </span>
                  {currentPlan?.tags?.includes(BONUS_TAG_20) && <span className={s[`bonus__double--hsh`]}> (x2)</span>}{' '}
                </div>
                <Discount promoDiscount={0} discount={0} bonusHSH={currentPlan?.hsh_reward} />
                <Icon
                  iconName={'question'}
                  width={20}
                  height={20}
                  className={s.bonus__title_question}
                  onClick={openHshModal}
                />
              </>
            ) : (
              <>
                {currentPlan?.tags?.includes(BONUS_TAG_20) ? (
                  <>
                    <Icon iconName={'purple-gift-x2'} width={20} height={20} />
                    <span className={s.bonus__title}>
                      {t('hps_increment')} <span className={s[`bonus__double--hps`]}> (x2)</span>
                    </span>
                    <Discount promoDiscount={0} discount={0} double={currentPlan?.hps_bonus} />
                  </>
                ) : (
                  <>
                    <Icon iconName={'blue-gift'} width={20} height={20} />
                    <span className={s.bonus__title}>{t('hps_increment')}</span>
                    <Discount promoDiscount={0} discount={0} bonus={currentPlan?.hps_bonus} />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
