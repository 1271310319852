import styles from './bottom-sheet-modal.module.scss';
import Modal from 'react-modal';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Icon } from '@/shared/ui/icons';
import { Typography } from '@/shared/ui/typography';
import cn from 'classnames';

type BottomSheetModalProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
};

export const BottomSheetModal = ({ isOpen, onClose, title, children }: PropsWithChildren<BottomSheetModalProps>) => {
  const overlayRef = useRef<HTMLDivElement | null>(null);
  const [isClosing, setIsClosing] = useState(false);

  const handleClickOutside = (e: Event) => {
    if (e.target === overlayRef.current) {
      setIsClosing(true);
    }
  };

  useEffect(() => {
    if (isClosing) {
      const turn = setTimeout(() => {
        onClose();
        setIsClosing(false);
      }, 250);

      return () => clearTimeout(turn);
    }
  }, [isClosing, onClose]);

  useEffect(() => {
    window.addEventListener('click', handleClickOutside);
    return () => window.removeEventListener('click', handleClickOutside);
  });
  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onRequestClose={() => setIsClosing(true)}
      overlayClassName={styles.overlay}
    >
      <div className={cn(styles.container, isClosing && styles.slideOut)} ref={overlayRef}>
        <div className={styles.contentContainer}>
          <div className={title ? styles.headerContainer : styles.notTitle}>
            <Typography variant="h4" weight="bold">
              {title}
            </Typography>
            <Icon iconName="close" className={styles.closeButton} onClick={() => setIsClosing(true)} />
          </div>

          {children}
        </div>
      </div>
    </Modal>
  );
};
