import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import ModalLayout from '@/shared/ui/modal/modal';
import { useCallback } from 'react';
import hshDecoration from './images/bg_hc_mob_img-min.png';
import styles from './modal-info-bonus-hsh.module.scss';
import { useLocation } from 'react-router-dom';
import { NC_WALLET } from '@/shared/lib/utils/links';

export const PURCHASE_HSH = 'PURCHASE_HSH';
export const ONE_WEEK = 604_800_000;

export const ModalInfoBonusHsh = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const handleDontShow = useCallback(() => {
    const localDate = localStorage.getItem(PURCHASE_HSH) || '{}';
    const lastBalance = JSON.parse(localDate);
    const visit = { ...lastBalance, isShow: !lastBalance.isShow };
    localStorage.setItem(PURCHASE_HSH, JSON.stringify(visit));
  }, []);

  return (
    <ModalLayout
      className={styles.modal}
      iconClose
      onClose={() => onClose(false)}
      isOpen={isOpen}
      id="ModalIncreasingHsh"
    >
      <div className={styles.root} onClick={() => onClose(false)}>
        <div className={styles.decoration}>
          <img src={hshDecoration} alt="img-hsh" />
        </div>
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <div className={styles.block}>
            <span className={styles.close} onClick={() => onClose(false)}>
              <Icon iconName="close" width="20" height="20" />
            </span>
            <Icon iconName={'hsh'} className={styles.icon} width="80" height="81" />
            <h6 className={styles.title}>{t('HSH_Upd.HSH_popup.Title')}</h6>
            <p className={styles.text}>
              <Trans
                i18nKey="HSH_Upd.HSH_popup.Description"
                components={{
                  b: <b />,
                  a: <a href={NC_WALLET} className={styles.link} target="_blank" rel="noreferrer" />,
                }}
              />
            </p>
          </div>
          <div className={styles.footer}>
            <Button color="blue" fullWidth onClick={() => onClose(false)}>
              <Icon iconName="checked" width="17" height="16" />
              {t('H.Skip_modal.Btn.Confirm')}
            </Button>
          </div>
          {location.pathname === '/' && (
            <div className={styles.dont_show}>
              <input id="modalHSHCheckbox" type="checkbox" onClick={handleDontShow} />
              <label htmlFor={'modalHSHCheckbox'}>{t('DontShowThisWindowAgain')}</label>
            </div>
          )}
        </div>
      </div>
    </ModalLayout>
  );
};
