import styles from './info-block.module.scss';
import { Trans } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { PURCHASE_INFO_BLOCK_SHOWED } from '@/shared/lib/constants/storage-key';

export const InfoContentBlock = ({ text }: { text: string }) => {
  const { currency: userCurrency } = useSelector((store: RootState) => store.user);
  const [close, setClose] = useState(false);
  const handleClose = () => {
    localStorage.setItem(PURCHASE_INFO_BLOCK_SHOWED, 'hide');
    setClose(!close);
  };
  if (close) {
    return null;
  }

  return (
    <div className={styles.root}>
      <Button title="close" variant="text" size="xs" onClick={handleClose} className={styles.close}>
        <Icon className={styles.icon} iconName="close-notification" width="16" height="16" />
      </Button>
      <p className={styles.text}>
        <Trans i18nKey={text} values={{ currency: userCurrency }} components={{ b: <strong /> }} />
      </p>
    </div>
  );
};
