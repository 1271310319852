import React, { useEffect, useState } from 'react';
import s from './google-mining-duration.module.scss';
import { Button } from '@/shared/ui/button';
import { BottomSheetModal } from '@/page/cart/ui/v2/bottom-sheet-modal';
import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { Trans, useTranslation } from 'react-i18next';
import { MiningPoolPlanGoogle } from '@/shared/lib/models';
import { GoogleMiningDurationButton } from '@/page/cart/ui/v2/google-mining-duration/google-mining-duration-button';
import { getGoogleMonthPrice } from '@/page/cart/ui/v2/total-price/total-price.util';
import {
  useGoogleAvailablePurchase,
  useGoogleCurrentPlan,
  useGoogleMiningDurations,
} from '@/page/cart/store/cart-google-store';
import { Icon } from '@/shared/ui/icons';
import cn from 'classnames';

type GoogleMiningDurationsModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSelected: (month: number, productId: string, offerToken: string, basePlanId: string, trial: boolean) => void;
};

export const GoogleMiningDurationsModal = ({ isOpen, onClose, onSelected }: GoogleMiningDurationsModalProps) => {
  const { t } = useTranslation();
  const activeMonth = useSelector((state: RootState) => state.googleCart.miningDuration)!;
  const plans = useSelector((state: RootState) => state.googleCart.googleData?.plans);
  const miningDurationList = useSelector(useGoogleMiningDurations);

  const currentPlan = useSelector(useGoogleCurrentPlan);

  const [selectedMonth, setSelectedMonth] = useState<number>(activeMonth);
  const [selectedPlan, setSelectedPlan] = useState(currentPlan);
  const availablePurchases = useSelector(useGoogleAvailablePurchase);
  const hasTrial = plans?.find((p) => p.hps === currentPlan.hps && p.month === selectedMonth)?.trial;

  useEffect(() => {
    if (isOpen) return;

    setSelectedMonth(activeMonth);
  }, [activeMonth, isOpen]);

  const onMonthSelect = (month: number) => {
    setSelectedPlan(plans?.find((i) => i?.hps === currentPlan?.hps && i?.month === month) ?? currentPlan);
    setSelectedMonth(month);
  };

  const onConfirm = () => {
    const plan = plans!.find((i) => i?.hps === currentPlan?.hps && i?.month === selectedMonth)!;
    onSelected(selectedMonth, plan.productId, plan.offerToken, plan.basePlanId, plan.trial);
    onClose();
  };

  return (
    <BottomSheetModal isOpen={isOpen} onClose={onClose} title={t('N.SelectDuration.Title')}>
      <div className={s.buttonsContainer}>
        {miningDurationList &&
          miningDurationList.map((month, i) => {
            const MONTH_IN_MS = 2629746000;
            const discount = plans && (plans.find((i) => i.month === month) as MiningPoolPlanGoogle).discount;
            const plan = plans && plans?.find((i) => i?.hps === currentPlan?.hps && i?.month === month);
            let expirationDate;
            const isNotAvailable =
              availablePurchases?.some((p) => {
                if (p.productId === plan!.productId) {
                  expirationDate = MONTH_IN_MS * month + p.transactionDate;
                }
                return p.productId === plan!.productId;
              }) ?? true;
            if (!plan) {
              return <></>;
            }

            return (
              <GoogleMiningDurationButton
                key={i}
                month={month}
                discount={discount}
                formattedPrice={getGoogleMonthPrice(plan)}
                isActive={selectedMonth === month}
                isNotAvailable={isNotAvailable}
                expirationDate={expirationDate}
                onClick={() => onMonthSelect(month)}
                isMostProfitable={i === miningDurationList.length - 1}
              />
            );
          })}
      </div>
      {hasTrial && (
        <div className={cn(s.note, s.note__green)}>
          <div className={cn(s.note__icon__container, s.note__icon__container__green)}>
            <Icon iconName="timer" width={20} height={20} className={s.note__icon} />
          </div>
          <span className={cn(s.note__description, s.note__description__green)}>
            <span>{t('Trial.Title')}</span>
            <span className={s.note__description__subtitle}>
              <Trans
                i18nKey={t('Trial.Description')}
                values={{
                  price: getGoogleMonthPrice(selectedPlan),
                  duration: selectedMonth,
                }}
              />
            </span>
          </span>
        </div>
      )}
      <Button
        fullWidth
        color="white-blue"
        variant="filled"
        onClick={() => onConfirm()}
        className={s.confirmButton}
        borderRadius={false}
      >
        <Icon iconName="checked" width={20} height={20} />
        <span>{t('confirm')}</span>
      </Button>
    </BottomSheetModal>
  );
};
