import { createSlice } from '@reduxjs/toolkit';
import { PoolMinerMessage } from '@/shared/lib/backend/JsonRpcApi';

export type TMiner = {
  id: string;
  hps: number;
  end_time: number;
  create_time: number;
  deleted: boolean;
  is_google_subscription: boolean;
  product_id?: string;
};

export type TMining = {
  id: string;
  hps: number;
  end_time: string;
  deleted: boolean;
  is_google_subscription: boolean;
  product_id?: string;
};

export type TPoolMiner = {
  list: TMiner[];
  new_miner: PoolMinerMessage | null;
  miners_list_used_now: TMining[];
  loading: boolean;
  checked: boolean;
};

const initialState: TPoolMiner = {
  list: [],
  new_miner: null,
  miners_list_used_now: [],
  loading: true,
  checked: false,
};

const poolMinerSlice = createSlice({
  name: 'poolMiner',
  initialState,
  reducers: {
    setPoolMinersList(state, action) {
      state.list = action.payload;
    },
    setNewMiner(state, action) {
      state.new_miner = action.payload;
    },
    setPoolUsedNow(state, action) {
      state.miners_list_used_now = action.payload;
      state.checked = action.payload.length > 0;
    },
    updateList(state, action) {
      const index = state.list.findIndex((m) => m.id === action.payload.id);
      if (action.payload.deleted) {
        index >= 0 && state.list.splice(index, 1);
      } else if (index === -1) {
        state.list.push(action.payload);
      } else {
        state.list.splice(index, 1, action.payload);
      }
    },
    updateUsedNow(state, action) {
      const index = state.miners_list_used_now.findIndex((m) => m.id === action.payload.id);
      if (action.payload.deleted) {
        index >= 0 && state.miners_list_used_now.splice(index, 1);
      } else if (index === -1) {
        state.miners_list_used_now.push(action.payload);
      } else {
        state.miners_list_used_now.splice(index, 1, action.payload);
      }
      state.checked = state.miners_list_used_now.length > 0;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setChecked(state, action) {
      state.checked = action.payload;
    },
  },
});

export const poolMiner = {
  setPoolMinersList: poolMinerSlice.actions.setPoolMinersList,
  setNewMiner: poolMinerSlice.actions.setNewMiner,
  setPoolUsedNow: poolMinerSlice.actions.setPoolUsedNow,
  updateList: poolMinerSlice.actions.updateList,
  updateUsedNow: poolMinerSlice.actions.updateUsedNow,
  setLoading: poolMinerSlice.actions.setLoading,
  setChecked: poolMinerSlice.actions.setChecked,
};
export default poolMinerSlice.reducer;
