import { useSelector } from 'react-redux';
import { TotalPrice } from '@/page/cart/ui/v1/total-price';
import { useCurrentPlan, useHashrate, useHashrates } from '@/page/cart/store';
import { Hashrate } from '../hashrate';
import { MiningDuration } from '../mining-duration';
import s from './add-miner-telegram.module.scss';
import { useCurrentPlanPriceInfoTelegram } from '@/page/cart/hooks/use-current-plan-price-info-telegram';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MiningPoolPlan } from '@/shared/lib/models';
import { formatPrice } from '@/page/cart/ui/v1/total-price/total-price.util';
import { formatBalance } from '@/shared/lib/utils/units';
import { RootState } from '@/shared/store/types';
import { useTranslation } from 'react-i18next';

interface AddMinerTelegramProps {
  handleSwitchTab: () => void;
}

export const AddMinerTelegram = ({ handleSwitchTab }: AddMinerTelegramProps) => {
  const currentPlan = useSelector(useCurrentPlan);
  const { cryptoBtc2XTR } = useSelector((store: RootState) => store.rate);
  const { currencies } = useSelector((store: RootState) => store.balance);
  const plans = useSelector((state: RootState) => state.cart.data?.plans);
  const hashrates = useSelector(useHashrates);
  const hashrate = useSelector(useHashrate);
  const [isLastInRange, setIsLastInRange] = useState(false);
  const { t } = useTranslation();
  const { formattedPrice, formattedPriceRegular, fullSavePrice } = useCurrentPlanPriceInfoTelegram();

  const currentCurrencyObj = useMemo(() => currencies?.find((cur) => cur?.symbol === 'XTR'), [currencies]);

  const getFormatedMiningPrice = useCallback(
    (plan: MiningPoolPlan | undefined) => {
      const currentBtcPrice = (plan && formatPrice(parseFloat(plan?.total_price) / plan?.month)) || 0;
      const btc2UserCurrencyPrice = formatPrice(Number(currentBtcPrice) * cryptoBtc2XTR);

      return currentCurrencyObj ? formatBalance(btc2UserCurrencyPrice, currentCurrencyObj?.unit) : '0.00';
    },
    [cryptoBtc2XTR, currentCurrencyObj]
  );

  const maxHashrate = useMemo(() => {
    const planMoreMax = plans?.find((plan) => {
      if (+plan?.total_price * cryptoBtc2XTR > 95000) return plan;
    });
    return hashrates?.indexOf(planMoreMax?.hps || 0) - 1;
  }, [plans, cryptoBtc2XTR, hashrates]);

  useEffect(() => {
    setIsLastInRange(hashrate === hashrates[maxHashrate]);
  }, [hashrate, hashrates, maxHashrate]);

  return (
    <div className={s.container}>
      <div className={s.contentContainer}>
        <div className={s.hashrateContainer}>
          {currentPlan && <Hashrate maxHashrate={maxHashrate} />}
          {isLastInRange && (
            <div className={s.higher} onClick={() => handleSwitchTab()}>
              {t('HigherFasterStronger')}
            </div>
          )}
          <MiningDuration getFormatedMiningPrice={getFormatedMiningPrice} userCurrency={'XTR'} />
        </div>
        {currentPlan && (
          <TotalPrice
            formattedPrice={formattedPrice}
            formattedPriceRegular={formattedPriceRegular}
            userCurrency={'XTR'}
            fullSavePrice={fullSavePrice}
          />
        )}
      </div>
    </div>
  );
};
