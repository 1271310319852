import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { useCurrentPlan } from '@/page/cart/store';
import { formatPrice, getRegularPrice } from '@/page/cart/ui/v2/total-price/total-price.util';
import { formatBalance } from '@/shared/lib/utils/units';

export const useCurrentPlanPriceInfoTelegram = () => {
  const { crypto2usdRate, cryptoBtc2XTR, cryptoBtc2userCurrencyRate, crypto2eurRate } = useSelector(
    (store: RootState) => store.rate
  );
  const { defaultFiatCurrencyApproximately } = useSelector((store: RootState) => store.user);
  const { currencies } = useSelector((store: RootState) => store.balance);
  const currentPlan = useSelector(useCurrentPlan);
  const selectedCryptoRate = defaultFiatCurrencyApproximately?.code === 'EUR' ? crypto2eurRate : crypto2usdRate;

  // Calculate current price in fiat
  const currentPrice = parseFloat(currentPlan?.total_price) || 0;
  const currentPriceFiat = (currentPrice * selectedCryptoRate * cryptoBtc2userCurrencyRate).toFixed(2);

  const currentCurrencyObj = currencies?.find((cur) => cur?.symbol === 'XTR');

  // Format prices based on user's currency
  const btc2XTRPrice = formatPrice(currentPrice * cryptoBtc2XTR);
  const formattedPrice = currentCurrencyObj ? formatBalance(btc2XTRPrice, currentCurrencyObj.unit) : '0.00';

  // Regular price
  const currentRegularPrice = getRegularPrice(currentPlan);
  const formattedPriceRegular = currentCurrencyObj
    ? formatBalance(currentRegularPrice * cryptoBtc2XTR, currentCurrencyObj.unit)
    : '0.00';

  const fullSavePrice = currentCurrencyObj
    ? formatBalance(+formattedPriceRegular - +formattedPrice, currentCurrencyObj.unit)
    : '0.00';

  return {
    currentPriceFiat,
    formattedPrice,
    formattedPriceRegular,
    fullSavePrice,
  };
};
