import cn from 'classnames';

import { formatHashrate } from './hashrate.util';

import s from './hashrate.module.scss';
import { InputRange } from '@/shared/ui/input-range';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { Button } from '@/shared/ui/button';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { setHashrate, useHashrate, useHashrates } from '@/page/cart/store';
import { MegaSaleBanner } from '@/page/cart/ui/v1/mega-sale-banner/mega-sale-banner';
import { useEffect } from 'react';

type HashrateProps = {
  maxHashrate?: number;
};

export const Hashrate = ({ maxHashrate }: HashrateProps) => {
  const { t } = useTranslation();
  const { openModal } = useModalSet();
  const { isTelegramMiniApp } = useDevice();
  const hashrates = useSelector(useHashrates);
  const dispatch: AppDispatch = useDispatch();

  const hashrate = useSelector(useHashrate);
  const formattedHashrate = formatHashrate(hashrate);

  const currentValue = hashrates.indexOf(hashrate);
  const minValue = 0;
  const maxValue = maxHashrate || hashrates.length - 1;
  const userTags = useSelector((state: RootState) => state.user.tags);
  const isMegaSaleShow = userTags?.includes('promo_11_11_24');

  const handleChange = (value: number) => {
    dispatch(setHashrate({ hashrate: hashrates[value] }));
  };

  useEffect(() => {
    if (hashrate > hashrates[maxValue]) dispatch(setHashrate({ hashrate: hashrates[maxValue] }));
  }, [maxValue]);

  return (
    <div className={s.container}>
      <div className={s.top}>
        <div className={s.top__item}>
          <Icon iconName="speed" className={s.top__icon} width={20} height={20} />
          {t('choose hashrate')}
        </div>
        {isTelegramMiniApp ? (
          <div>
            <Button
              className={s.btnInfo}
              variant="text"
              color="blue"
              onClick={() => openModal('MODAL_INFO_POOL', { isServices: true })}
            >
              <Icon iconName="info" width="20" height="20" />
            </Button>
          </div>
        ) : null}
      </div>
      <div className={s.hashrateContainer}>
        <span className={cn(s.hashrateValue)}>{formattedHashrate}</span>
        <div className={s.hashrateRangeContainer}>
          <InputRange value={currentValue} onChange={handleChange} min={minValue} max={maxValue} />
        </div>
      </div>
      {isMegaSaleShow ? <MegaSaleBanner /> : null}
    </div>
  );
};
