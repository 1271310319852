import { PropsWithChildren, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTelegramAccessTokens, logoutToken, rootUser, userAction } from '@/shared/store/user/user.slice';
import useJsonRpc from '@/shared/lib/hooks/useJsonRpc';
import { AppDispatch } from '@/shared/store/types';
import { useTelegram } from '@/shared/lib/hooks/useTelegram';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import useCloudStorage from '@/shared/lib/hooks/useCloudStorage';
import { Language, languages } from '@/shared/lib/constants/languages';
import { getLanguage, isValidCode, setLanguage } from '@/shared/store/language';
import { changeIsTelegramMiniApp } from '@/features/theme/model/slice';
import { clearBalance } from '@/shared/store/balance/balance.slice';
import { rpc } from '@/shared/lib/backend/Rpc';
import { HOME_PATH } from '@/shared/lib/utils/links';
import { useNavigate } from 'react-router-dom';
import { FixedLoader } from '@/shared/ui/loader/loader';
import { initApp, selectRpcInitiated, setRpcAuthed, setRpcInitiated } from '@/shared/store/rpc/rpc.slice';
import { withdrawalAction } from '@/shared/store/withdrawal/withdrawal.slice';
import { getDeviceId } from '@/app/init';
import { setUtmObg, utmObjType } from '@/shared/store/utm/utm.slice';
import { useTutorialStorage } from '@/shared/lib/hooks/useTutorialStorage';
import { I18_NEXT_LNG, TOKENS } from '@/shared/lib/constants/storage-key';

export const EffectAppContainer = ({ children }: PropsWithChildren) => {
  const rpcInited = useSelector(selectRpcInitiated);
  const { isAuthChecked, access_token, refresh_token } = useSelector((store: { user: rootUser }) => store.user);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { tg, initData, initDataUnsafe, startParam, decodedStartParam } = useTelegram();
  const { isReactNativeApp, isTelegramMiniApp, platformCt } = useDevice();
  const { setItem, getItem } = useCloudStorage();
  const navigation = useNavigate();
  const userLanguage = isTelegramMiniApp ? initDataUnsafe?.user?.language_code : undefined;
  const currentLocalLang = localStorage?.getItem(I18_NEXT_LNG);
  const code = getLanguage();
  const { markTutorialSeen } = useTutorialStorage();

  useEffect(() => {
    const handleLogout = async () => {
      await dispatch(logoutToken({ refresh_token: refresh_token || '' }));
      dispatch(clearBalance());
      dispatch(withdrawalAction.clearWithdrawalsToShow());
      dispatch(userAction.setBanned(false));
      await rpc.close();
      dispatch(userAction.setStatus(''));
      dispatch(setRpcInitiated(false));
      dispatch(setRpcAuthed(false));
      dispatch(userAction.unAuth());
      setIsLoading(false);
      navigation(HOME_PATH);
    };

    const handleLoginOrLogout = async () => {
      if (isTelegramMiniApp) {
        if (!rpcInited) {
          markTutorialSeen();
          //for init value in localstorage
          const dsp =
            decodedStartParam && decodedStartParam.startsWith('R:')
              ? decodedStartParam
              : startParam
              ? startParam
              : null;
          await getDeviceId({
            isTelegramMiniApp,
            isReactNativeApp,
            initData,
            setItem,
            getItem,
            appPlatform: platformCt,
            decodedStartParam: dsp,
          });
          await dispatch(
            getTelegramAccessTokens({
              telegramToken: initData,
              telegramUserId: initDataUnsafe?.user?.id.toString() || '',
            })
          );
          await dispatch(
            initApp({ isTelegramMiniApp, isReactNativeApp, initData, setItem, getItem, appPlatform: platformCt })
          );
        } else if (isAuthChecked) {
          const tokens = localStorage.getItem(TOKENS);
          const tokensParsed = tokens ? JSON.parse(tokens) : null;
          if (tokensParsed?.telegramId && initDataUnsafe?.user?.id.toString() !== tokensParsed?.telegramId) {
            setIsLoading(true);
            await handleLogout();
          }
        }
      }
      if (isReactNativeApp) {
        if (!rpcInited) {
          if (access_token || refresh_token) {
            await dispatch(
              initApp({ isReactNativeApp, isTelegramMiniApp, initData, setItem, getItem, appPlatform: platformCt })
            );
          } else {
            dispatch(userAction.setLoading(false));
            dispatch(userAction.checkAuth());
          }
        }
      }
    };
    handleLoginOrLogout().catch(console.error);
  }, [
    dispatch,
    rpcInited,
    isAuthChecked,
    isTelegramMiniApp,
    isReactNativeApp,
    initData,
    initDataUnsafe?.user?.id,
    navigation,
  ]);

  useEffect(() => {
    window.Notification?.requestPermission &&
      Notification?.requestPermission()?.then((status) => {
        dispatch(userAction.setNotificationStatus(status));
      });
  }, [dispatch]);

  // Resize to full Mini App window on open
  useEffect(() => {
    if (isTelegramMiniApp) {
      tg?.expand();
    }
  }, [isTelegramMiniApp, tg]);

  // Effect to check if the app is running inside Telegram and update Redux state
  useEffect(() => {
    dispatch(changeIsTelegramMiniApp(isTelegramMiniApp));
  }, [isTelegramMiniApp, dispatch]);

  const [langObj, setLangObj] = useState<Language>(() => {
    if (isTelegramMiniApp && isValidCode(userLanguage)) {
      return languages[userLanguage];
    }
    return languages[code] ?? languages.en;
  });

  useEffect(() => {
    if (isTelegramMiniApp && isValidCode(userLanguage) && !currentLocalLang) {
      setLangObj(languages[userLanguage]);
    } else if (isValidCode(currentLocalLang)) {
      setLangObj(languages[currentLocalLang]);
    } else {
      setLangObj(languages[code] ?? languages.en);
    }
  }, [code, userLanguage, isTelegramMiniApp, currentLocalLang]);

  useEffect(() => {
    setLanguage(langObj?.code || 'en');
  }, [langObj]);

  useJsonRpc();

  useEffect(() => {
    const utm_obj: utmObjType = {};
    const search = new URLSearchParams(window.location.search);
    search.forEach((value, key) => {
      if (key.startsWith('utm_')) {
        utm_obj[key] = value;
      }
    });
    if (decodedStartParam) {
      utm_obj['ref'] = decodedStartParam;
    }
    if (Object.keys(utm_obj).length > 0) {
      dispatch(setUtmObg(utm_obj));
    }
  }, [dispatch]);

  if (isLoading) {
    return <FixedLoader />;
  }

  return children;
};
