import type { ButtonHTMLAttributes, PropsWithChildren, Ref } from 'react';
import React, { forwardRef, memo } from 'react';
import cn from 'classnames';
import styles from './button.module.scss';
import { ButtonCommonProps } from './button.model';
import { LoadSpinner } from '@/shared/ui/loader/loader';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & PropsWithChildren<ButtonCommonProps>;

const ButtonComponent = forwardRef((props: ButtonProps, ref?: Ref<HTMLButtonElement>) => {
  const {
    children,
    color = 'black',
    size = 's',
    variant = 'filled',
    disabled = false,
    className,
    fullWidth,
    wide,
    loading,
    justify = 'center',
    as: Component = 'button',
    borderRadius = false,
    spinnerTheme = 'white',
    to,
    ...rest
  } = props;

  return (
    <Component
      className={cn(
        styles.button,
        styles[variant],
        styles[color],
        styles[size],
        styles[justify],
        {
          [styles.wide]: wide,
          [styles.fullWidth]: fullWidth,
          [styles.rounded]: borderRadius,
          [styles.disabled]: disabled,
          [styles.loading]: loading,
        },
        className
      )}
      disabled={disabled}
      ref={ref}
      to={to}
      {...rest}
    >
      {loading ? <LoadSpinner color={spinnerTheme} /> : children}
    </Component>
  );
});

export const Button = memo(ButtonComponent);
