import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@/shared/store/types';
import styles from './modal-email-verify/modal-email-verify.module.scss';
import { Button } from '@/shared/ui/button';
import { confirmEmail } from '@/shared/store/withdrawal/withdrawal.slice';
import ModalLayout from '@/shared/ui/modal/modal';
import { useEffect, useRef, useState } from 'react';
import { selectCurrentTheme } from '@/features/theme';
import cn from 'classnames';
import { LoadSpinner } from '@/shared/ui/loader/loader';
import { Slide, ToastContainer } from 'react-toastify';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import { useModalVerify } from '@/shared/lib/hooks/useModalVerify';

type ModalEmailCheckType = {
  onClose: (e: boolean) => void;
  isOpen: boolean;
};

export const ModalEmailCheck = ({ onClose, isOpen }: ModalEmailCheckType) => {
  const theme = useSelector(selectCurrentTheme);
  const { Header, Title, ContentMobile } = ModalLayout;
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const toastsContainer = useRef(null);
  const { countdown, newCountdown, toastId, newToastId, value } = useModalVerify();

  const handleClick = () => {
    window.open('https://mail.google.com/', '_blank');
  };

  const handleClickResend = () => {
    setLoading(true);
    dispatch(confirmEmail({ email: value, t, newToastId, toastId, newCountdown }))
      .then(() => {
        toaster.success(t('Successfully resent'), { toastProps: { containerId: 'modalsContainer2' } });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!toastsContainer.current) return;
    toaster.success(t('Successfully sent'), { toastProps: { containerId: 'modalsContainer2' } });
  }, [toastsContainer.current]);

  return (
    <ModalLayout background onClose={() => onClose(false)} isOpen={isOpen} isNavigation={true} id="mail-verify">
      <Header>
        <Title size="xs">{t('Mail_conf.Main_title')}</Title>
      </Header>
      <ContentMobile className={styles.contentModal}>
        <div className={styles.root}>
          <div className={styles.block}>
            <Icon
              iconName={theme === 'light' ? 'email-verify-connect-done' : 'email-verify-connect-done-dark'}
              width={80}
              height={80}
            />
            <h6 className={styles.title}>{<Trans i18nKey={t('Check your Email')} components={{ b: <strong /> }} />}</h6>
            <p className={styles.text}>
              <Trans i18nKey={t('Verify.text')} values={{ email: value }} components={{ b: <strong /> }} />
            </p>

            <div className={styles.resend}>
              <div className={styles.resend__text}>
                <Trans i18nKey={t('Mail_conf.Check.Spam_mb')} components={{ b: <strong /> }} />
              </div>
              <button
                className={cn(styles.resend__button, {
                  [styles.loading]: loading,
                  [styles.disabled]: countdown,
                })}
                onClick={() => {
                  handleClickResend();
                }}
                disabled={loading || countdown !== null}
              >
                {loading ? (
                  <LoadSpinner color={'white'} className={styles.loader} />
                ) : (
                  <>
                    <Icon iconName={'refresh'} width={20} height={20} />
                    {t('Mail_conf.Check.Resend')} {countdown && `(${countdown % 61})`}
                  </>
                )}
              </button>
            </div>
          </div>
          <div className={styles.footer}>
            <Button color="blue" fullWidth onClick={handleClick}>
              <Icon iconName="email" width="24" height="24" />
              {t('MAILBOX')}
            </Button>
          </div>
          <ToastContainer
            ref={toastsContainer}
            enableMultiContainer={true}
            closeButton={false}
            hideProgressBar
            closeOnClick={true}
            pauseOnHover={false}
            position="top-right"
            transition={Slide}
            containerId="modalsContainer2"
          />
        </div>
      </ContentMobile>
    </ModalLayout>
  );
};
