import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { poolMiner } from '@/shared/store/pool-miner/pool-miner.slice';
import { rpc } from '@/shared/lib/backend/Rpc';
import { Mining } from '@/page/home/ui/mining-block/ui/mining';
import { nftMiner } from '@/shared/store/ntf-miner/nft-miner.slice';
import { Icon } from '@/shared/ui/icons';
import cn from 'classnames';
import { refreshBalanceThunk } from '@/shared/store/balance/balance.slice';
import { LimitAlertModal } from '@/page/home/ui/mining-block/ui/limit-alert/limit-alert.modal';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { shouldShowContent } from '@/shared/lib/utils/last-show-date';
import { selectTutorial, startTutorial } from '@/shared/store/tutorial/tutorial.slice';
import styles from './mining-block.module.scss';
import dayjs from 'dayjs';
import { useTutorialStorage } from '@/shared/lib/hooks/useTutorialStorage';
import {
  MINING_START_TIMESTAMP,
  OFFER_ACCEPTED_BY_USER,
  RATING_TIMER_ID,
  STORAGE_TUTORIAL_ONBOARDING,
} from '@/shared/lib/constants/storage-key';
import { AppDispatch, RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';

const RATE_CHECK_KEY = 'rateCheckTimestamp';

type MiningBlockContextType = React.RefObject<HTMLDivElement> | null;

const MiningBlockContext = createContext<MiningBlockContextType>(null);

export const MiningBlockProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const miningBlockRef = useRef<HTMLDivElement>(null);

  return (
    <MiningBlockContext.Provider value={miningBlockRef}>
      <div className="container" ref={miningBlockRef}>
        {children}
      </div>
    </MiningBlockContext.Provider>
  );
};

export const useMiningBlock = () => {
  const context = useContext(MiningBlockContext);
  if (!context) {
    throw new Error('useMiningBlockRef must be used within a MiningBlockProvider');
  }
  return context;
};

export function MiningBlock() {
  const [isAlertOpen, setAlertOpen] = useState(false);
  const { step } = useSelector(selectTutorial);
  const miners = useSelector((store: RootState) => store.pool_miner.list);
  const mining = useSelector((store: RootState) => store.pool_miner.miners_list_used_now);
  const checked = useSelector((store: RootState) => store.pool_miner.checked);
  const nftMining = useSelector((store: RootState) => store.nft_miner.mining);
  const limitAlertShowed = useSelector((store: RootState) => store.nft_miner.limitAlertShowed);
  const [hps, setHps] = useState(0);
  const dispatch: AppDispatch = useDispatch();
  const { openModal } = useModalSet();
  const { isOnboardingSeen } = useTutorialStorage();
  const { id } = useSelector(selectUser);

  useEffect(() => {
    if (!isOnboardingSeen()) {
      dispatch(startTutorial(STORAGE_TUTORIAL_ONBOARDING));
    }
  }, [isOnboardingSeen]);

  const startMiningSession = useCallback(async () => {
    if (mining.length) {
      await rpc.transmit('mining.pool.stop', {}).then(() => dispatch(poolMiner.setPoolUsedNow([])));
      localStorage.removeItem(MINING_START_TIMESTAMP);
      return;
    }
    await rpc.transmit('mining.pool.start', {}).then((r) => dispatch(poolMiner.setPoolUsedNow(r)));
    const timestamp = Date.now();
    localStorage.setItem(MINING_START_TIMESTAMP, timestamp.toString());

    const timerId = setTimeout(
      async () => {
        try {
          const response = await rpc.transmit('rate.info', {});
          if (response?.allow) {
            openModal('MODAL_RATE_US');
          } else {
            const nextCheckTime = dayjs().add(86400, 'second').valueOf();
            localStorage.setItem(RATE_CHECK_KEY, nextCheckTime.toString());
          }
        } catch (e) {
          console.error('Failed to check rating permission', e);
        }
      },
      10 * 60 * 1000
    ); // 10 minutes

    localStorage.setItem(RATING_TIMER_ID, JSON.stringify(timerId));
  }, [dispatch, openModal, mining]);

  const onCloseAlert = useCallback(() => {
    setAlertOpen(false);
    if (!limitAlertShowed) {
      dispatch(nftMiner.setLimitAlertShowed());
    }
  }, [limitAlertShowed, dispatch]);

  const isOfferAcceptedWithinInterval = useCallback((): boolean => {
    const storedTimestamp = localStorage.getItem(`${OFFER_ACCEPTED_BY_USER}${id}`);

    if (!storedTimestamp) {
      return false;
    }

    const savedTime = new Date(storedTimestamp).getTime();
    const currentTime = Date.now();
    const checkInterval = 1800000;

    return currentTime - savedTime <= checkInterval;
  }, [id]);

  useEffect(() => {
    if (mining.length > 0) {
      const miningHps = mining.reduce((acc, cur) => cur?.hps + acc, 0);

      const interval = setInterval(() => {
        // Generate a random number between -500 and +500
        const change = Math.random() * 1000 - 500;
        setHps(miningHps + change);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      const minersHps = miners.reduce((acc, cur) => cur?.hps + acc, 0);
      setHps(minersHps);
    }
  }, [miners, mining]);

  useEffect(() => {
    if (mining.length || nftMining.length) {
      const interval = setInterval(() => {
        dispatch(refreshBalanceThunk());
      }, 600 * 1000);
      return () => clearInterval(interval);
    }
  }, [dispatch, mining, nftMining]);

  useEffect(() => {
    const havePurchasedMiner =
      miners.length >= 1 && miners.some(({ product_id, deleted }) => !deleted && !product_id?.includes('promo'));
    const checkOfferInterval = setInterval(() => {
      if (!isOfferAcceptedWithinInterval() && !havePurchasedMiner && shouldShowContent() && step === -1) {
        openModal('MODAL_PROMO_ADD_POOL_MINER');
        clearInterval(checkOfferInterval);
      }
    }, 5000);

    return () => clearInterval(checkOfferInterval);
  }, [openModal, miners, step, isOfferAcceptedWithinInterval]);

  return (
    <MiningBlockProvider>
      <div className={cn(styles.miningBlock)}>
        <Mining
          className={styles.pool}
          mining={mining}
          hps={hps}
          texts={{
            addMiner: 'ADD POOL MINER',
            addMinerMobile: 'ADD MINER',
            turnOnMining: 'titleOnButton',
            activeStatus: 'ACTIVE_Status',
            noMiners: 'You have no Pool Miners',
            expirationDate: 'Expiration date',
            miners: 'Pool Miners',
            mining: 'Pool Mining',
          }}
          checked={checked}
          onStartMiner={startMiningSession}
          icon={<Icon iconName="miningSpeed" width={40} height={40} />}
          miners={miners}
        />
      </div>
      {isAlertOpen && <LimitAlertModal isOpen={isAlertOpen} onClose={onCloseAlert} />}
    </MiningBlockProvider>
  );
}
