import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import ModalLayout from '@/shared/ui/modal/modal';
import styles from './modal-increasing-hsh.module.scss';
import { useCallback, useEffect, useState } from 'react';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { NC_WALLET } from '@/shared/lib/utils/links';
import hshDecoration from './images/bg_hc_mob_img-min.png';

export const BALANCE_HSH = 'BALANCE_HSH';

export const ModalIncreasingHsh = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const [lastBalance, setLastBalance] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const localDate = localStorage.getItem(BALANCE_HSH) || '{}';
    const lastBalance = JSON.parse(localDate);

    if (+lastBalance.lastBalance) {
      setLastBalance(+lastBalance.lastBalance);
    }
  }, []);

  const handleDontShow = useCallback(() => {
    const localDate = localStorage.getItem(BALANCE_HSH) || '{}';
    const lastBalance = JSON.parse(localDate);
    const visit = { ...lastBalance, isShow: !lastBalance.isShow };
    localStorage.setItem(BALANCE_HSH, JSON.stringify(visit));
  }, []);

  return (
    <ModalLayout
      className={styles.modal}
      iconClose
      onClose={() => onClose(false)}
      isOpen={isOpen}
      id="ModalIncreasingHsh"
    >
      <div className={styles.root} onClick={() => onClose(false)}>
        <div className={styles.decoration}>
          <img src={hshDecoration} alt="img-hsh" />
        </div>
        <div className={styles.container} onClick={(e) => e.stopPropagation()}>
          <div className={styles.block}>
            <span className={styles.close} onClick={() => onClose(false)}>
              <Icon iconName="close" width="20" height="20" />
            </span>
            <Icon iconName={'hsh'} className={styles.icon} width="80" height="81" />
            <h6 className={styles.title}>
              {
                <Trans
                  i18nKey="BB_Hsh.Topup.popup.Title"
                  components={{ b: <b className={styles.green} /> }}
                  values={{ HSH: getLocalAmount(lastBalance) }}
                />
              }
            </h6>
            <p className={styles.text}>
              <Trans
                i18nKey="BB_Hsh.Topup.popup.Description"
                components={{
                  span: <span className={styles.green} />,
                  b: <b />,
                  a: <a href={NC_WALLET} className={styles.link} target="_blank" rel="noreferrer" />,
                }}
                values={{ HSH: getLocalAmount(lastBalance) }}
              />
            </p>
          </div>
          <div className={styles.footer}>
            <Button color="blue" fullWidth onClick={() => onClose(false)}>
              <Icon iconName="checked" width="17" height="16" />
              {t('H.Skip_modal.Btn.Confirm')}
            </Button>
          </div>
          <div className={styles.dont_show}>
            <input id="modalHSHCheckbox" type="checkbox" onClick={handleDontShow} />
            <label htmlFor={'modalHSHCheckbox'}>{t('DontShowThisWindowAgain')}</label>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
