import { Trans, useTranslation } from 'react-i18next';
import s from './subscription-total-price-ios.module.scss';
import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';
import { Discount } from '@/page/cart/ui/v1/total-price/discount';
import { useGoogleCurrentPlan, useGoogleHashrate } from '@/page/cart/store/cart-google-store';
import { BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import { formatHashrate } from '@/page/cart/ui/v1/hashrate';
import { useCallback, useEffect, useState } from 'react';
import { ReceiptInfo } from '@/shared/lib/backend/JsonRpcApi';
import cn from 'classnames';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { Typography } from '@/shared/ui/typography';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

const PLAN_TAG = 'bonus';

export const SubscriptionTotalPriceIos = () => {
  const miningDuration = useSelector((state: RootState) => state.googleCart.miningDuration);
  const hashrate = useSelector(useGoogleHashrate);
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const lastReceiptIOS = useSelector((state: RootState) => state.googleCart.lastReceiptIOS);
  const plans = useSelector((state: RootState) => state.googleCart.googleData?.plans);
  const [changeSubscription, setChangeSubscription] = useState<boolean>(false);
  const [subscriptionsIos, setSubscriptionsIos] = useState<ReceiptInfo[]>([]);
  const { openModal } = useModalSet();
  const { t } = useTranslation();

  useEffect(() => {
    if (lastReceiptIOS) {
      setSubscriptionsIos(lastReceiptIOS.latest_receipt_info);
    }
  }, [lastReceiptIOS]);

  useEffect(() => {
    if (subscriptionsIos?.length) {
      const group = plans && plans.filter((i) => i?.hps === currentPlan?.hps);
      const change =
        group?.reduce((v, plan) => {
          return v || !!subscriptionsIos.find((i) => i?.product_id === plan?.productId);
        }, false) || false;
      setChangeSubscription(change);
    }
  }, [currentPlan, plans, subscriptionsIos]);

  const openHshModal = useCallback(() => {
    openModal('MODAl_INFO_BONUS_HSH', { isServices: true });
  }, []);

  return (
    <div className={s.container}>
      {changeSubscription ? (
        <>
          <div className={s.columnPrice}>
            <div className={s.column}>
              <div className={s.currentMiner}>
                Pool Miner {hashrate && formatHashrate(hashrate)} ({miningDuration} {t('mo.')})
              </div>
            </div>
          </div>
          <div className={s.flexSpace}>
            <div className={s.left}>
              <Icon className={s.green} iconName="change" width={20} height={20} />
              <span className={s.discounts}>{t('Subscribe')}</span>
            </div>
            <p className={s.fullPrice}>
              {currentPlan?.total_price} / {currentPlan?.month} {t('Months_abrv.Subtitle')}
            </p>
          </div>
        </>
      ) : (
        <>
          <div className={s.columnPrice}>
            <div className={s.row}>
              <Typography variant="h6" weight="bold" className={s.row__typography}>
                <Icon iconName="cartSell" height={20} width={20} />
                {t('OrdDetails.Title')}
              </Typography>
              <div className={s.column}>
                <div className={s.currentMiner}>
                  Pool Miner {hashrate && formatHashrate(hashrate)} ({miningDuration} {t('mo.')})
                </div>
                <div className={s.priceContainer}>
                  {`${currentPlan && getLocalAmount(currentPlan?.total_price, currentPlan?.currentCurrency)}` || '0'}
                </div>
              </div>
            </div>
          </div>
          <div className={s.totalPriceRows}>
            <div className={s.flexSpace}>
              <div className={s.left}>
                <Icon iconName="label-price" width={20} height={20} />
                <span className={s.discounts}>{t('Purchase_scr.Reg_price.Title')}</span>{' '}
              </div>
              <p className={s.oldPrice}>{`${
                currentPlan && getLocalAmount(currentPlan?.old_price, currentPlan?.currentCurrency)
              }`}</p>
            </div>
            {currentPlan && currentPlan?.discount > 0 && (
              <div className={s.flexSpace}>
                <div className={s.left}>
                  <Icon iconName="percent" width={20} height={20} className={s.green} />
                  <span className={s.discounts}>{t('N.YourDiscount.Title')}</span>
                </div>
                <Discount discount={currentPlan?.discount} promoDiscount={currentPlan?.promo_discount} />
              </div>
            )}
            {currentPlan && currentPlan?.discount > 0 && (
              <div className={s.flexSpace}>
                <div className={s.left}>
                  <Icon iconName="check-blue" width={20} height={20} />
                  <span className={s.discounts}>{t('Purchase_scr.Total_Discount.Title')}</span>
                </div>
                <p className={s.fullPrice}>{`${
                  currentPlan && getLocalAmount(currentPlan?.save_price, currentPlan?.currentCurrency)
                } `}</p>
              </div>
            )}
            {!currentPlan.hsh_reward && currentPlan?.hps_bonus && currentPlan?.tags?.includes(BONUS_TAG_20) && (
              <div className={s.flexSpace}>
                <div className={s.left}>
                  <Icon iconName="purple-gift-x2" width={20} height={20} />
                  <div className={s.bonus__title}>
                    <Trans
                      i18nKey="BonusHashx2.Short_description"
                      components={{ b: <span className={s.presentsPurple} /> }}
                    />
                  </div>
                </div>
                <Discount promoDiscount={0} discount={0} double={currentPlan?.hps_bonus} />
              </div>
            )}
            {!currentPlan.hsh_reward && currentPlan?.hps_bonus && currentPlan?.tags?.includes(PLAN_TAG) && (
              <div className={s.flexSpace}>
                <div className={s.left}>
                  <Icon iconName="blue-gift" width={20} height={20} />
                  <span className={s.bonus__title}>{t('hps_increment')}:</span>
                </div>
                <Discount promoDiscount={0} discount={0} bonus={currentPlan?.hps_bonus} />
              </div>
            )}
            {currentPlan.hsh_reward && (
              <div className={s.bonuses}>
                <div className={s.bonus}>
                  <Icon iconName="hsh" width={20} height={20} />
                  <div>
                    <span className={cn(s.bonus__title, s[`bonus__title--hsh`])} onClick={openHshModal}>
                      {t('HashCoins')}
                    </span>
                    {currentPlan?.tags?.includes(BONUS_TAG_20) && <span className={s.bonus__double}> (x2)</span>}
                  </div>
                  <Discount promoDiscount={0} discount={0} bonusHSH={currentPlan?.hsh_reward} />
                  <Icon
                    iconName={'question'}
                    width={20}
                    height={20}
                    className={s.bonus__title_question}
                    onClick={openHshModal}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
