import ncwalletIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet.svg';
import ncwalletWhiteIcon from '@/shared/ui/assets/unsupport-icons/nc-wallet-white.svg';

export type ncwIconProps = {
  width: number;
  height: number;
  className?: string;
  isDark?: boolean;
};

export const NcwIcon = ({ width, height, className, isDark }: ncwIconProps) => {
  return (
    <img
      src={isDark ? ncwalletIcon : ncwalletWhiteIcon}
      alt="icon"
      width={width}
      height={height}
      className={className}
    />
  );
};
