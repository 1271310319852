import { Route, Routes, useLocation } from 'react-router-dom';
import { NotFound } from '@/page/not-found/not-found';
import {
  BONUS_PROGRAM,
  CART_PATH,
  CRYPTO_TAB_PRODUCTS_PATH,
  CRYPTO_TAB_REVIEW,
  ERROR_CONNECTION,
  HISTORY_PATH,
  HOME_PATH,
  LOGIN_PATH,
  MENU_PATH,
  MODAL_DEBUG,
  NC_WALLET_CONNECT,
  POOL_MINING,
  SWITCH_BALANCE_PATH,
  TUTORIAL_PATH,
  WITHDRAW_PATH,
} from '@/shared/lib/utils/links';
import { Home } from '@/page/home/home';
import { AddPoolMinerPage } from '@/page/cart';
import { WithdrawPage } from '@/page/withdraw';
import { MenuPage } from '@/page/menu';
import { Layout } from '@/shared/ui/layout/layout';
import { LoginPage } from '@/page/login';
import { Navigation } from '@/shared/ui/navigation/navigation';
import { TutorialPage } from '@/page/tutorial';
import { ProtectedRoute } from '@/app/protected-route';
import { HistoryPage } from '@/page/history';
import { WithdrawalDetailsPage } from '@/page/withdrawal-details';
import { useTranslation } from 'react-i18next';
import { SwitchBalancePage } from '@/page/switch-balance';
import { NcwalletConnectPage } from '@/page/ncwallet-connect';
import ErrorConnection from '@/shared/ui/error-connection/error-connection';
import { CryptoTabProductsPage } from '@/page/crypto-tab-products/ui/crypto-tab-products-page';
import { PoolMiningPage } from '@/page/pool-mining/pool-mining-page';
import { useSelector } from 'react-redux';
import { selectUser } from '@/shared/store/user/user.slice';
import { ModalAccountBlocked } from '@/shared/ui/modal-account-blocked/modal-account-blocked';
import { Review } from '@/page/review/ui/review';
import { BonusProgramPage } from '@/page/bonus-program/ui/crypto-tab-products-page';
import { ModalDebug } from '@/page/modal-debug/modal-debug';

export const AppRouter = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { banned } = useSelector(selectUser);

  return (
    <>
      {banned && <ModalAccountBlocked isOpen={banned} />}
      <Routes key={location.pathname} location={location}>
        <Route>
          <Route
            path="*"
            element={
              <Layout navigation={<Navigation />}>
                <NotFound />
              </Layout>
            }
          />
          <Route
            path={HOME_PATH}
            element={
              <ProtectedRoute>
                <Layout navigation={<Navigation />}>
                  <Home />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={POOL_MINING}
            element={
              <ProtectedRoute>
                <Layout navigation={<Navigation />} title={t('Pool Mining')}>
                  <PoolMiningPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={CART_PATH}
            element={
              <ProtectedRoute>
                <Layout navigation={<Navigation />}>
                  <AddPoolMinerPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={`${WITHDRAW_PATH}/:MiningOrBonus?`}
            element={
              <ProtectedRoute>
                <Layout navigation={<Navigation />}>
                  <WithdrawPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={CRYPTO_TAB_PRODUCTS_PATH}
            element={
              <ProtectedRoute>
                <Layout navigation={<Navigation />} title={t('Products.General_title')}>
                  <CryptoTabProductsPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={BONUS_PROGRAM}
            element={
              <ProtectedRoute>
                <Layout navigation={<Navigation />} title={t('BB_Hsh.BonusProg.Title')}>
                  <BonusProgramPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={CRYPTO_TAB_REVIEW}
            element={
              <ProtectedRoute>
                <Layout navigation={<Navigation />} title={t('Select_Reviews.Btn')}>
                  <Review />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={MENU_PATH}
            element={
              <ProtectedRoute>
                <Layout navigation={<Navigation />}>
                  <MenuPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={HISTORY_PATH}
            element={
              <ProtectedRoute>
                <Layout navigation={<Navigation />} title={t('Payment History')}>
                  <HistoryPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={`${HISTORY_PATH}/:id`}
            element={
              <ProtectedRoute>
                <Layout title={t('TransactionDetails')} navigation={<Navigation />}>
                  <WithdrawalDetailsPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={SWITCH_BALANCE_PATH}
            element={
              <ProtectedRoute>
                <Layout title={t('Balance_currency.Main_title')} navigation={<Navigation />}>
                  <SwitchBalancePage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={NC_WALLET_CONNECT}
            element={
              <ProtectedRoute>
                <Layout title={t('Withdrawal.Connect_NCW.Pop_up.TItle')} navigation={<Navigation />}>
                  <NcwalletConnectPage />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={ERROR_CONNECTION}
            element={
              <ProtectedRoute>
                <Layout navigation={<Navigation />}>
                  <ErrorConnection />
                </Layout>
              </ProtectedRoute>
            }
          />
          <Route
            path={MODAL_DEBUG}
            element={
              <ProtectedRoute>
                <Layout navigation={<Navigation />}>
                  <ModalDebug />
                </Layout>
              </ProtectedRoute>
            }
          />
        </Route>
        <Route
          path={LOGIN_PATH}
          element={
            <Layout>
              <LoginPage />
            </Layout>
          }
        />
        <Route path={TUTORIAL_PATH} element={<TutorialPage />} />
      </Routes>
    </>
  );
};
