export function getMonths(startDate: number, endDate: number): number {
  const start = new Date(startDate * 1000);
  const end = new Date(endDate * 1000);

  let monthsDiff = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());

  const startOfCurrentMonth = new Date(start);
  startOfCurrentMonth.setMonth(start.getMonth() + monthsDiff);

  const daysDifference = (end.getTime() - startOfCurrentMonth.getTime()) / (1000 * 60 * 60 * 24);

  if (daysDifference >= 5) {
    monthsDiff += 1;
  }

  return monthsDiff;
}
