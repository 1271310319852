import { CreateWithdraw } from '@/page/withdraw/ui/create-withdraw/create-withdraw';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { selectUser } from '@/shared/store/user/user.slice';
import { useEffect } from 'react';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { BONUS, MINING, SwitchWithdrawalTabs } from '@/page/withdraw/ui/switch-withdrawal-tabs/switch-withdrawal-tabs';
import { useNavigate, useParams } from 'react-router-dom';
import { setIsMining } from '@/shared/store/balance/balance.slice';
import { WITHDRAW_PATH } from '@/shared/lib/utils/links';
import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';

export const WithdrawPage = () => {
  const balance = useSelector((store: RootState) => store.balance);
  const { tags } = useSelector(selectUser);
  const { currentCurrencyObj, hsh_currency, formattedBalance, userCurrency } = useBalanceInfo();

  const dispatch = useDispatch();
  const { openModal } = useModalSet();
  const { MiningOrBonus } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!tags.includes('withdraw_suspend') || tags.includes('withdraw_access')) return;

    openModal('MODAL_WITHDRAW_SUSPENDED', { isServices: true });
  }, [tags]);

  useEffect(() => {
    if (MiningOrBonus) {
      dispatch(setIsMining(MiningOrBonus === MINING));
    } else {
      navigate(`${WITHDRAW_PATH}/${balance.is_mining ? MINING : BONUS}`);
    }
  }, [MiningOrBonus]);

  return (
    <div>
      <SwitchWithdrawalTabs isMining={balance.is_mining} />
      <div>
        <CreateWithdraw
          isMining={balance.is_mining}
          currentCurrencyObj={currentCurrencyObj}
          hsh_currency={hsh_currency}
          formattedBalance={formattedBalance}
          userCurrency={userCurrency}
        />
      </div>
    </div>
  );
};
