export const HOME_PATH = '/';
export const POOL_MINING = '/pool-mining';
export const CART_PATH = '/cart';
export const WITHDRAW_PATH = '/withdraw';
export const MENU_PATH = '/menu';
export const LOGIN_PATH = '/login';
export const ERROR_CONNECTION = '/connection';
export const MODAL_DEBUG = '/modal-debug';
export const HISTORY_PATH = '/history';
export const TUTORIAL_PATH = '/tutorial';
export const SWITCH_BALANCE_PATH = '/switch-balance';
export const NC_WALLET_CONNECT = '/ncw-connect';
export const CRYPTO_TAB_PRODUCTS_PATH = '/crypto-tab-products';
export const CRYPTO_TAB_REVIEW = '/crypto-tab-review';
export const BONUS_PROGRAM = '/bonus-program';

export const GOOGLE_SUBSCRIPTIONS = 'https://play.google.com/store/account/subscriptions';
export const APPLE_SUBSCRIPTIONS = 'https://apps.apple.com/account/subscriptions';
export const BROWSER_PROMO = '/pool/promo';
export const FARM_PROMO = '/farm/promo';
export const POOL_PROMO = '/pool/promo';

export const POOL_FEEDBACK = 'https://app.ctpool.net/support';
export const SUPPORT_SUSPENDED = 'https://cryptobrowser.site/app/suspend';
export const BROWSER_BANNED = 'https://cryptobrowser.site/app/banned';
export const BROWSER_SETTINGS = 'https://cryptobrowser.site/app/settings';
export const BROWSER_JOURNAL = 'https://cryptobrowser.site/journal/';
export const POOL_TERMS = 'https://ctpool.net/terms/';
export const POOL_PRIVACY_POLICY = 'https://ctpool.net/privacy/';
export const BROWSER_NEWS = '/news/';

export const CT_FARM = 'https://cryptotab.farm/';
export const CT_STORE = 'https://market.ctnft.net/';
export const CT_NFT = 'https://cryptotabnft.com/';
export const CT_VPN = 'https://ct-vpn.com/en/landing_full/';
export const CT_CLICK = 'https://cb.click';
export const CT_START = 'https://start.cryptobrowser.site/';
export const CT_TAGS = 'https://ctags.app/?utm_source=ct-pool&utm_medium=site&utm_campaign=ctags-nov23';
export const CT_ANDROID_MAX =
  'https://play.app.goo.gl/?link=https%3A//play.google.com/store/apps/details%3Fid%3Dmax.cryptotab.android%26referrer%3Dutm_source%253Dlanding%2526utm_medium%253Dfooter%2526utm_content%253De85b0ff3-1392-4ee0-b56a-61a824984ba3';
export const CT_ANDROID_PRO =
  'https://play.app.goo.gl/?link=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dpro.cryptotab.android%26referrer%3Dutm_source%253Dlanding%2526utm_medium%253Dfooter%2526utm_content%253De85b0ff3-1392-4ee0-b56a-61a824984ba3';
export const CT_ANDROID_LITE =
  'https://play.app.goo.gl/?link=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dlite.cryptotab.android%26referrer%3Dutm_source%253Daffiliate%2526utm_medium%253Dfooter%2526utm_content%253De85b0ff3-1392-4ee0-b56a-61a824984ba3';

export const PLAY_GOOGLE_NC_WALLET =
  'https://play.google.com/store/apps/details?id=com.ncwallet&refferrer=utm_source%3Dpool_mob';
export const PLAY_APPLE_NC_WALLET = 'https://apps.apple.com/app/ncwallet/id1615381976?utm_source%3Dpool_mob';

export const POOL_LANDING = 'https://ctpool.net/';

export const CONNECT = '/connect/';

export const AGE_EGGS_LANDING = '/landing/age-of-eggs/#features';

export const MARKET_AGE_OF_EGGS_III = 'https://market.ctnft.net/age-of-eggs-iii/';
export const OPENSEA_AGE_OF_EGGS_III = 'https://opensea.io/collection/the-legend-of-cryptotab-age-of-eggs-iii';
export const RARIBLE_AGE_OF_EGGS_III = 'https://rarible.com/the-legend-of-cryptotab-age-of-eggs-iii/items';

export const TRANSACTION_DETAILS = 'https://polygonscan.com/tx';

export const NC_WALLET = 'https://ncwallet.net/try';
export const LINK_TO_FEE = 'https://jochen-hoenicke.de/queue/#BTC%20(default%20mempool),2w,fee';
export const RATING_GOOGLE_STORE = 'https://play.google.com/store/apps/details?id=net.ctpool';
export const RATING_APPLE_STORE = 'https://apps.apple.com/app/id6503949346?action=write-review';
export const CRYPTO_COMPANY_GOOGLE_RATE = 'https://g.page/r/CUrO2uwA_dmiEAE/review';
export const POOL_REF_LINK_BASE = 'https://ctpool.net/';
export const PAGE_REF_LINK_BASE = 'https://cryptotabbrowser.com/try-products/';
