import { AppDispatch, RootState } from '@/shared/store/types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import ModalLayout from '@/shared/ui/modal/modal';
import { Currencies } from '@/shared/lib/models';
import { rpc } from '@/shared/lib/backend/Rpc';
import { isEffectivelyZero } from '@/shared/lib/utils/isEffectivelyZero';
import dayjs from 'dayjs';
import { setHistory, setRate, setRateBtcToCurrentUserCurrency } from '@/shared/store/rate/rate.slice';
import { userAction } from '@/shared/store/user/user.slice';
import { toast } from 'react-toastify';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import styles from '@/page/tutorial/ui/slider-currency/slider-currency.module.scss';
import { CurrencyButton } from '@/page/switch-balance/ui/currency-button/currency-button';
import { SwitchList } from '@/page/switch-balance/ui/switch-list/switch-list';

export function SliderCurrency({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { currency: userCurrency } = useSelector((store: RootState) => store.user);
  const { mining_currencies, pool_balance } = useSelector((store: RootState) => store.balance);
  const defaultCurrency = mining_currencies?.find((curr) => curr?.symbol === userCurrency);
  const [isLoading, setIsLoading] = useState(false);
  const [activeCurrency, setActiveCurrency] = useState(defaultCurrency?.symbol || userCurrency);
  const { ModalBottomSheet } = ModalLayout;

  const isValidCurrency = (currency: string): currency is Currencies => {
    return mining_currencies.some((c) => c.symbol === currency);
  };

  const changeCurrency = () => {
    if (isValidCurrency(activeCurrency) && userCurrency !== activeCurrency) {
      setIsLoading(true);
      rpc
        .transmit('users.me.change_currency', { currency: activeCurrency })
        .then(async (response) => {
          if ('currency' in response && response.currency && isValidCurrency(response.currency)) {
            setActiveCurrency(response.currency);
            setTimeout(() => {
              setIsOpen(false);
            }, 300);
            const rateParams = {
              from_currency: isEffectivelyZero(pool_balance?.total)
                ? activeCurrency || 'BTC'
                : response?.currency || 'BTC',
              to_currency: activeCurrency || userCurrency === 'USDT' ? 'USD' : 'USDT',
            };
            const rateParamsBtc = {
              from_currency: 'BTC',
              to_currency: activeCurrency,
            };
            const historyParams = {
              ...rateParams,
              interval: 'daily',
              time_start: dayjs().add(-1, 'months').toISOString(),
              time_end: dayjs().toISOString(),
            };
            await rpc.transmit('currencies.history', historyParams).then((r) => {
              dispatch(setHistory(r));
            });
            await rpc.transmit('currencies.rate', rateParams).then((r) => {
              dispatch(setRate(r));
            });
            await rpc.transmit('currencies.rate', rateParamsBtc).then((r) => {
              dispatch(setRateBtcToCurrentUserCurrency(r));
            });
            dispatch(userAction.checkUserNew(response));
          } else if ('rate' in response && 'to_currency' in response && isValidCurrency(response.to_currency)) {
            setActiveCurrency(response?.to_currency);
            setTimeout(() => {
              setIsOpen(false);
            }, 300);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          toast.dismiss();
          toaster.error('Error', { toastProps: { autoClose: 1000 } });
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    changeCurrency();
  }, [activeCurrency]);

  return (
    <div className={styles.currency}>
      {defaultCurrency && (
        <CurrencyButton
          key={defaultCurrency.symbol}
          iconUrl={defaultCurrency.img}
          onClick={() => setIsOpen(true)}
          name={defaultCurrency.name}
          symbol={defaultCurrency?.symbol}
          disabled={isLoading}
          arrow
        />
      )}
      <ModalBottomSheet isOpen={isOpen} onClose={() => setIsOpen(false)} id="address">
        <div className={styles.header}>{t('Balance_currency.Main_title')}</div>
        <SwitchList
          activeCurrency={userCurrency}
          setActiveCurrency={setActiveCurrency}
          error={isLoading}
          userCurrency={userCurrency}
          currencies={mining_currencies}
        />
      </ModalBottomSheet>
    </div>
  );
}
