import { SVGProps } from 'react';
import {
  BNBIcon,
  BTCIcon,
  CTCIcon,
  ETHIcon,
  POLIcon,
  SOLIcon,
  TETHERIcon,
  TRXIcon,
  DogeIcon,
  TonIcon,
  HSHIcon,
} from './icons';

export type Currencies = 'BTC' | 'ETH' | 'CTC' | 'USDT' | 'POL' | 'SOL' | 'BNB' | 'TRX' | 'DOGE' | 'TON' | 'HSH';

type CurrencyIconProps = {
  currency: Currencies | string;
  width?: number;
  height?: number;
} & SVGProps<SVGSVGElement>;

const getIcon = (currency: Currencies | string) => {
  switch (currency) {
    case 'BTC':
      return BTCIcon;
    case 'ETH':
      return ETHIcon;
    case 'SOL':
      return SOLIcon;
    case 'CTC':
      return CTCIcon;
    case 'POL':
      return POLIcon;
    case 'USDT':
      return TETHERIcon;
    case 'BNB':
      return BNBIcon;
    case 'TRX':
      return TRXIcon;
    case 'DOGE':
      return DogeIcon;
    case 'TON':
      return TonIcon;
    case 'HSH':
      return HSHIcon;
    default:
      return () => <span>{currency}</span>;
  }
};

export const CurrencyIcon = ({ currency, width = 20, height = 20, ...rest }: CurrencyIconProps) => {
  const Icon = getIcon(currency);

  return <Icon {...rest} width={width} height={height} />;
};
