import styles from './balance-withdraw-modal.module.scss';
import ModalLayout from '@/shared/ui/modal/modal';
import { Trans, useTranslation } from 'react-i18next';
import { MARKET_AGE_OF_EGGS_III, OPENSEA_AGE_OF_EGGS_III, RARIBLE_AGE_OF_EGGS_III } from '@/shared/lib/utils/links';
import { Icon } from '@/shared/ui/icons';
import cn from 'classnames';

export const BalanceWithdrawModal = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const { t } = useTranslation();
  const { Title, Header, Content, Description } = ModalLayout;

  return (
    <ModalLayout background isOpen={isOpen} onClose={() => onClose(false)} id="BalanceWithdraw">
      <Header align="center" hiddenDesktop>
        <Title size="xs">{t('AoE_III.Marketplace_Menu.Title')}</Title>
      </Header>
      <Content align="left">
        <Description className={styles.desc}>
          <Trans
            i18nKey={t('AoE_III.Marketplace_Menu.Description')}
            components={{ a: <a href={MARKET_AGE_OF_EGGS_III} target="_blank" rel="noreferrer" /> }}
          />
        </Description>
        <ul className={styles.list}>
          <li className={cn(styles.item, styles.blue)}>
            <a className={styles.link} href={OPENSEA_AGE_OF_EGGS_III} target="_blank" rel="noreferrer">
              <span className={styles.icon}>
                <Icon iconName="opensea" width={38} height={38} className={styles.opensea} />
              </span>
              <p>OpenSea</p>
            </a>
          </li>
          <li className={cn(styles.item, styles.pink)}>
            <a className={styles.link} href={MARKET_AGE_OF_EGGS_III} target="_blank" rel="noreferrer">
              <span className={styles.icon}>
                <Icon iconName="marketStore" width={38} height={38} className={styles.storeIcon} />
              </span>
              <p>CT Store</p>
            </a>
          </li>
          <li className={cn(styles.item, styles.yellow)}>
            <a className={styles.link} href={RARIBLE_AGE_OF_EGGS_III} target="_blank" rel="noreferrer">
              <span className={styles.icon}>
                <Icon iconName="raribleIcon" width={38} height={38} className={styles.raribleIcon} />
              </span>
              <p>Rarible</p>
            </a>
          </li>
        </ul>
      </Content>
    </ModalLayout>
  );
};
