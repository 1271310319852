import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { Typography } from '@/shared/ui/typography';
import { Button } from '@/shared/ui/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { Icon } from '@/shared/ui/icons';
import {
  checkPurchase,
  setGoogleInfo,
  setGoogleMiningDuration,
  useGoogleCurrentPlan,
  useGoogleMiningDurations,
} from '@/page/cart/store/cart-google-store';
import { BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import { PendingRenewalInfo, ReceiptInfo } from '@/shared/lib/backend/JsonRpcApi';
import { getLanguage } from '@/shared/store/language';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { formatHashrate } from '@/page/cart/ui/v1/hashrate';
import s from './subscription-mining-duration-ios.module.scss';
import { HshAlert } from '@/page/cart/ui/v1/bonus-alerts/hsh-alert';
import { useBonusBanner } from '@/page/cart/hooks/use-bonus-banner';
import { BonusNames, usePlansBonusName } from '@/shared/hooks/use-plans-bonus-name';
import { HpsAlert } from '@/page/cart/ui/v1/bonus-alerts/hps-alert';

export const SubscriptionMiningDurationIos = () => {
  const activeMonth = useSelector((state: RootState) => state.googleCart.miningDuration);
  const plans = useSelector((state: RootState) => state.googleCart.googleData?.plans);
  const lastReceiptIOS = useSelector((state: RootState) => state.googleCart.lastReceiptIOS);
  const miningDurationList = useSelector(useGoogleMiningDurations);
  const currentPlan = useSelector(useGoogleCurrentPlan);
  const [subscriptionsIos, setSubscriptionsIos] = useState<ReceiptInfo[]>([]);
  const [renewalIos, setRenewalIos] = useState<PendingRenewalInfo[]>([]);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const lang = getLanguage();
  const plansBonusName = usePlansBonusName();
  const { monthsBonus, isShowBanner } = useBonusBanner({
    plans,
    sliceTo: miningDurationList?.length,
    currentPlanMonth: currentPlan?.month,
  });

  useEffect(() => {
    if (lastReceiptIOS) {
      setSubscriptionsIos(lastReceiptIOS.latest_receipt_info);
      setRenewalIos(lastReceiptIOS.pending_renewal_info);
    }
  }, [lastReceiptIOS]);

  const handleButtonClick = useCallback(
    (month: number, productId: string, offerToken: string, basePlanId: string, trial: boolean, isActive: boolean) =>
      () => {
        dispatch(checkPurchase(isActive));
        dispatch(setGoogleMiningDuration({ miningDuration: month }));
        dispatch(setGoogleInfo({ productId: productId, offerToken: offerToken, basePlanId: basePlanId, trial: trial }));
      },
    [dispatch]
  );

  return (
    <div className={s.root}>
      <div className={s.top}>
        <Typography variant="h5" className={s.title}>
          <Icon iconName="clock" width={20} height={20} />
          {t('choose mining duration')}
        </Typography>
        <p className={s.top__text}>{t('Purchase_scr.Price.Subtitle')}</p>
      </div>
      <div className={s.buttonsContainer}>
        {miningDurationList &&
          miningDurationList.map((month, i) => {
            const plan = plans && plans.find((i) => i?.hps === currentPlan?.hps && i?.month === month);
            const discount = plan?.discount;
            const purchasedPlan = subscriptionsIos?.find((i) => i?.product_id === plan?.productId);
            const renewalInfo = renewalIos?.find((i) => i.product_id === plan?.productId);
            const pendingRenewalInfo = renewalIos?.find(
              (i) => i.auto_renew_product_id === plan?.productId && i.product_id !== plan?.productId
            );
            const planActivated =
              purchasedPlan && (+purchasedPlan.expires_date_ms > Date.now() || renewalInfo?.auto_renew_status === '1');
            let formattedDate = '';
            if (purchasedPlan?.purchase_date_ms || purchasedPlan?.original_purchase_date_ms) {
              const date = new Date(+(purchasedPlan?.purchase_date_ms || purchasedPlan?.original_purchase_date_ms));
              // Format the date to '17 Oct. 2024'
              formattedDate = new Intl.DateTimeFormat(lang, {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
              }).format(date);
            }
            const isDoubleHpsIcon = !plan?.hsh_reward && plan?.tags?.includes(BONUS_TAG_20) && plan?.hps_bonus;

            return (
              <div
                key={month}
                className={cn(s.buttonWrapper, {
                  [s.inactive]: activeMonth !== month,
                  [s.buttonWrapper_faded]: (purchasedPlan || pendingRenewalInfo) && activeMonth !== month,
                  [s.buttonWrapper_indentation]: activeMonth !== month && i === miningDurationList.length - 1,
                })}
              >
                <Button
                  borderRadius={false}
                  fullWidth
                  key={month}
                  variant="outline"
                  onClick={handleButtonClick(
                    month,
                    plan?.productId || '',
                    plan?.offerToken || '',
                    plan?.basePlanId || '',
                    !!plan?.trial,
                    !!purchasedPlan || !!pendingRenewalInfo
                  )}
                  color="black"
                  className={cn(s.button, {
                    [s.inactive]: activeMonth !== month,
                    [s.subs_actived]: purchasedPlan && planActivated,
                    [s.subs_inactive]: (purchasedPlan && !planActivated) || pendingRenewalInfo,
                    [s.subs_indentation]: activeMonth !== month && i === miningDurationList.length - 1,
                  })}
                >
                  <div className={s.button__container}>
                    <span className={s.button__text}>
                      {month} {t('mo.')}
                    </span>
                    {!!purchasedPlan && !!planActivated ? (
                      <span className={s.button__actived}>
                        {t('workerDetails.activated')}
                        <Icon iconName="check-green" width={20} height={20} />
                      </span>
                    ) : (
                      <>
                        <div className={cn(s.plain, isDoubleHpsIcon && s[`plain--background`])}>
                          {discount && discount > 1 ? <span className={s.button__discount}>-{discount}%</span> : null}
                          {plan?.tags?.includes(BONUS_TAG_20) && plan?.hsh_reward && (
                            <Icon iconName="gift-yellow" width={24} height={24} className={s.button__icon} />
                          )}
                          {isDoubleHpsIcon && (
                            <Icon iconName="white-gift-x2" width={15} height={16} className={s.button__icon} />
                          )}
                        </div>
                        <span className={s.button__plan}>
                          {plan && getLocalAmount(plan?.total_price, plan?.currentCurrency)}
                        </span>
                      </>
                    )}
                    {i === miningDurationList.length - 1 &&
                      activeMonth !== month &&
                      !pendingRenewalInfo &&
                      !purchasedPlan && (
                        <span className={s.button__tag}>{t('MTU.Cloud_boost_buy.Tag_profitable')}</span>
                      )}
                  </div>
                  {plan &&
                    !purchasedPlan &&
                    !pendingRenewalInfo &&
                    activeMonth === month &&
                    (plan?.hsh_reward || plan?.hps_bonus) && (
                      <div className={cn(s.button__bonus, s.button__bonus__active)}>
                        <Icon iconName="gift" width={20} height={20} />
                        {plan?.hsh_reward ? (
                          <>
                            {t('BB_Hsh.Bonus')}:
                            <span className={s.button__right}>
                              +{getLocalAmount(plan?.hsh_reward)} HSH {plan?.tags?.includes(BONUS_TAG_20) && '(x2)'}
                            </span>
                          </>
                        ) : (
                          <>
                            {t('hps_increment')}:
                            <span className={s.button__right}>
                              +{formatHashrate(plan?.hps_bonus)} {plan?.tags?.includes(BONUS_TAG_20) && '(x2)'}
                            </span>
                          </>
                        )}
                      </div>
                    )}
                </Button>
                {!!purchasedPlan && !planActivated && (
                  <div className={cn(s.activatedLabel, s.activatedLabel_grey)}>
                    <div className={s.activatedLabel__text}>
                      <Icon iconName="closeCircle" width={20} height={20} />
                      {t('Inactive')}
                    </div>
                    <div className={s.activatedLabel__date}>{formattedDate}</div>
                  </div>
                )}
                {!!pendingRenewalInfo && (
                  <div className={cn(s.activatedLabel, s.activatedLabel_grey)}>
                    <div className={s.activatedLabel__text}>
                      <Icon iconName="timer" width={20} height={20} />
                      {t('Next')}
                    </div>
                    <div className={s.activatedLabel__date}>{formattedDate}</div>
                  </div>
                )}
              </div>
            );
          })}
      </div>
      {plansBonusName === BonusNames.HSH && isShowBanner ? <HshAlert months={monthsBonus} /> : null}
      {plansBonusName === BonusNames.HPS && isShowBanner ? <HpsAlert months={monthsBonus} /> : null}
    </div>
  );
};
