import React from 'react';
import cx from 'classnames';
import styles from './balance-hsh.module.scss';
import { Icon } from '@/shared/ui/icons';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

interface BalanceHSHProps {
  balance: number;
  currency?: string;
  small?: boolean;
}

export const BalanceHSH: React.FC<BalanceHSHProps> = ({ balance = 0, currency = 'HSH', small = false }) => {
  const iconSize = small ? 20 : 30;

  return (
    <div className={styles.balance}>
      <span className={cx(styles.balance__icon, { [styles.small]: small })}>
        <Icon iconName="hsh" width={iconSize} height={iconSize} />
      </span>
      <span className={cx(styles.balance__number, { [styles.small]: small })}>{getLocalAmount(balance)}</span>
      <span className={cx(styles.balance__currency, { [styles.small]: small })}>{currency}</span>
    </div>
  );
};
