import { getWithdrawals, selectWithdrawal } from '@/shared/store/withdrawal/withdrawal.slice';
import { useTranslation } from 'react-i18next';
import styles from './history-view.module.scss';
import { Button } from '@/shared/ui/button';
import { Icon } from '@/shared/ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoaderMobile, LoadSpinner } from '@/shared/ui/loader/loader';
import { AppDispatch, RootState } from '@/shared/store/types';
import { useEffect, useRef } from 'react';
import { EmptyHistory } from '@/page/history/ui/empty-history/empty-history';
import { BalanceHistoryItem } from '@/features/balance';

export const HistoryView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { withdrawalsToShow, isNextWithdrawalsLoading, total, isFirstLoading } = useSelector(selectWithdrawal);
  const { currencies } = useSelector((store: RootState) => store.balance);

  const dispatch: AppDispatch = useDispatch();

  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries?.[0]?.isIntersecting) {
          if (withdrawalsToShow.length < total) {
            dispatch(getWithdrawals());
          }
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    const observerRefValue = observerTarget.current;

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [withdrawalsToShow, total, observerTarget, dispatch]);

  return (
    <div className={styles.root}>
      {isFirstLoading ? (
        <LoaderMobile />
      ) : (
        <div className={styles.block}>
          <>
            {!isNextWithdrawalsLoading && withdrawalsToShow.length === 0 && <EmptyHistory />}
            <ul className={styles.list}>
              {withdrawalsToShow.length > 0 &&
                withdrawalsToShow?.map((item) => (
                  <BalanceHistoryItem key={item.id} withdrawal={item} currencies={currencies} />
                ))}
              {isNextWithdrawalsLoading && (
                <div className={styles.loader}>
                  <LoadSpinner color="blue" />
                </div>
              )}
            </ul>
            <div className={styles.footer}>
              <Button fullWidth color="white-blue" variant="filled" borderRadius={false} onClick={() => navigate(-1)}>
                <Icon iconName="arrowLeft" width={20} height={20} />
                {t('GO BACK')}
              </Button>
            </div>
          </>
        </div>
      )}
      <div ref={observerTarget}></div>
    </div>
  );
};
