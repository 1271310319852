import { useSelector } from 'react-redux';
import { selectCurrentTheme } from '@/features/theme';
import { NcwIcon, ncwIconProps } from '@/shared/ui/ncw-icon/ncw-icon';
import { AppTheme } from '@/features/theme/model/slice';

export const NcwIconContainer = (props: ncwIconProps) => {
  const theme = useSelector(selectCurrentTheme);

  return <NcwIcon {...props} isDark={theme === AppTheme.LIGHT} />;
};
