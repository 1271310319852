import { getLanguage } from '@/shared/store/language';

export const getLocalAmount = (amount: string | number, currencyCode?: string): string => {
  const lang = getLanguage();
  const options = currencyCode
    ? { style: 'currency', currency: currencyCode }
    : {
        style: 'decimal',
        maximumFractionDigits: 15,
        minimumFractionDigits: +amount > 0 ? 0 : `${amount}`.replace(/[0-9]\.*/, '').length,
      };

  return new Intl.NumberFormat(lang, options).format(+amount);
};
