import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { formatBalance } from '@/shared/lib/utils/units';
import { useMemo } from 'react';

export const useBalanceInfo = () => {
  const { pool_balance, currencies, mining_currencies } = useSelector((store: RootState) => store.balance);
  const { withdrawalsCreated } = useSelector((store: RootState) => store.withdrawal);
  const rate = useSelector((store: RootState) => store.rate);
  const { currency: userCurrency } = useSelector((store: RootState) => store.user);

  const currentCurrencyObj = useMemo(
    () => mining_currencies?.find((cur) => cur?.symbol === userCurrency),
    [mining_currencies, userCurrency]
  );

  const hsh_currency = useMemo(() => currencies?.find((cur) => cur?.symbol === 'HSH'), [currencies]);

  const activeWithdrawalsSum = useMemo(
    () =>
      withdrawalsCreated.reduce((sum, { amount, currency }) => {
        if (currency === userCurrency) {
          const parsedAmount = parseFloat(amount);
          return sum + (isNaN(parsedAmount) ? 0 : parsedAmount);
        }
        return sum;
      }, 0),
    [withdrawalsCreated, userCurrency]
  );

  const formattedBalance = useMemo(() => {
    if (!currentCurrencyObj) return '0.00';
    const balance = +pool_balance?.total - activeWithdrawalsSum;
    return balance >= +currentCurrencyObj.unit
      ? formatBalance(balance, currentCurrencyObj.unit)
      : currentCurrencyObj.unit.toString().replace(/1$/, '0');
  }, [currentCurrencyObj, pool_balance, activeWithdrawalsSum]);

  const usdBalance = useMemo(
    () => (formattedBalance ? (+formattedBalance * +rate.crypto2usdRate).toFixed(2) : '0.00'),
    [formattedBalance, rate.crypto2usdRate]
  );

  return {
    formattedBalance,
    usdBalance,
    currentCurrencyObj,
    hsh_currency,
    userCurrency,
    exact_total: pool_balance?.exact_total,
  };
};
