import { Trans, useTranslation } from 'react-i18next';

import { Discount } from './discount';

import s from './total-price.module.scss';
import { Typography } from '@/shared/ui/typography';
import { useSelector } from 'react-redux';
import { Icon } from '@/shared/ui/icons';
import { BONUS_TAG, BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import { useCurrentPlan } from '@/page/cart/store';
import { CoinsIcon } from '@/page/cart/assets';
import { MinerInfo } from '@/page/cart/ui/v2/total-price/miner-info';
import { useCurrentPlanPriceInfo } from '@/page/cart/hooks';
import cn from 'classnames';

export const TotalPrice = () => {
  const { t } = useTranslation();
  const currentPlan = useSelector(useCurrentPlan);

  const { userCurrency, formattedPrice, formattedPriceRegular, currentCurrencyObj } = useCurrentPlanPriceInfo();

  return (
    <div className={s.container}>
      <Typography variant="h5" weight="bold" className={s.title}>
        <CoinsIcon /> {t('total price')}
      </Typography>
      <MinerInfo />
      <div className={s.totalPriceRows}>
        <div className={s.plain}>
          <Icon iconName="percent" width={20} height={20} className={s.discount} />
          <span className={s.discounts}>{t('N.YourDiscount.Title')}</span>
          <Discount discount={currentPlan.discount} promoDiscount={0} />
        </div>
        {/*<div className={s.row}>*/}
        {/*  <Typography variant="h5" weight="normal">*/}
        {/*    {t('Regular Price')}*/}
        {/*  </Typography>*/}
        {/*  <div className={s.priceContainer}>*/}
        {/*    <CurrencyIcon currency="BTC" />*/}
        {/*    <Typography variant="h4" className={s.oldPrice}>*/}
        {/*      {formatPrice(getRegularPrice(currentPlan))}*/}
        {/*    </Typography>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
      {currentPlan?.tags?.includes(BONUS_TAG_20) && (
        <div className={s.bonuses}>
          {currentPlan?.tags?.includes(BONUS_TAG_20) && (
            <div className={s.bonus}>
              <Icon iconName="purple-gift-x2" width={20} height={20} />
              <Trans
                i18nKey="BonusHashx2.Short_description"
                components={{ b: <span className={cn(s.bonus__titleColor, s.bonus__title)} /> }}
              />
              <Discount promoDiscount={0} discount={0} double={currentPlan?.hps_bonus} />
            </div>
          )}
        </div>
      )}
      {currentPlan?.tags?.includes(BONUS_TAG) && (
        <div className={s.bonuses}>
          {currentPlan?.tags?.includes(BONUS_TAG) && (
            <div className={s.bonus}>
              <Icon iconName="red-gift" width={20} height={20} />
              <span className={s.bonus__title}>{t('hps_increment')}</span>
              <Discount promoDiscount={0} discount={0} bonus={currentPlan?.hps_bonus} />
            </div>
          )}
        </div>
      )}

      <div className={s.row}>
        <span className={s.totalPriceRowTitle}>
          <img src={currentCurrencyObj!.img} /> {t('total price')}{' '}
        </span>
        <div className={s.priceContainer}>
          <span className={s.totalPrice}>
            {formattedPrice} {userCurrency}
          </span>
          <p className={s.oldPrice}>{formattedPriceRegular}</p>
        </div>
        {/*<div className={s.priceContainer}>*/}
        {/*      <span className={s.priceUsd}>*/}
        {/*        {t('Approx.')} ${currentPriceUsd} USD*/}
        {/*      </span>*/}
        {/*</div>*/}
      </div>

      <div className={s.info}>{t('N.DifferentCurrency.Description')}</div>
    </div>
  );
};
