import styles from './modal-background.module.scss';
import ModalLayout from '@/shared/ui/modal/modal';
import { useTranslation } from 'react-i18next';
import { Icon } from '@/shared/ui/icons';
import { SelectableColors, selectableColorsSolid } from '@/features/theme/model/types';
import { useDispatch, useSelector } from 'react-redux';
import { changeBackground, selectCurrentBg } from '@/features/theme/model/slice';
import cn from 'classnames';
import { useState } from 'react';
import { AppDispatch } from '@/shared/store/types';
import { Button } from '@/shared/ui/button';
import { STORAGE_RANDOM_BACKGROUND } from '@/shared/lib/constants/storage-key';

export const ModalBackground = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const currentBg = useSelector(selectCurrentBg);
  const userRandomBg = localStorage.getItem(STORAGE_RANDOM_BACKGROUND);
  const [selectedBackground, setSelectedBackground] = useState(currentBg);
  const [checked, setChecked] = useState(() => userRandomBg === 'true');
  const [change, setChange] = useState(false);
  const { t } = useTranslation();
  const { Header, Title, ContentMobile } = ModalLayout;
  const dispatch: AppDispatch = useDispatch();

  const handleChange = (bg: SelectableColors) => {
    setSelectedBackground(bg);
    setChange(true);
    localStorage.setItem(STORAGE_RANDOM_BACKGROUND, 'false');
    setChecked(false);
  };

  const handleChangeBackground = () => {
    dispatch(changeBackground(selectedBackground));
    setChange(false);
  };

  const handleCheckbox = () => {
    if (checked) {
      localStorage.setItem(STORAGE_RANDOM_BACKGROUND, 'false');
      setChecked(false);
    } else {
      localStorage.setItem(STORAGE_RANDOM_BACKGROUND, 'true');
      setChecked(true);
    }
  };

  return (
    <ModalLayout background onClose={() => onClose(false)} isOpen={isOpen} id="ModalBackground">
      <Header>
        <Title size="xs">{t('Background.Title')}</Title>
      </Header>
      <ContentMobile style={{ background: selectedBackground }}>
        <div className={cn(styles.block, change && styles.active)}>
          <div className={styles.block__title}>{t('PickColor')}</div>
          <div className={styles.block__content}>
            <ul className={styles.list}>
              {selectableColorsSolid.map((color, idx) => (
                <button
                  className={cn(styles.item, color === selectedBackground && !checked && styles.active)}
                  key={idx}
                  style={{ background: color }}
                  onClick={() => handleChange(color)}
                >
                  {!checked && (
                    <span className={styles.check}>
                      <Icon iconName="checked" width={10} height={10} />
                    </span>
                  )}
                </button>
              ))}
            </ul>
          </div>
          <div className={styles.footer}>
            <div className={styles.footer__text}>
              <Icon iconName="random" width={20} height={20} className={styles.footer__icon} />
              {t('Background.Random')}
            </div>
            <label className={styles.switch}>
              <input type="checkbox" className={styles.switchInput} checked={checked} onChange={handleCheckbox} />
              <span className={styles.switchSlider} />
            </label>
          </div>
        </div>
        {change && (
          <div className={cn(styles.btnBlock, change && styles.active)}>
            <Button className={styles.btn} color="blue" fullWidth onClick={handleChangeBackground}>
              <Icon iconName="checked" width="17" height="16" />
              {t('Confirm')}
            </Button>
            <Button className={styles.btn} variant="outline" fullWidth onClick={() => onClose(false)}>
              <Icon iconName="cancel" width="20" height="20" />
              {t('Cancel')}
            </Button>
          </div>
        )}
      </ContentMobile>
    </ModalLayout>
  );
};
