import React, { useMemo, useState } from 'react';
import styles from './christmas-banner.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { CHRISTMAS_BANNER_ID, CHRISTMAS_INFO_URL, getIsChristmas } from '@/page/home/ui/christmas/helpers';
import { useChristmasSnowflakes } from '@/page/home/ui/christmas/hooks/useChristmasSnowflakes';
import christmasBackgroundSrc from '../image/christmas_banner_mobile.png';
import flakeIconSrc from '../image/flake.svg';
import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import isNil from 'lodash/isNil';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

export const ChristmasBanner = () => {
  const { t } = useTranslation();
  const [showChristmasBanner, setShowChristmasBanner] = useState(() => getIsChristmas());
  const snowflakes = useChristmasSnowflakes();
  const userTags = useSelector((state: RootState) => state.user.tags);
  const isChristmasParticipant = userTags?.includes('xmas_24');
  const { isTelegramMiniApp, isIos } = useDevice();
  const utm_source: string = isTelegramMiniApp ? 'plt' : isIos ? 'pli' : 'pla';

  const onClickCloseBanner = () => {
    const timestamp = Date.now();
    localStorage.setItem(CHRISTMAS_BANNER_ID, timestamp.toString());
    setShowChristmasBanner(false);
  };

  const onLinkClick = () => {
    window.open(`${CHRISTMAS_INFO_URL}?utm_source=${utm_source}&utm_medium=bnrfes`, '_blank');
  };

  const content = useMemo(() => {
    return !isNil(snowflakes.snowflakesCount) ? (
      <div className={styles.candiesCollectedContainer}>
        <img src={flakeIconSrc} alt="Flake icon" className={styles.flakeIcon} />
        <h3 className={styles.candiesCollectedTitle}>{t('Fest24.Snippet.Btn_1')}</h3>
        <div className={styles.candiesCollectedLabel}>{getLocalAmount(snowflakes.snowflakesCount)}</div>
      </div>
    ) : (
      <p className={styles.text}>
        <Trans i18nKey={'Fest24.Quests.Descr'} components={{ b: <b /> }} />
      </p>
    );
  }, [snowflakes.snowflakesCount, t]);

  if (!showChristmasBanner) {
    return null;
  }

  return (
    <div className="container">
      <div className={styles.root}>
        <img src={christmasBackgroundSrc} alt="Christmas background" className={styles.background} />
        <span className={styles.close} onClick={onClickCloseBanner}>
          <Icon iconName="close" width="12" height="12" />
        </span>
        <div className={styles.content}>
          <div className={styles.title}>{t('Fest24.Quests.Title')}</div>
          {content}
          <div className={styles.button}>
            {isChristmasParticipant ? (
              <Button color="blue" fullWidth onClick={onLinkClick}>
                <Icon iconName="stats-icon" width="20" height="20" className={styles.icon} />
                {t('BF24.Banner.Btn')}
              </Button>
            ) : (
              <Button color="blue" fullWidth onClick={onLinkClick}>
                {t('BF24.Banner.Short.Btn')}
                <Icon iconName="arrow" width="20" height="20" className={styles.icon} />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
