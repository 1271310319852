import Tippy, { TippyProps } from '@tippyjs/react';
import React, { type PropsWithChildren } from 'react';

interface CustomTippyProps extends TippyProps {
  visibleTooltip: boolean;
  hideTooltip: () => void;
}

export const CustomTippy = ({
  content,
  visibleTooltip,
  hideTooltip,
  children,
  appendTo = document.body,
  placement = 'bottom',
  arrow = false,
  theme = 'custom',
  ...props
}: PropsWithChildren<CustomTippyProps>) => {
  return (
    <Tippy
      appendTo={appendTo}
      content={content}
      placement={placement}
      arrow={arrow}
      theme={theme}
      visible={visibleTooltip}
      onClickOutside={(instance, event) => {
        const tooltipNode = instance.popper;
        if (tooltipNode && !tooltipNode.contains(event.target as Node)) {
          hideTooltip();
        }
      }}
      {...props}
    >
      {children}
    </Tippy>
  );
};
