import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './loader.module.scss';
import { Icon } from '@/shared/ui/icons';
import cn from 'classnames';

import { useSelector } from 'react-redux';
import { selectCurrentTheme } from '@/features/theme';
import { DefaultLoader } from '@/shared/ui/loader/default-loader';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { AppTheme, selectIsMobile } from '@/features/theme/model/slice';

export function Loader() {
  const { t } = useTranslation();
  const [err, setErr] = useState(false);
  const refresh = useCallback(() => {
    window.location.reload();
  }, []);
  useEffect(() => {
    setTimeout(() => setErr(true), 10000);
  }, []);
  return (
    <>
      <div className={`${styles.loader_dash} ${err && styles.hide}`}>
        <LoadSpinner />
      </div>
      <div className={`${styles.loader_error} ${err && styles.show}`}>
        <p>{t('Something Went Wrong')}...</p>
        <button className={styles.loader_error__btn} onClick={refresh}>
          <Icon iconName="refresh" width="24" height="24" /> {t('Refresh')}
        </button>
      </div>
    </>
  );
}

export function LoaderMobile() {
  const { isTelegramMiniApp } = useDevice();
  const isMobileState = useSelector(selectIsMobile);
  const currentTheme = useSelector(selectCurrentTheme);
  return (
    <div className={styles.root}>
      <div className={isMobileState || isTelegramMiniApp ? styles.headerTransparent : styles.header}>
        {isMobileState || isTelegramMiniApp ? null : (
          <Icon iconName={currentTheme === AppTheme.DARK ? 'logoFooter' : 'logoHeader'} width={174} height={32} />
        )}
      </div>
      <DefaultLoader />
    </div>
  );
}

export function FixedLoader() {
  return (
    <div className={styles.fixed}>
      <div className={styles.fixed__overlay} />
      <DefaultLoader className={styles.fixed__loader} />
    </div>
  );
}

export const LoadSpinner = ({ color, className }: { color?: string; className?: string }) => {
  return (
    <div className={cn(styles.loader_dash_spinner, color && styles[color], className)}>
      <i></i>
      <i></i>
      <i></i>
      <i></i>
    </div>
  );
};
