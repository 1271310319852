import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UTM_OBJ } from '@/shared/lib/constants/storage-key';

export type utmObjType = { [key: string]: string };
export type utmStore = {
  utmObj: utmObjType;
};

let storage_utm = {};
try {
  const storage_str = localStorage.getItem(UTM_OBJ);
  if (storage_str) {
    const parse_utm = JSON.parse(storage_str);
    if (parse_utm?.time > Date.now() - 86400000) {
      storage_utm = parse_utm.utm;
    } else {
      localStorage.removeItem(UTM_OBJ);
    }
  }
} catch (e) {
  console.error(e);
}

const initialState: utmStore = {
  utmObj: storage_utm,
};

export const utmSlice = createSlice({
  name: 'utms',
  initialState,
  reducers: {
    setUtmObg(store, payload: PayloadAction<utmObjType>) {
      store.utmObj = payload.payload;
      localStorage.setItem(UTM_OBJ, JSON.stringify({ time: Date.now(), utm: store.utmObj }));
    },
  },
});

export const { setUtmObg } = utmSlice.actions;

export default utmSlice.reducer;
