import { useMemo } from 'react';
import { BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import { GooglePlan } from '@/page/cart/store/cart-google-store';
import { MiningPoolPlan } from '@/shared/lib/models';

export type useBonusBannerType = {
  plans: GooglePlan[] | MiningPoolPlan[] | undefined;
  sliceTo: number;
  currentPlanMonth: number;
};

export const useBonusBanner = ({ plans, sliceTo, currentPlanMonth }: useBonusBannerType) => {
  const monthsBonus = useMemo(
    () =>
      plans
        ?.slice(0, sliceTo)
        ?.reduce((acc: number[], plan) => (plan?.tags?.includes(BONUS_TAG_20) ? [...acc, plan?.month] : acc), [])
        ?.join(', '),
    [plans, sliceTo]
  );

  const isShowBanner = useMemo(
    () => monthsBonus && !monthsBonus.includes(`${currentPlanMonth}`),
    [monthsBonus, currentPlanMonth]
  );

  return { monthsBonus, isShowBanner };
};
