import { useSelector } from 'react-redux';
import { rootUser } from '@/shared/store/user/user.slice';
import { generatePageRefLink, generatePoolRefLink } from '@/shared/lib/utils/generatedRefLinks';
import { useTranslation } from 'react-i18next';
import { useDevice } from '@/shared/lib/hooks/useDevice';

const isShort = Math.random() > 0.5;

export const useShareProduct = () => {
  const { id } = useSelector((store: { user: rootUser }) => store.user);
  const { t } = useTranslation();
  const { isTelegramMiniApp, isIos } = useDevice();
  const utm_source: string = isTelegramMiniApp ? 'plt' : isIos ? 'pli' : 'pla';

  const pageRefLink = generatePageRefLink(id, isShort, utm_source);
  const poolRefLink = generatePoolRefLink(id, isShort, utm_source);

  const text = t(isShort ? 'AfP.Sharing_textShort' : 'AfP.SharingText', {
    ref_link: poolRefLink,
    page_link: pageRefLink,
    interpolation: { escapeValue: false },
  });

  return { pageRefLink, poolRefLink, text };
};
