import ModalLayout from '@/shared/ui/modal/modal';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import styles from './modal-language.module.scss';
import { Code, languages } from '@/shared/lib/constants/languages';
import { getLanguage, setLanguage } from '@/shared/store/language';
import { Icon } from '@/shared/ui/icons';
import { useState } from 'react';
import { Button } from '@/shared/ui/button';

export const ModalLanguage = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const activeCode = getLanguage();
  const [choice, setChoice] = useState(activeCode);
  const [change, setChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { Title, Header, ContentMobile } = ModalLayout;

  const handleClick = (code: Code) => {
    setChoice(code);
    if (code === activeCode) {
      setChange(false);
    } else {
      setChange(true);
    }
  };

  const handleChange = () => {
    setLanguage(choice);
    setLoading(true);
    setTimeout(() => {
      setChange(false);
      onClose(false);
      setLoading(false);
    }, 1000);
  };

  return (
    <ModalLayout background onClose={() => onClose(false)} isOpen={isOpen} id="ModalLanguage">
      <Header>
        <Title size="xs">{t('Language')}</Title>
      </Header>
      <ContentMobile>
        <ul
          className={cn(styles.list, {
            [styles.active]: change,
          })}
        >
          {Object.values(languages).map((language) => (
            <li
              key={language.code}
              className={cn(styles.item, {
                [styles.active]: language.code === activeCode,
                [styles.choice]: language.code === choice,
              })}
              onClick={() => handleClick(language.code)}
            >
              <img src={language.icon} alt={`${language.code}-language-icon`} />
              {language.title}
              {(language.code === activeCode || language.code === choice) && (
                <Icon iconName="checked" width="17" height="16" className={styles.iconCheck} />
              )}
            </li>
          ))}
        </ul>
        {change && (
          <div className={cn(styles.btnBlock, change && styles.active)}>
            <Button
              className={styles.btn}
              color="blue"
              fullWidth
              onClick={handleChange}
              spinnerTheme="static"
              disabled={loading}
              loading={loading}
            >
              <Icon iconName="checked" width="17" height="16" />
              {t('Confirm')}
            </Button>
            <Button className={styles.btn} color="white-blue" variant="filled" fullWidth onClick={() => onClose(false)}>
              <Icon iconName="cancel" width="20" height="20" />
              {t('CANCEL')}
            </Button>
          </div>
        )}
      </ContentMobile>
    </ModalLayout>
  );
};
