import React, { useMemo } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import styles from './products-slider.module.scss';
import { ProductCard, useProductsList } from '@/features/crypto-tab-products';
import { CRYPTO_TAB_PRODUCTS_PATH } from '@/shared/lib/utils/links';
import { Icon } from '@/shared/ui/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const ProductsSlider = () => {
  const { t } = useTranslation();
  const { products } = useProductsList();

  const shuffledProducts = useMemo(() => {
    return products.sort(() => Math.random() - 0.5);
  }, [products]);

  return (
    <div className={styles.container}>
      <div className={styles.sectionTitleContainer}>
        <Link to={CRYPTO_TAB_PRODUCTS_PATH} className={styles.title}>
          {t('Products.General_title')} <Icon iconName="arrow" width="20" height="20" />
        </Link>
      </div>
      <div className={styles.sliderContainer}>
        <Swiper
          modules={[Pagination]}
          autoHeight
          className={styles.swiperContainer}
          initialSlide={2}
          pagination={{
            dynamicBullets: true,
            dynamicMainBullets: 1,
            el: '.custom-pagination',
          }}
          loop
        >
          {shuffledProducts?.map((product) => (
            <SwiperSlide key={product.name} className={styles.slideContainer}>
              <ProductCard product={product} infoIcon />
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.paginationContainer}>
          <div className="custom-pagination" />
        </div>
      </div>
    </div>
  );
};
