import styles from '@/features/rate-us/ui/rate-us-modal/rate-us-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import cn from 'classnames';
import { Button } from '@/shared/ui/button';
import { useTranslation } from 'react-i18next';

type FailBlockProps = {
  onClose: (e: boolean) => void;
  next: (e: 'feedback') => void;
};

export const FailBlock = ({ onClose, next }: FailBlockProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.block}>
        <h6 className={styles.title}>{t('MTU.Modal.Feedback.Title')}</h6>
        <span className={styles.close} onClick={() => onClose(false)}>
          <Icon iconName="close" width="20" height="20" />
        </span>
        <Icon iconName="modalError" width={70} height={70} className={styles.icon} />
        <h6 className={cn(styles.title, styles.center)}>{t('Something Went Wrong')}</h6>
        <p className={cn(styles.text, styles.center)}>{t('errors.unknown.description')}</p>
        <div className={styles.buttonBlock}>
          <Button color="blue" fullWidth borderRadius={false} onClick={() => next('feedback')}>
            <Icon iconName="refresh" width={20} height={20} />
            {t('RETRY')}
          </Button>
        </div>
      </div>
    </>
  );
};
