import styles from './share-to-earn-info.module.scss';
import { Icon } from '@/shared/ui/icons';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import browserShare from '@/shared/lib/utils/browserShare';
import EarnImg from './../images/earn-img.png';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useShareProduct } from '@/shared/lib/hooks/useShareProduct';

export const ShareToEarnInfo = () => {
  const { t } = useTranslation();
  const { poolRefLink, text } = useShareProduct();
  const { isTelegramMiniApp } = useDevice();
  const onClickShare = async () => {
    try {
      await browserShare({
        url: poolRefLink,
        text: text,
        isTelegramMiniApp,
      });
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.image}>
          <img src={EarnImg} alt={t('Earn')} />
        </div>
        <p className={styles.text}>{t('BB_Hsh.BonusProg.Descr')}</p>
      </div>
      <div className={styles.footer}>
        <Button fullWidth color="green" onClick={onClickShare}>
          <Icon iconName="share" width="20" height="20" />
          {t('BB_Hsh.Share_btn')}
        </Button>
      </div>
    </div>
  );
};
