import styles from '@/features/rate-us/ui/rate-us-modal/rate-us-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';

type SuccessBlockProps = {
  onClose: (e: boolean) => void;
};

export const SuccessBlock = ({ onClose }: SuccessBlockProps) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={styles.block}>
        <h6 className={styles.title}>{t('MTU.Modal.Feedback.Title')}</h6>
        <span className={styles.close} onClick={() => onClose(false)}>
          <Icon iconName="close" width="20" height="20" />
        </span>
        <Icon iconName="modalSuccess" width={70} height={70} className={styles.icon} />
        <h6 className={cn(styles.title, styles.center)}>{t('MTU.Modal.Feedback_Success.Title')}</h6>
        <p className={cn(styles.text, styles.center)}>{t('MTU.Modal.Feedback_Success.Description')}</p>
        <div className={styles.buttonBlock}>
          <Button color="blue" fullWidth borderRadius={false} onClick={() => onClose(false)}>
            <Icon iconName="checked" width={20} height={20} />
            {t('DONE')}
          </Button>
        </div>
      </div>
    </>
  );
};
