import { ModalName } from '@/shared/lib/context/modal/modal-render';
import { useModal } from '@/shared/lib/context/modal/useModal';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { useDevice } from '@/shared/lib/hooks/useDevice';
import { useEffect, useState } from 'react';

export const ModalDebug = () => {
  const { openModal } = useModalSet();
  const { modal } = useModal();
  const { isTelegramMiniApp } = useDevice();
  const [modals, setModals] = useState<ModalName[]>([
    ...((!isTelegramMiniApp ? ['MODAL_RATE_US'] : []) as ModalName[]),
    ...([
      'MODAL_ADDRESS_INFO',
      'MODAL_CONFIRM_WITHDRAWAL',
      'MODAL_POOL_MINER_ACTIVE',
      'MODAl_INFO_BONUS_HSH',
      'MODAL_INCREASING_HSH',
      'MODAL_ERROR_WITHDRAWAL_ADDRESS',
      'MODAL_RATE_COMPANY',
      'MODAL_OFFER',
      'MODAL_PROMO_ADD_POOL_MINER',
      'MODAL_BTC_CONNECT',
      'MODAL_NC_WALLET_REJECT',
      'MODAL_NC_WALLET_CONFIRM',
      'MODAL_SWITCH_BALANCE_CONFIRM',
      'MODAL_SUCCESS_CANCEL_WITHDRAWAL',
      'MODAL_CANCEL_WITHDRAWAL',
      'MODAL_EMAIL_CHECK',
      'MODAL_EMAIL_VERIFY',
      'MODAL_WITHDRAW_SUSPENDED',
      'MODAL_ERROR',
      'MODAL_ERROR_FUNDS_WITHDRAWAL',
      'MODAL_INFO_POOL',
      'MODAL_SET_BACKGROUND',
      'MODAL_SET_LANGUAGE',
      'MODAL_GO_TO_PAYMENT',
      'MODAL_ACCOUNT_BLOCKED',
    ] as ModalName[]),
  ]);

  useEffect(() => {
    if (!modal?.name) {
      openModal(modals[0]);
      setModals((prev) => prev.slice(1));
    }
  }, [modal]);

  return <></>;
};
