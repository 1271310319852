import { TMining } from '@/shared/store/pool-miner/pool-miner.slice';
import { MiningPoolPlan } from '@/shared/lib/models';

export interface AuthRequest {
  access_token: string;
  lang?: string;
}

export interface Account {
  address: string;
  date_joined?: number;
  currency_changed_at?: number;
  email?: string;
  email_verified?: boolean;
  email_verified_at?: Date;
  id: number;
  min_withdrawal_amount?: number;
  ncw_min_withdrawal_amount?: number;
  tags: string[];
}

export interface PoolMinerMessage {
  id: string;
  hps: number;
  hsh_reward?: string;
  hps_bonus?: number;
  create_time: number;
  end_time: number;
  deleted: boolean;
  is_google_subscription?: boolean;
  product_id?: string;
}

export interface PoolMiningMessage {
  id: string;
  hps: number;
  end_time: number;
  deleted: boolean;
}

type DeviceTypes = 'ANDROID' | 'IOS' | 'WEB';

export interface FcmRegisterRequest {
  token: string;
  device_type: DeviceTypes | string;
}

export interface NftMining {
  address: string;
  amount: number;
  hps: number;
  end_time: number;
}

export interface NftMiningAddressInfo {
  id: number;
  address: string;
  max: number;
  max_hps: number;
  mining_eggs_max?: number;
  mining_eggs_max_hps?: number;
  mining_eggs_total?: number;
  tokens: NftMiningToken[];
  total: number;
}

export type EggStatus =
  | 'ready_to_hatching'
  | 'hatching'
  | 'hatched'
  | 'cracked'
  | 'taken'
  | 'empty'
  | 'leveling_up'
  | 'recharge_ready';

export interface NftMiningToken {
  active: number;
  count: number;
  hps: number;
  id: string;
  type: string;
  image: string;
  name: string;
  contract: string;
  network: Network;
  status: EggStatus;
  level: number;
  hatching_finish_at: number;
  can_level_up_at: number;
  isUpdate?: boolean;
  url?: string;
  rewards?: GetRewardResponse[];
}

interface AutoWithdrawalCreateRequest {
  address: string;
  min_amount: number;
}

type WithdrawalType = 'regular' | 'browser' | 'farm' | 'nft' | 'pool_nft' | 'pool' | 'pool_mobile';

export interface AutoWithdrawalPublic {
  id: string;
  last_at?: string;
  type: WithdrawalType;
  address: string;
  min_amount: number;
  is_active?: boolean;
}

export interface AutoWithdrawalChangeRequest {
  id: string;
  address?: string;
  min_amount?: number;
  is_active?: boolean;
}

interface AutoWithdrawalConfirmationRequest {
  code: string;
}

export type EthNetwork = 'mainnet' | 'matic' | 'mumbai' | 'dev' | 'test' | 'bsc';
export type Network = 'ethereum' | 'mainnet' | 'matic' | 'mumbai' | 'dev' | 'test';
export type TokenStandard = 'ERC20' | 'ERC721' | 'ERC1155';
export type BaseTransactionResponse = {
  id: string;
  network: EthNetwork;
  contract_address: string;
  token_standard: TokenStandard;
  from_address: string;
  data_to_sign: object;
  status?: number;
  txid?: string;
};

export interface GetRewardResponse {
  contract?: string;
  issued_at?: Date;
  level?: number;
  network: EthNetwork;
  owner?: string;
  reward: Reward;
  token_id?: string;
  tx_id?: string;
  tx_ts?: number;
}

export interface Reward {
  contract: string;
  description?: string;
  image?: string;
  name?: string;
  network: EthNetwork;
  token_id: string;
  token_standard: TokenStandard;
  url?: string;
}

export type EggsRequest = {
  contract: string;
  from_address: string | `0x${string}` | undefined;
  network: Network;
  token_id: string;
};

export type AddrTransProcessRequest = {
  transaction_id: string;
  sign: string;
};

export interface LevelUpTokenInfo {
  contract: string;
  network: EthNetwork;
  token_id: string;
  token_standard: TokenStandard;

  image: string;
  level: number;
  name: string;

  url: string;

  count?: number;
  isUpdate?: boolean | undefined;
  active: number;
  type: string;
}

interface GetPromoRequest {
  lang?: string;
}

interface Landing {
  id: number;
  category: string;
  url: string;
  image: string;
}

interface NftPromoCode {
  code: string;
  created_at: number;
  discount?: number;
  is_deleted?: boolean;
}

type Discreteness = 'months' | 'weeks' | 'days' | 'hours';

interface ApiStatisticsRequest {
  start_date?: string;
  end_date?: string;
  discreteness?: Discreteness;
}

interface CtNftReferralPayments {
  timestamp: number;
  account_id: number;
  promo_code: string;
  amount: number;
}

export type UsersMeEggsResalePaymentsResponse = EggRefPaymentPublic[];

export interface EggRefPaymentPublic {
  amount_btc: number;
  image: string;
  name: string;
  timestamp: number;
  tx_id: string;
}

export interface ContentCategoryPagesRequest {
  categories: PageCategory[];
  language?: string;
  limit?: number;
  offset?: number;
  placing?: string[];
}

export interface ContentCategoryPagesResponse {
  items: Page[];
  total: number;
}

export type CurrenciesResponse = CurrencyBonus[];

export interface CurrencyBonus {
  features?: Feature[];
  img: string;
  min_withdrawal: number;
  name: string;
  symbol: string;
  unit: number;
  url: string;
}

export enum Feature {
  PoolMining = 'pool_mining',
}

export interface Page {
  actions?: PublicPageAction[];
  body: string;
  category?: PageCategory;
  description: string;
  id: number;
  image: string;
  // eslint-disable-next-line
  meta?: { [key: string]: any };
  name: string;
  options?: PageOptions;
  placing: string[];
  published_at: Date;
  tags: string[];
  title: string;
  url: string;
}

export interface PublicPageAction {
  title: string;
  url: string;
}

export interface PageOptions {
  priority?: number;
  throttle?: number;
}

export type PageCategory = 'banners' | 'modals' | 'news';

export interface WithdrawalsGetResponse {
  items: Withdrawal[];
  limit: number;
  offset: number;
  total: number;
}

export type MiningPoolPlansGetResponse = MiningPoolPlan[];

export interface MiningPoolOrdersCreateRequest {
  hps: number;
  month: number;
  utm?: { [key: string]: string };
  currency?: string;
}

export interface MiningPoolOrdersCreateResponse {
  order_id: string;
  url: string;
}

export interface WithdrawalsCreateWithdrawalRequest {
  address: string;
  amount: number;
  captcha?: string;
  type?: WithdrawalType;
}

export interface WithdrawalsCreateWithdrawalResponse {
  address: string;
  amount: number;
  bitcoin_transaction?: string;
  id: string;
  payment_service?: WithdrawalPaymentService;
  request_date: number;
  state: number;
  transaction_id?: string;
  type?: WithdrawalType;
}

export enum WithdrawalPaymentService {
  Bitcoin = 'bitcoin',
  Ncw = 'ncw',
}

export interface CtpayOrderGetRequest {
  order_id: string;
}

export interface CtpayOrderGetResponse {
  account_id?: string;
  amount: number;
  created_at: Date;
  currency: string;
  email?: string;
  expired_at?: Date;
  id: string;
  payment_types: PaymentType[];
  product_description?: { [key: string]: string };
  product_name?: { [key: string]: string };
  status: OrderStatus;
}

export type PaymentType = 'BNB' | 'BTC' | 'BUSD' | 'CARD' | 'CTC' | 'CTC_CARD' | 'ETH' | 'MATIC' | 'SOL' | 'USDT';

export type OrderStatus = 'canceled' | 'completed' | 'created' | 'expired' | 'fraud';

export interface UsersMeSetEmailRequest {
  email: string;
  next_url?: string;
}

export interface NcwGetLastAddressesRequest {
  blockchain: string;
}

export interface NcwGetLastAddressesResponse {
  addresses?: string[];
}

export type UsersMeLinksBalancesResponse = LinkBalance[];

export interface LinkBalance {
  ct?: CT;
  ct_nft?: CT;
  ct_pool?: CT;
  pool_mobile?: CT;
  eggs?: CT;
  farm?: CT;
  link_id: number;
  nft?: CT;
}

export interface CT {
  earnings?: Earning;
  locked?: number;
  total?: number;
}

export interface Earning {
  mined?: number;
  ref?: number;
}

export interface WithdrawalsGetRequest {
  limit?: number;
  offset?: number;
}

export type WalletsGetBalanceResponse = ProjectBalance[];

export interface ProjectBalance {
  accrued: string;
  group: string;
  total: string;
}

export type WalletsPendingBalanceResponse = {
  earliest_payment_at?: number;
  income?: number;
  outgoing?: number;
};

export interface UsersMeChangeCurrencyRequest {
  currency: string;
}

export type UsersMeChangeCurrencyResponse = APIUser | ChangeCurrencyResponse;

export interface APIUser {
  currency?: string;
  date_joined?: number;
  email?: string;
  email_verified?: boolean;
  email_verified_at?: string;
  id: number;
  min_withdrawal_amount?: number;
  ncw_min_withdrawal_amount?: number;
  tags: string[];
}

export interface ChangeCurrencyResponse {
  fernet: string;
  from_currency: string;
  rate: string;
  to_currency: string;
}

export interface UsersMeChangeCurrencyConfirmRequest {
  fernet: string;
}

export interface UsersMeChangeCurrencyConfirmResponse {
  currency?: string;
  date_joined?: number;
  email?: string;
  email_verified?: boolean;
  email_verified_at?: string;
  id: number;
  min_withdrawal_amount?: number;
  ncw_min_withdrawal_amount?: number;
  tags: string[];
}

export interface PlatformAndroidProductsRequest {
  tags?: string[];
}

export type PlatformAndroidProductsResponse = PublicProduct[];

export interface PublicProduct {
  discounts: Discount[];
  duration: Duration;
  key: string;
  settings: { [key: string]: number };
  sku: string;
  tags: string[];
  plan_tag: string;
  offer_tags?: string[];
}

export interface Discount {
  type: string;
  value: number;
}

export interface Duration {
  count: number;
  interval: PlanInterval;
}

export type PlanInterval = 'day' | 'month' | 'year';

export interface PlatformAndroidProductsOffersBuyRequest {
  offer_id: string;
  plan_id: string;
  sku: string;
  utm?: { [key: string]: string };
}

export type PlatformAndroidProductsOffersBuyResponse = string;

export interface PlatformAndroidGooglePlayPurchasesRequest {
  purchases: GooglePlayPurchase[];
}

export interface GooglePlayPurchase {
  data: string;
  sign: string;
}

export type PlatformAndroidGooglePlayPurchasesResponse = undefined[];

export type TimestampResponse = number;

export type WalletsCurrenciesResponse = Currency[];

export interface Currency {
  img: string;
  min_withdrawal: number;
  name: string;
  symbol: string;
  unit: number;
  url: string;
}

export type HistoryDataPoint = [number, number];
export type HistoryResponse = HistoryDataPoint[];

export interface NcwFindAccountRequest {
  value: string;
}

export interface NcwFindAccountResponse {
  email?: string;
  account_id: string;
}

export interface WalletsWithdrawalsCreateRequest {
  address: string;
  amount: number;
  type?: WithdrawalCreateType;
}

/**
 * An enumeration.
 */
export enum WithdrawalCreateType {
  Browser = 'browser',
  Farm = 'farm',
  Hsh = 'hsh',
  Nft = 'nft',
  Pool = 'pool',
  PoolMobile = 'pool_mobile',
  PoolNft = 'pool_nft',
  Regular = 'regular',
}

export interface WalletsWithdrawalsCreateResponse {
  account_id: string;
  amount: string;
  client_ip?: string;
  created_at: Date;
  currency: string;
  fee?: number;
  from_wallet_ids: string[];
  id: string;
  meta?: { [key: string]: never };
  status: WithdrawalStatus;
  tags?: string[];
  to_address: string;
  transactions_ids?: string[];
  txid?: string;
  updated_at: Date;
}

export enum WithdrawalStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  Confirmed = 'confirmed',
  Created = 'created',
  Error = 'error',
  Pending = 'pending',
  PendingFunds = 'pending_funds',
  Rejected = 'rejected',
  RejectedNull = 'rejected_null',
  Sent = 'sent',
}

export interface WalletsWithdrawalsHistoryRequest {
  limit?: number;
  offset?: number;
  status?: WithdrawalStatus[];
}

export interface WalletsWithdrawalsHistoryResponse {
  items: Withdrawal[];
  limit?: number;
  offset?: number;
  total: number;
}

export interface Withdrawal {
  account_id: string;
  amount: string;
  client_ip?: string;
  request_date?: string;
  service?: WithdrawalPaymentService;
  created_at: Date;
  currency: string;
  fee?: number;
  from_wallet_ids: string[];
  id: string;
  meta: WalletsWithdrawalsLastAddressesResponseUnion;
  status: WithdrawalStatus;
  tags?: string[];
  to_address: string;
  transactions_ids?: string[];
  txid?: string;
  updated_at: Date;
}

export type WalletsWithdrawalsLastAddressesResponse = Array<WalletsWithdrawalsLastAddressesResponseUnion>;

export type WalletsWithdrawalsLastAddressesResponseUnion = BtcWithdrawalMeta | NcwWithdrawalMeta;

export interface BtcWithdrawalMeta {
  address: string;
}

export interface NcwWithdrawalMeta {
  account_id: string;
  email?: string;
}

export interface WalletsWithdrawalsConfirmRequest {
  code: string;
}

export interface WalletsWithdrawalsConfirmResponse {
  account_id: string;
  amount: number;
  client_ip?: string;
  created_at: Date;
  currency: string;
  fee?: number;
  from_wallet_ids: string[];
  id: string;
  meta?: Meta;
  service: string;
  status: WithdrawalStatus;
  tags?: string[];
  to_address: string;
  txid?: string;
  updated_at: Date;
}

export interface Meta {
  address?: string;
  account_id?: string;
  email?: string;
}

export interface WalletsWithdrawalsChangeRequest {
  account_id: string;
  amount: number;
  batch: boolean;
  client_ip?: string;
  comment?: string;
  created_at: Date;
  currency: string;
  fee?: number;
  from_wallet_ids: string[];
  id: string;
  meta?: WalletsWithdrawalsLastAddressesResponseUnion;
  reviewed: boolean;
  service: WithdrawalPaymentService;
  status: WithdrawalStatus;
  tags?: string[];
  to_address: string;
  transactions_ids?: string[];
  transfer_options?: { [key: string]: unknown };
  txid?: string;
  updated_at: Date;
}

export interface WalletsPendingPaymentRequest {
  amount: number;
  created_at: Date;
  id: string;
  // eslint-disable-next-line
  meta?: { [key: string]: any };
  purchase_at: Date;
  type: string;
}

export interface WalletsWithdrawalsCancelRequest {
  withdrawal_id: string;
}

export interface WalletsCheckAddressRequest {
  address: string;
}

export type WalletsCheckAddressResponse = BtcAddressResponse | NcwAddressResponse;

type WalletsCheckAddressType = 'btc' | 'ncw';

export interface BtcAddressResponse {
  type: WalletsCheckAddressType;
}

export interface NcwAddressResponse {
  account_id: string;
  email?: string;
  type: WalletsCheckAddressType;
}

export interface RatingRequest {
  email?: string;
  message?: string;
  rating?: number;
  summary?: string;
  project_id?: string;
}

export interface RatingResponse {
  issue_id?: string;
}

export interface RateInfoResponse {
  allow?: boolean;
}

export interface RateSendRequest {
  message?: string;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: { [key: string]: any };
  project_id?: string;
  rating?: number;
  summary?: string;
}

export interface RateSendResponse {
  issue_id?: string;
}
export interface RateManagementResponse {
  rating?: RatingInfo;
  review?: ReviewInfo;
}

export interface RatingInfo {
  allow?: boolean;
  icons?: RatingIcons;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: any;
  rating?: boolean;
  review?: boolean;
}

/**
 * An enumeration.
 */
export enum RatingIcons {
  Emoji = 'emoji',
  Stars = 'stars',
}

export interface ReviewInfo {
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: { [key: string]: any };
  type: ReviewType;
  id: string;
}

/**
 * An enumeration.
 */
export enum ReviewType {
  Modal = 'modal',
  Page = 'page',
}
export interface RateSendReviewRequest {
  action: ReviewAction;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: { [key: string]: any };
  project_id?: string;
  review_id?: string;
}

/**
 * An enumeration.
 */
export enum ReviewAction {
  Click = 'click',
  Close = 'close',
}

export interface RateSendRequest {
  message?: string;
  project_id?: string;
  rating?: number;
  summary?: string;
  email?: string;
  action?: ReviewActionsType;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  meta?: { [key: string]: any };
}

export interface RateSendResponse {
  issue_id?: string;
}
export type RatingIconsType = 'emoji' | 'stars';

export type ReviewActionsType = 'close' | 'click';
export interface PlatformIosAppstorePurchasesRequest {
  meta?: AppStorePurchaseMetaOverride[];
  receipt: string;
  restore?: boolean;
}

export interface AppStorePurchaseMetaOverride {
  meta?: { [key: string]: never };
  transaction_id?: string;
}

export interface PlatformIosAppstorePurchasesResponse {
  result: AppStorePurchase[];
}

export interface AppStorePurchase {
  account_purchase: AccountPurchase;
  transaction_id: string;
}

export interface AccountPurchase {
  account_id: string;
  account_ids?: string[];
  currency?: string;
  end_time?: Date;
  from_trial?: boolean;
  id: string;
  is_active: boolean;
  is_test?: boolean;
  is_trial?: boolean;
  iso2?: string;
  last_payment_time?: Date;
  meta?: { [key: string]: never };
  order_id?: string;
  payment_system_id?: string;
  payment_system_order_id?: string;
  payments_count?: number;
  payments_total?: number;
  price?: number;
  price_info?: PriceInfo;
  product_currency?: string;
  product_id: string;
  product_price?: number;
  quantity?: number;
  refunded?: boolean;
  start_time?: Date;
}

export interface PriceInfo {
  currency: string;
  net: number;
  payed: number;
  taxed: number;
}

export interface PlatformIosAppStoreVerifyReceiptParams {
  receipt: string;
}

export interface PlatformIosAppstoreVerifyReceiptResponse {
  enviroment: string;
  receipt: { [key: string]: unknown };
  latest_receipt_info: ReceiptInfo[];
  latest_receipt: string;
  pending_renewal_info: PendingRenewalInfo[];
  status: number;
}

export interface PendingRenewalInfo {
  expiration_intent?: string;
  auto_renew_product_id: string;
  is_in_billing_retry_period?: string;
  product_id: string;
  original_transaction_id: string;
  auto_renew_status: string;
}

export interface ReceiptInfo {
  quantity: string;
  product_id: string;
  transaction_id: string;
  original_transaction_id: string;
  purchase_date: string;
  purchase_date_ms: string;
  purchase_date_pst: string;
  original_purchase_date: string;
  original_purchase_date_ms: string;
  original_purchase_date_pst: string;
  expires_date: string;
  expires_date_ms: string;
  expires_date_pst: string;
  web_order_line_item_id: string;
  is_trial_period: string;
  is_in_intro_offer_period: string;
  in_app_ownership_type: string;
  subscription_group_identifier: string;
}

export interface WalletsDailyTransactionsHistoryRequest {
  currencies: string[];
  limit?: number;
  offset?: number;
}

export interface WalletsDailyTransactionsHistoryResponse {
  items: DailyTransactionsHistory[];
}

export interface DailyTransactionsHistory {
  amount: number;
  count: number;
  currency: string;
  date: string;
  type: string;
}

export type Contests2024ParticipantGetResponse = PublicParticipant | null;

export interface PublicParticipant {
  account_id: string;
  can_open_chest?: boolean;
  state: PublicState;
}

export interface PublicState {
  candy_coins?: number;
  snowflake_coins?: number;
  chest_opened?: number;
  hsh_exchanged?: number;
}

export interface JsonRpcApi {
  'ping': () => void;
  'timestamp': () => TimestampResponse;
  'auth': (request: AuthRequest) => Account;
  'content.pages': () => ContentCategoryPagesResponse;
  'rate.management': () => RateManagementResponse;
  'rate.send': (request: RateSendRequest) => RateSendResponse;
  'rate.send_review': (request: RateSendReviewRequest) => void;
  'content.category_pages': (request: ContentCategoryPagesRequest) => ContentCategoryPagesResponse;
  'currencies.rate': () => void;
  'currencies': () => CurrenciesResponse;
  'users.me.links.balances': () => UsersMeLinksBalancesResponse;
  'wallets.balance': () => WalletsGetBalanceResponse;
  'wallets.pending.balance': () => WalletsPendingBalanceResponse;
  'wallets.currencies': () => WalletsCurrenciesResponse;
  'wallets.withdrawals.create': (request: WalletsWithdrawalsCreateRequest) => WalletsWithdrawalsCreateResponse;
  'wallets.withdrawals.history': (request: WalletsWithdrawalsHistoryRequest) => WalletsWithdrawalsHistoryResponse;
  'wallets.withdrawals.cancel': (request: WalletsWithdrawalsCancelRequest) => WalletsWithdrawalsCreateResponse;
  'ncw.find_account': (request: NcwFindAccountRequest) => NcwFindAccountResponse;
  'wallets.withdrawals.last_addresses': () => WalletsWithdrawalsLastAddressesResponse;
  'wallets.withdrawals.confirm': (request: WalletsWithdrawalsConfirmRequest) => WalletsWithdrawalsConfirmResponse;
  'users.me.change_currency': (request: UsersMeChangeCurrencyRequest) => UsersMeChangeCurrencyResponse;
  'users.me.change_currency_confirm': (
    request: UsersMeChangeCurrencyConfirmRequest
  ) => UsersMeChangeCurrencyConfirmResponse;
  'users.me.eggs.resale_payments': () => UsersMeEggsResalePaymentsResponse;
  'wallets.check_address': (request: WalletsCheckAddressRequest) => WalletsCheckAddressResponse;

  'promo.get_pool_landings': (request: GetPromoRequest) => Landing[];

  'auto_withdrawals.create': (request: AutoWithdrawalCreateRequest) => void;
  'auto_withdrawals.list': () => AutoWithdrawalPublic[];
  'auto_withdrawals.change': (request: AutoWithdrawalChangeRequest) => AutoWithdrawalPublic | null;
  'auto_withdrawals.confirm': (request: AutoWithdrawalConfirmationRequest) => AutoWithdrawalPublic;

  'users.me.links': () => void;
  'withdrawals.get': (request: WithdrawalsGetRequest) => WithdrawalsGetResponse;
  'currencies.history': () => HistoryResponse;
  'mining.pool.list': () => void;
  'mining.nft.start': () => void;
  'mining.nft.stop': () => void;
  'rate.info': () => RateInfoResponse;
  'promo.get_landings': (request: GetPromoRequest) => Landing[];
  'farm.promo-codes': () => {
    code: string;
    sold_count?: number;
    active_count?: number;
    alltime_income?: number;
  }[];

  'nft.get_payments_statistics': (request: ApiStatisticsRequest) => CtNftReferralPayments[];

  'nft.promo-codes': () => NftPromoCode[];
  'mining.nft.list': () => NftMining[];
  'mining.nft.info': () => NftMiningAddressInfo[];
  'mining.nft.eggs.leveling_up': (request: EggsRequest) => BaseTransactionResponse;
  'mining.nft.eggs.level_up': (request: AddrTransProcessRequest) => BaseTransactionResponse;
  'mining.nft.eggs.level_up.tokens': () => LevelUpTokenInfo[];
  'mining.nft.eggs.rewards': (request: { addresses: string[] }) => GetRewardResponse[];
  'mining.pool.plans.get': () => MiningPoolPlansGetResponse;
  'mining.pool.orders.create': (request: MiningPoolOrdersCreateRequest) => MiningPoolOrdersCreateResponse;
  'mining.pool.miners': () => TMining[];
  'mining.pool.start': () => TMining[];
  'mining.pool.stop': () => void;
  'user.auth': () => void;
  'users.me.delete': () => void;
  'users.me': () => void;
  'users.me.set_email': (request: UsersMeSetEmailRequest) => void;
  'fcm.register': (request: FcmRegisterRequest) => void;

  'withdrawals.create_withdrawal': (request: WithdrawalsCreateWithdrawalRequest) => WithdrawalsCreateWithdrawalResponse;
  'withdrawals.get_addresses': () => void;
  'withdrawals.reconfirm_withdrawal': (request: { id: string }) => Withdrawal;
  'withdrawals.delete_withdrawal': (request: { id: string }) => Withdrawal;
  'ncw.get_last_addresses': (request: NcwGetLastAddressesRequest) => NcwGetLastAddressesResponse;

  'ctpay.order.get': (request: CtpayOrderGetRequest) => CtpayOrderGetResponse;

  'platform.android.products': (request: PlatformAndroidProductsRequest) => PlatformAndroidProductsResponse;
  'platform.ios.products': (request: PlatformAndroidProductsRequest) => PlatformAndroidProductsResponse;
  'platform.android.products.offers.buy': (
    request: PlatformAndroidProductsOffersBuyRequest
  ) => PlatformAndroidProductsOffersBuyResponse;
  'platform.android.google_play.purchases': (
    request: PlatformAndroidGooglePlayPurchasesRequest
  ) => PlatformAndroidGooglePlayPurchasesResponse;
  'platform.ios.appstore.purchases': (
    request: PlatformIosAppstorePurchasesRequest | AppStorePurchaseMetaOverride
  ) => PlatformIosAppstorePurchasesResponse;
  'platform.ios.appstore.verify_receipt': (
    request: PlatformIosAppStoreVerifyReceiptParams
  ) => PlatformIosAppstoreVerifyReceiptResponse;
  'rating': (request: RatingRequest) => RatingResponse;
  'contests.xmas_24.participant.get': () => Contests2024ParticipantGetResponse;
  'wallets.daily_transactions_history': (
    request: WalletsDailyTransactionsHistoryRequest
  ) => WalletsDailyTransactionsHistoryResponse;
}

export interface JsonRpcApiMethods {
  'app.reload': () => void;
  'user': (message: Account) => void;
  'mining.pool.miner': (message: PoolMinerMessage) => void;
  'mining.pool.mining': (message: PoolMiningMessage) => void;
  'mining.nft': (message: NftMining[]) => void;
  'mining.nft.info': (message: NftMiningAddressInfo[]) => void;
  'wallets.withdrawals.change': (message: WalletsWithdrawalsChangeRequest) => void;
  'wallets.pending_payment': (message: WalletsPendingPaymentRequest) => void;
}
