import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import s from './mining-duration.module.scss';
import { Typography } from '@/shared/ui/typography';
import { Button } from '@/shared/ui/button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { MiningPoolPlan } from '@/shared/lib/models';
import { Icon } from '@/shared/ui/icons';
import { setMiningDuration, useCurrentPlan, useMiningDurations } from '@/page/cart/store';
import { BONUS_TAG, BONUS_TAG_20 } from '@/shared/lib/constants/bonus-tag';
import { formatHashrate } from '../hashrate';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';
import { useCallback } from 'react';
import { useBonusBanner } from '@/page/cart/hooks/use-bonus-banner';
import { HshAlert } from '@/page/cart/ui/v1/bonus-alerts/hsh-alert';
import { BonusNames, usePlansBonusName } from '@/shared/hooks/use-plans-bonus-name';
import { HpsAlert } from '@/page/cart/ui/v1/bonus-alerts/hps-alert';

type MiningDurationProps = {
  getFormatedMiningPrice: (plan: MiningPoolPlan | undefined) => string;
  userCurrency: string;
};

export const MiningDuration = ({ getFormatedMiningPrice, userCurrency }: MiningDurationProps) => {
  const activeMonth = useSelector((state: RootState) => state.cart.miningDuration);
  const plans = useSelector((state: RootState) => state.cart.data?.plans);
  const miningDurations = useSelector(useMiningDurations);
  const currentPlan = useSelector(useCurrentPlan);
  const plansBonusName = usePlansBonusName();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { monthsBonus, isShowBanner } = useBonusBanner({
    plans,
    sliceTo: miningDurations?.length,
    currentPlanMonth: currentPlan?.month,
  });

  const handleButtonClick = useCallback(
    (month: number) => () => dispatch(setMiningDuration({ miningDuration: month })),
    [dispatch]
  );

  return (
    <div className={s.root}>
      <div className={s.top}>
        <Typography variant="h5" className={s.title}>
          <Icon iconName="clock" width={20} height={20} />
          {t('choose mining duration')}
        </Typography>
        <p className={s.top__text}>{t('Buy_scr.Price_month')}</p>
      </div>

      <div className={s.buttonsContainer}>
        {miningDurations &&
          miningDurations.map((month, i) => {
            const discount = plans && (plans.find((i) => i.month === month) as MiningPoolPlan)?.discount;
            const plan = plans && plans.find((i) => i.hps === currentPlan?.hps && i.month === month);
            const formattedPrice = getFormatedMiningPrice(plan);
            const pickedPlan = plan ? activeMonth === month : undefined;
            const isDoubleHpsIcon = !plan?.hsh_reward && plan?.tags?.includes(BONUS_TAG_20) && plan?.hps_bonus;

            if (!plan) {
              return <></>;
            }

            return (
              <Button
                borderRadius={false}
                fullWidth
                key={month}
                variant="outline"
                onClick={handleButtonClick(month)}
                color="black"
                className={cn(s.button, activeMonth !== month && s.inactive)}
                style={{ marginBottom: !pickedPlan && i === miningDurations.length - 1 ? '10px' : '0' }}
              >
                <div className={s.button__container}>
                  <span className={s.button__text}>
                    {month} {t('mo.')}
                  </span>
                  <div
                    className={cn(
                      s.button__container_discount,
                      isDoubleHpsIcon && s[`button__container_discount--background`]
                    )}
                  >
                    {discount && discount > 1 ? <span className={s.button__discount}>-{discount}%</span> : null}
                    {plan?.tags?.includes(BONUS_TAG_20) && plan?.hsh_reward && (
                      <Icon iconName="gift-yellow" width={24} height={24} className={s.button__icon} />
                    )}
                    {isDoubleHpsIcon && (
                      <Icon iconName="white-gift-x2" width={15} height={16} className={s.button__icon} />
                    )}
                  </div>
                  <span className={s.button__plan}>
                    {formattedPrice && getLocalAmount(formattedPrice)} {userCurrency}
                  </span>

                  {!pickedPlan && i === miningDurations.length - 1 && (
                    <span className={cn(s.button__tag, plan?.tags?.includes(BONUS_TAG_20) && s.button__tagPurple)}>
                      {t('Buy_scr.Profitable')}
                    </span>
                  )}
                </div>
                {activeMonth === month &&
                  (plan?.hsh_reward || plan?.hps_bonus) &&
                  [BONUS_TAG_20, BONUS_TAG].some((tag) => plan?.tags?.includes(tag)) && (
                    <div className={s.button__bonus}>
                      <Icon iconName="gift" width={20} height={20} />
                      {plan?.hsh_reward ? (
                        <>
                          {t('BB_Hsh.Bonus')}:
                          <span className={s.button__right}>
                            +{getLocalAmount(plan?.hsh_reward)} HSH {plan?.tags?.includes(BONUS_TAG_20) && '(x2)'}
                          </span>
                        </>
                      ) : (
                        <>
                          {t('hps_increment')}:
                          <span className={s.button__right}>
                            +{formatHashrate(plan?.hps_bonus)} {plan?.tags?.includes(BONUS_TAG_20) && '(x2)'}
                          </span>
                        </>
                      )}
                    </div>
                  )}
              </Button>
            );
          })}
      </div>
      {plansBonusName === BonusNames.HSH && isShowBanner ? <HshAlert months={monthsBonus} /> : null}
      {plansBonusName === BonusNames.HPS && isShowBanner ? <HpsAlert months={monthsBonus} /> : null}
    </div>
  );
};
