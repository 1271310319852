import { MiningPoolPlan, MiningPoolPlanGoogle } from '@/shared/lib/models';

export const getRegularPrice = (plan: MiningPoolPlan) => {
  return (parseFloat(plan?.total_price) / (100 - plan?.discount)) * 100;
};

function getNumericStr(str: string) {
  // Replace commas with periods if they are used as thousand separators
  if (str.includes(',')) {
    // If there's both a comma and a period, assume comma is the thousand separator
    if (str.includes('.')) {
      str = str.replace(/,/g, '');
    } else {
      str = str.replace(/,/g, '.');
    }
  }
  return str.replace(/[^\d.]/g, '');
}

export const getGoogleRegularPrice = (plan: MiningPoolPlanGoogle) => {
  const discount = plan?.discount / 100;
  const numericStr = getNumericStr(plan?.month_price);
  const currencyPrefix = plan?.month_price?.replace(/[0-9,.]/g, '').trim();
  const regularPrice = parseFloat(numericStr) / (1 - discount);
  return `${regularPrice?.toFixed(2)} ${currencyPrefix}`;
};

export const getCurrencyFormat = (number: string, lang: string, currency: string) => {
  return new Intl.NumberFormat(lang, { style: 'currency', currency: currency }).format(Number(number));
};

export const getMonthPrice = (plan: MiningPoolPlan) => {
  const numericStr = getNumericStr(plan?.total_price);
  return (parseFloat(numericStr) / plan?.month)?.toFixed(2);
};

export const getGoogleMonthPrice = (plan: MiningPoolPlanGoogle) => {
  const numericStr = getNumericStr(plan?.month_price);
  const monthPrice = (parseFloat(numericStr) / plan?.month)?.toFixed(2);
  return `${monthPrice} ${plan?.currentCurrency}`;
};

export const formatPrice = (price: number) => {
  return price?.toFixed(8);
};
export const formatPriceUsd = (price: number) => {
  return price?.toFixed(2);
};
