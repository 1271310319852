import styles from '@/page/home/ui/balance-info/balance.module.scss';
import theme from '@/page/home/ui/balance-info/theme.module.scss';
import { CurrentCurrencyRate } from '@/page/home/ui/btc-rate/current-currency-rate';
import { TutorialProps } from '@/shared/ui/tutorial/tutorial';
import { useBalanceInfo } from '@/page/home/hooks/use-balance-info';
import { ShuffleMachine } from '@/features/shuffleMachine/shuffleMachine';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

type BalanceInfoProps = {
  withoutRate?: boolean;
  tutorialPosition?: TutorialProps['position'];
  tutorialArrowPosition?: TutorialProps['arrow'];
};

export const BalanceInfo = ({ withoutRate = false }: BalanceInfoProps) => {
  const { formattedBalance, usdBalance, currentCurrencyObj, userCurrency } = useBalanceInfo();

  return (
    <div className={styles.balance}>
      <div className={styles.balance__block}>
        <div className={styles.balance__btc}>
          <img src={currentCurrencyObj?.img} alt="ico" width={32} height={32} className={styles.balance__icon} />
          <div className={styles.balance__reel}>
            <ShuffleMachine text={getLocalAmount(formattedBalance)} extendedClasses={theme} />
          </div>
          <div>
            <span>{userCurrency}</span>
          </div>
        </div>
        <div className={styles.balance__usd}>
          <strong>{getLocalAmount(usdBalance, 'usd')}</strong>
        </div>
      </div>
      {!withoutRate && (
        <div className={styles.balance__rate}>
          <CurrentCurrencyRate userCurrency={userCurrency} currency={currentCurrencyObj} />
        </div>
      )}
    </div>
  );
};
