import { useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { useMakeOrderMutation } from '@/page/cart/ui/v1/make-order-button/use-make-order-mutation';
import { useCurrentPlanPriceInfo } from '@/page/cart/hooks';
import { getLocalAmount } from '@/shared/lib/utils/getFormattedPrice';

export const MakeOrderButton = () => {
  const { t } = useTranslation();
  const { handleClick, status } = useMakeOrderMutation();
  const isLoading = status === 'loading';
  const { userCurrency, formattedPrice } = useCurrentPlanPriceInfo();

  return (
    <Button fullWidth color="green" onClick={handleClick} disabled={isLoading} loading={isLoading}>
      {t('BUY')}
      {' / '}
      {getLocalAmount(formattedPrice)} {userCurrency}
    </Button>
  );
};
