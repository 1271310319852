import { useSelector } from 'react-redux';
import {
  STORAGE_TUTORIAL,
  STORAGE_TUTORIAL_CURRENCY,
  STORAGE_TUTORIAL_SLIDER,
  STORAGE_TUTORIAL_ONBOARDING,
} from '../constants/storage-key';
import { useCallback } from 'react';
import { RootState } from '@/shared/store/types';

export const useTutorialStorage = () => {
  const { id: userId } = useSelector((store: RootState) => store.user);
  const onboardingData = JSON.parse(localStorage.getItem(`${STORAGE_TUTORIAL}${STORAGE_TUTORIAL_ONBOARDING}`) || '[]');
  const tutorialData = JSON.parse(localStorage.getItem(`${STORAGE_TUTORIAL}${STORAGE_TUTORIAL_SLIDER}`) || '[]');
  const currencyData = JSON.parse(localStorage.getItem(`${STORAGE_TUTORIAL}${STORAGE_TUTORIAL_CURRENCY}`) || '[]');

  const markOnboardingSeen = () => {
    const updatedData = [...onboardingData, userId];
    localStorage.setItem(`${STORAGE_TUTORIAL}${STORAGE_TUTORIAL_ONBOARDING}`, JSON.stringify(updatedData));
  };

  const markTutorialSeen = () => {
    if (!tutorialData.includes(userId)) {
      const updatedData = [...tutorialData, userId];
      localStorage.setItem(`${STORAGE_TUTORIAL}${STORAGE_TUTORIAL_SLIDER}`, JSON.stringify(updatedData));
    }
  };

  const markCurrencySeen = () => {
    if (!onboardingData.includes(userId)) {
      const updatedData = [...currencyData, userId];
      localStorage.setItem(`${STORAGE_TUTORIAL}${STORAGE_TUTORIAL_CURRENCY}`, JSON.stringify(updatedData));
    }
  };

  const clearTutorialSeen = () => {
    localStorage.setItem(`${STORAGE_TUTORIAL}${STORAGE_TUTORIAL_SLIDER}`, '[]');
  };

  const isOnboardingSeen = useCallback(
    () =>
      JSON.parse(localStorage.getItem(`${STORAGE_TUTORIAL}${STORAGE_TUTORIAL_ONBOARDING}`) || '[]').includes(userId),
    [userId]
  );
  const isTutorialSeen = useCallback(
    () => JSON.parse(localStorage.getItem(`${STORAGE_TUTORIAL}${STORAGE_TUTORIAL_SLIDER}`) || '[]').includes(userId),
    [userId]
  );
  const isCurrencySeen = useCallback(
    () => JSON.parse(localStorage.getItem(`${STORAGE_TUTORIAL}${STORAGE_TUTORIAL_CURRENCY}`) || '[]').includes(userId),
    [userId]
  );

  return {
    markOnboardingSeen,
    markTutorialSeen,
    markCurrencySeen,
    isOnboardingSeen,
    isTutorialSeen,
    isCurrencySeen,
    clearTutorialSeen,
  };
};
