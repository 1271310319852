import styles from './limit-alert.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from '@/shared/ui/button';
import { Icon as IconName } from '@/shared/ui/icons';
import ModalLayout from '@/shared/ui/modal/modal';
import React from 'react';

type TLimitAlertModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const LimitAlertModal = ({ isOpen, onClose }: TLimitAlertModalProps) => {
  const { t } = useTranslation();

  const { Header, Footer, Content, Icon, Title, Description } = ModalLayout;

  return (
    <ModalLayout onClose={onClose} isOpen={isOpen} id="limit-alert">
      <Header hiddenDesktop>
        <Title size="xs">{t('Token_Limit_Exceeded')}</Title>
      </Header>
      <Content>
        <Icon>
          <IconName iconName="alert" width={70} height={70} />
        </Icon>
        <Title>{t('Token_Limit_Exceeded')}</Title>
        <Description>
          <Trans i18nKey="Please_note_that_you_can_only_use_100_tokens" components={{ b: <strong /> }} />
        </Description>
      </Content>
      <Footer gray>
        <Button className={styles.button} color="white-blue" variant="filled" onClick={onClose}>
          <IconName iconName="checked" width="17" height="16" />
          {t('OK, GOT IT')}
        </Button>
      </Footer>
    </ModalLayout>
  );
};
