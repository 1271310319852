import s from './shuffleMachine.module.scss';
import Reel from 'react-reel';

interface ShuffleMachineProps {
  text: string;
  extendedClasses: CSSModuleClasses;
  duration?: number;
}

export const ShuffleMachine = ({ text, extendedClasses, duration = 800 }: ShuffleMachineProps) => (
  <Reel text={text} theme={{ ...s, ...extendedClasses }} duration={duration} />
);
