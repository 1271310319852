import { useEffect, useState } from 'react';
import { rpc } from '@/shared/lib/backend/Rpc';

type ChristmasSnowflakes = {
  snowflakesCount: undefined | null | number;
};

export const useChristmasSnowflakes = (): ChristmasSnowflakes => {
  const [state, setState] = useState<ChristmasSnowflakes>({
    snowflakesCount: undefined,
  });

  useEffect(() => {
    const fetchSnowflakesCollected = async () => {
      try {
        const response = await rpc.transmit('contests.xmas_24.participant.get', {});
        const snowflakesCount = response?.state.snowflake_coins;
        setState({ snowflakesCount });
      } catch {
        setState({ snowflakesCount: null });
      }
    };

    fetchSnowflakesCollected();
  }, []);

  return state;
};
