import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/shared/store/types';
import { useModalSet } from '@/shared/lib/context/modal/useModalSet';
import { makeOrderThunk } from '@/page/cart/store';

export const useMakeOrderMutation = () => {
  const dispatch: AppDispatch = useDispatch();
  const status = useSelector((store: RootState) => store.cart.status);
  const { openModal } = useModalSet();

  const handleClick = async () => {
    try {
      const userAgent = navigator?.userAgent;
      const isApple = /iPhone|iPad|iPod/i.test(userAgent);
      const paymentUrl = await dispatch(makeOrderThunk({})).unwrap();

      if (isApple) {
        openModal('MODAL_GO_TO_PAYMENT', { paymentUrl, isServices: true });
        return;
      }

      window.open(paymentUrl, '_blank');
    } catch (error) {
      openModal('MODAL_ERROR', { isServices: true });
    }
  };

  return {
    handleClick,
    status,
  };
};
