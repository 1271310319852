import { useTranslation } from 'react-i18next';
import ModalLayout from '@/shared/ui/modal/modal';
import styles from './add-pool-miner-modal.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import promoImage from './image/promo-image.png';
import { useNavigate } from 'react-router-dom';
import { CART_PATH } from '@/shared/lib/utils/links';
import { useCallback } from 'react';
import { LAST_SHOWN_DATE } from '@/shared/lib/constants/storage-key';

export const AddPoolMinerModal = ({ onClose, isOpen }: { onClose: (e: boolean) => void; isOpen: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const storeRecording = useCallback(() => {
    const currentDate = new Date();
    localStorage.setItem(LAST_SHOWN_DATE, currentDate.toISOString());
  }, []);

  return (
    <ModalLayout
      iconClose
      onClose={() => {
        storeRecording();
        onClose(false);
      }}
      isOpen={isOpen}
      id="add-pool-miner"
    >
      <div
        className={styles.root}
        onClick={() => {
          storeRecording();
          onClose(false);
        }}
      >
        <div
          className={styles.block}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span
            className={styles.close}
            onClick={() => {
              storeRecording();
              onClose(false);
            }}
          >
            <Icon iconName="close" width="20" height="20" />
          </span>
          <div className={styles.top}>
            <img src={promoImage} alt="" />
          </div>
          <div className={styles.mid}>
            <h6 className={styles.title}>{t('First_modal.Title')}</h6>
            <p className={styles.text}>{t('First_modal.Description')}</p>
          </div>
        </div>
        <div className={styles.footer}>
          <Button
            color="blue"
            fullWidth
            onClick={() => {
              storeRecording();
              navigate(CART_PATH);
            }}
          >
            <Icon iconName="plus" width="20" height="20" />
            {t('ADD POOL MINER')}
          </Button>
        </div>
      </div>
    </ModalLayout>
  );
};
