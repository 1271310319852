import { useSelector } from 'react-redux';
import { RootState } from '@/shared/store/types';
import { useMemo } from 'react';

export enum BonusNames {
  HSH = 'HSH',
  HPS = 'HPS',
}
export const usePlansBonusName = () => {
  const plansGoogleOrIos = useSelector((state: RootState) => state.googleCart.googleData?.plans);
  const plansCrypto = useSelector((state: RootState) => state.cart.data?.plans);

  const isHshBonus = useMemo(
    () => plansGoogleOrIos?.some((plan) => plan?.hsh_reward) || plansCrypto?.some((plan) => plan?.hsh_reward),
    [plansGoogleOrIos, plansCrypto]
  );

  const isHpsBonus = useMemo(
    () => plansGoogleOrIos?.some((plan) => plan?.hps_bonus) || plansCrypto?.some((plan) => plan?.hps_bonus),
    [plansGoogleOrIos, plansCrypto]
  );

  if (isHshBonus) return BonusNames.HSH;
  if (isHpsBonus) return BonusNames.HPS;

  return null;
};
