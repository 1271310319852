import { Trans, useTranslation } from 'react-i18next';
import ModalLayout from '@/shared/ui/modal/modal';
import styles from './modal-change-currency-confirm.module.scss';
import { Icon } from '@/shared/ui/icons';
import { Button } from '@/shared/ui/button';
import { Currencies, CurrencyIcon } from '@/shared/ui/currency-icon';
import { ChangeCurrencyResponse } from '@/shared/lib/backend/JsonRpcApi';
import { rpc } from '@/shared/lib/backend/Rpc';
import { userAction } from '@/shared/store/user/user.slice';
import { AppDispatch } from '@/shared/store/types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toaster } from '@/shared/ui/custom-toast/toaster';
import React, { useState } from 'react';
import { initUserThunk } from '@/shared/store/rpc/rpc.slice';
import { CircleProgress } from '@/shared/ui/circle-progress/circle-progress';

interface ModalConfirmProps {
  onClose: (e: boolean) => void;
  isOpen: boolean;
  activeCurrency: Currencies;
  conversionData: ChangeCurrencyResponse | null;
  currencyBalance: string;
}

export const ModalChangeCurrencyConfirm = ({
  onClose,
  isOpen,
  activeCurrency,
  conversionData,
  currencyBalance,
}: ModalConfirmProps) => {
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const confirmCurrencyChange = () => {
    if (conversionData?.fernet) {
      setDisabled(true);
      rpc
        .transmit('users.me.change_currency_confirm', { fernet: conversionData?.fernet })
        .then((response) => {
          dispatch(userAction.checkUserNew(response));
          dispatch(initUserThunk());
          onClose(false);
          setDisabled(false);
          toaster.success(t('balance.currency.changed'));
          navigate(-1);
        })
        .catch(() => {
          setDisabled(false);
        });
    }
  };
  return (
    <ModalLayout iconClose onClose={() => onClose(false)} isOpen={isOpen} id="rate-us">
      <div className={styles.root} onClick={() => onClose(false)}>
        <div
          className={styles.block}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <span className={styles.icon_circle}>
            <CircleProgress variant="dot" width={20} duration={60000} activeCurrency={activeCurrency} />
          </span>
          <span className={styles.close} onClick={() => onClose(false)}>
            <Icon iconName="close" width="20" height="20" />
          </span>
          <CurrencyIcon currency={activeCurrency} width={80} height={80} className={styles.icon} />
          <h6 className={styles.title}>{t('Balance_currency.Conversion.Confirm.Title')}</h6>
          <p className={styles.text}>
            <Trans
              i18nKey={t('Balance_currency.Conversion.Confirm.Description')}
              components={{ b: <strong />, b2: <b className={styles.hours} /> }}
              values={{ currency: activeCurrency, rate: currencyBalance }}
            />
          </p>
          <div className={styles.footer}>
            <Button color="blue" fullWidth onClick={confirmCurrencyChange} disabled={disabled}>
              <Icon iconName="checked" width="17" height="16" />
              {t('confirm')}
            </Button>
            <Button fullWidth variant="outline" color="blue" onClick={() => onClose(false)}>
              <Icon iconName="cancel" width={20} height={20} />
              {t('CANCEL')}
            </Button>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};
