export const CHRISTMAS_INFO_URL = 'https://cryptobrowser.site/promo/festive-season-24/';
export const CHRISTMAS_BANNER_ID = '_ChristmasBannerClosedAt';
export const CHRISTMAS_END_DATE = '2025-01-30T00:00:00.000Z';

export const getIsChristmas = (): boolean => {
  const christmasClosedAt = localStorage.getItem(CHRISTMAS_BANNER_ID);

  if (christmasClosedAt) return false;

  const currentDate = new Date();
  const christmasDate = new Date(CHRISTMAS_END_DATE);
  const isChristmas = currentDate < christmasDate;

  if (!isChristmas) {
    console.warn('Christmas is over, please remove the Christmas banner', 'color: red; font-size: 20px;');
  }

  return isChristmas;
};
