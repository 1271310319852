import cn from 'classnames';
import { HTMLAttributes, PropsWithChildren } from 'react';

import s from './alert.module.scss';

type AlertProps = {
  variant?: 'success' | 'warning' | 'info' | 'default' | 'danger' | 'hsh' | 'purple';
  dashed?: boolean;
} & HTMLAttributes<HTMLDivElement>;

export const Alert = ({ children, variant = 'info', dashed, className, ...rest }: PropsWithChildren<AlertProps>) => {
  return (
    <div className={cn(s.container, s[variant], dashed && s.dashed, className)} {...rest}>
      {children}
    </div>
  );
};
