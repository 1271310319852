import { SelectableColors, Theme } from '@/features/theme/model/types';
import { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  AppTheme,
  changeTheme,
  selectCurrentBg,
  selectCurrentTheme,
  selectOverlapBg,
} from '@/features/theme/model/slice';
import { AppDispatch } from '@/shared/store/types';

type Props = {
  background?: SelectableColors;
  theme?: Theme;
  children: ReactNode;
};

export function ThemeProvider({ children, theme }: Props) {
  const currentTheme = useSelector(selectCurrentTheme);
  const currentBg = useSelector(selectCurrentBg);
  const overlapBg = useSelector(selectOverlapBg);
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    const bgColor = currentTheme === AppTheme.LIGHT ? '#ffffff' : '#25272a';
    if (window?.ct?.setupUi) {
      window.ct.setupUi({ theme: currentTheme, backgroundColor: bgColor });
    }

    if (theme && theme !== currentTheme) {
      dispatch(changeTheme(theme));
      return;
    }

    document.documentElement.setAttribute('data-theme', currentTheme);
    document.documentElement.style.setProperty('--user-bg-color', overlapBg ?? currentBg);
  }, [dispatch, currentTheme, theme, currentBg, overlapBg]);

  return <>{children}</>;
}
